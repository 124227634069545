import React, { useState, useEffect } from "react";
import { useSetOddHistoryMutation } from "../../../../redux/oddhistoryApi";
import {
  commetaryRunEnumm,
  commetaryWicketEnumm,
  recentBall,
  ShortKeyEnum,
} from "../../../constant/enum";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import {
  useSetSessionsMutation,
  useUpdateCommentMutation,
} from "../../../../redux/matchApi";
import {
  useGetOverRunMutation,
  useSetOverRunMutation,
} from "../../../../redux/overRunApi";

function FirstCircle({
  data,
  setTeamScore,
  result,
  sendData,
  setLast4over,
  last4over,
  getMatchDetail,
  setFirstCircle,
  bowler,
  batter,
}) {
  const [getOverRun] = useGetOverRunMutation();
  const [setOverRun] = useSetOverRunMutation();
  const [setSessions] = useSetSessionsMutation();
  const [setOddHistory] = useSetOddHistoryMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [message, setMessage] = useState("");
  const [typeMessage, setTypeMessage] = useState("");
  const [comment, setComment] = useState();
  const [comment3, setComment3] = useState();
  const [matchId, setMatchId] = useState("");
  const [currentInning, setCurrentInning] = useState("");
  const [battingTeam, setBattingTeam] = useState("");
  const [run, setRun] = useState("0");
  const [wicket, setWicket] = useState("0");
  const [over, setOver] = useState("0");
  const [teamAOver, setTeamAOver] = useState("");
  const [teamBOver, setTeamBOver] = useState("");
  const [teamAScores, setTeamAScores] = useState("");
  const [teamBScores, setTeamBScores] = useState("");
  const [teamAScore, setTeamAScore] = useState({});
  const [teamBScore, setTeamBScore] = useState({});
  const [teamAScoresOver, setTeamAScoresOver] = useState([]);
  const [teamBScoresOver, setTeamBScoresOver] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [session, setSession] = useState([]);
  const [sessionField, setSessionField] = useState({
    session: "",
    open: "",
    pass: "",
    min: "",
    max: "",
  });

  useEffect(() => {
    if (over && currentInning && matchId) {
      getOverRun({
        match_id: matchId,
        over: parseInt(over),
        inning: currentInning,
      });
    }
  }, [matchId]);

  useEffect(() => {
    if (data) {
      let matchDetail = data;
      setMessage(matchDetail?.first_circle);
      setMatchId(matchDetail?.match_id);
      setCurrentInning(matchDetail?.current_inning);
      setBattingTeam(matchDetail?.batting_team);
      setTeamAScores(matchDetail?.team_a_scores);
      setTeamBScores(matchDetail?.team_b_scores);
      setTeamAOver(matchDetail?.team_a_over);
      setTeamBOver(matchDetail?.team_b_over);
      setSession(matchDetail?.session ? JSON.parse(matchDetail?.session) : []);
      setTeamBScore(
        matchDetail?.team_b_score ? JSON.parse(matchDetail?.team_b_score) : {}
      );
      setTeamAScore(
        matchDetail?.team_a_score ? JSON.parse(matchDetail?.team_a_score) : {}
      );
      setTeamAScoresOver(
        matchDetail?.team_a_scores_over
          ? JSON.parse(matchDetail?.team_a_scores_over)
          : []
      );
      setTeamBScoresOver(
        matchDetail?.team_b_scores_over
          ? JSON.parse(matchDetail?.team_b_scores_over)
          : []
      );
      if (matchDetail?.batting_team == matchDetail?.team_a_id) {
        if (matchDetail?.team_a_score) {
          let jsonScore = JSON.parse(matchDetail?.team_a_score);
          setRun(
            jsonScore[matchDetail?.current_inning]?.score
              ? jsonScore[matchDetail?.current_inning]?.score
              : 0
          );
          setWicket(
            jsonScore[matchDetail?.current_inning]?.wicket
              ? jsonScore[matchDetail?.current_inning]?.wicket
              : 0
          );
          setOver(
            jsonScore[matchDetail?.current_inning]?.over
              ? jsonScore[matchDetail?.current_inning]?.over
              : 0
          );
        }
        setTeamName(matchDetail?.team_a_name);
      } else {
        if (matchDetail?.team_b_score) {
          let jsonScore = JSON.parse(matchDetail?.team_b_score);
          setRun(
            jsonScore[matchDetail?.current_inning]?.score
              ? jsonScore[matchDetail?.current_inning]?.score
              : 0
          );
          setWicket(
            jsonScore[matchDetail?.current_inning]?.wicket
              ? jsonScore[matchDetail?.current_inning]?.wicket
              : 0
          );
          setOver(
            jsonScore[matchDetail?.current_inning]?.over
              ? jsonScore[matchDetail?.current_inning]?.over
              : 0
          );
        }
        setTeamName(matchDetail?.team_b_name);
      }
    }
  }, [data]);

  useEffect(() => {
    if (result) {
      setMessage(result);
      const data = {
        match_id: matchId,
        first_circle: result,
      };
      const eventType = "update-first-circle";
      sendData(eventType, data);
    }
  }, [result]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "message") {
      setTypeMessage(value);
    }
    if (name === "comment") {
      setComment(value);
    }
    if (name === "comment3") {
      setComment3(value);
    }
    if (name === "over") {
      setOver(value);
    }
    if (name === "run") {
      setRun(value);
    }
    if (name === "wicket") {
      setWicket(value);
    }
  };

  const handleSession = (e) => {
    const { name, value } = e.target;
    setSessionField({ ...sessionField, [name]: value });
  };

  const submitMessage = (e) => {
    e.preventDefault();
    let fullKey = ShortKeyEnum.filter((list) => {
      return list.key == typeMessage.toUpperCase();
    });
    let message;
    if (fullKey.length > 0) {
      setMessage(fullKey[0].value);
      message = fullKey[0]?.value;
    } else {
      setMessage(typeMessage);
      message = typeMessage;
    }
    const data = {
      match_id: matchId,
      first_circle: message,
    };
    setFirstCircle(message);
    const eventType = "update-first-circle";
    sendData(eventType, data);
    setTypeMessage("");
  };

  const submitComment = (e) => {
    e.preventDefault();
    const updateData = {
      comment_2: comment,
      match_id: data?.match_id,
    };
    updateComment(updateData).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setComment("");
        getMatchDetail();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  const submitComment3 = (e) => {
    e.preventDefault();
    const updateData = {
      comment_3: comment3,
      match_id: data?.match_id,
    };
    updateComment(updateData).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setComment3("");
        getMatchDetail();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  const isInt = (s) => {
    return /^\d+$/.test(s);
  };

  const isFloat = (s) => {
    return /^\d+\.\d+$/.test(s);
  };

  const covertBalls = (over) => {
    let balls=(Math.floor(over) * 6 + (parseFloat(over) % 1) * 10)
    return balls
  };

  const updateScore = async (e) => {
    e.preventDefault();
    let teamAJsonScore = teamAScore;
    let teamBJsonScore = teamBScore;
    let updateData;
    if (battingTeam == data?.team_a_id) {
      teamAJsonScore["team_id"] = battingTeam;
      teamAJsonScore[currentInning] = {
        score: run,
        wicket: wicket ? wicket : 0,
        over: over ? over : 0,
      };
      let teamScoreOver = teamAScoresOver;
      if (currentInning > 2) {
        teamScoreOver[currentInning - 2] = {
          score: run + "-" + wicket,
          over: over,
        };
      } else {
        teamScoreOver[0] = { score: run + "-" + wicket, over: over };
      }
      updateData = {
        team_a_scores:
          currentInning > 2
            ? teamAJsonScore[currentInning - 2]?.score +
              "-" +
              teamAJsonScore[currentInning - 2]?.wicket +
              "&" +
              run +
              "-" +
              wicket
            : run + "-" + wicket,
        team_a_over:
          currentInning > 2
            ? teamAJsonScore[currentInning - 2].over + "&" + over
            : over,
        team_a_score: JSON.stringify(teamAJsonScore),
        team_b_scores: teamBScores,
        team_a_scores_over: JSON.stringify(teamScoreOver),
        team_b_over: teamBOver,
        team_b_score: JSON.stringify(teamBJsonScore),
        team_b_scores_over: data?.team_b_scores_over,
        match_id: matchId,
        curr_rate: (run == 0 ? 0.00 : (parseFloat(run) / parseFloat(covertBalls(over))*6)).toFixed(
          2
        ),
        run: run,
        over: over,
      };
      setTeamScore(teamAJsonScore);
    } else {
      teamBJsonScore["team_id"] = battingTeam;
      teamBJsonScore[currentInning] = {
        score: run,
        wicket: wicket ? wicket : 0,
        over: over ? over : 0,
      };
      let teamScoreOver = teamBScoresOver;
      if (currentInning > 2) {
        teamScoreOver[currentInning - 2] = {
          score: run + "-" + wicket,
          over: over,
        };
      } else {
        teamScoreOver[0] = { score: run + "-" + wicket, over: over };
      }
      updateData = {
        team_a_scores: teamAScores,
        team_a_over: teamAOver,
        team_a_score: JSON.stringify(teamAJsonScore),
        team_a_scores_over: data?.team_a_scores_over,
        team_b_scores:
          currentInning > 2
            ? teamBJsonScore[currentInning - 2]?.score +
              "-" +
              teamBJsonScore[currentInning - 2]?.wicket +
              "&" +
              run +
              "-" +
              wicket
            : run + "-" + wicket,
        team_b_over:
          currentInning > 2
            ? teamBJsonScore[currentInning - 2]?.over + "&" + over
            : over,
        team_b_score: JSON.stringify(teamBJsonScore),
        team_b_scores_over: JSON.stringify(teamScoreOver),
        match_id: matchId,
        curr_rate:(run == 0 ? 10 : (parseFloat(run) / parseFloat(covertBalls(over))*6)).toFixed(
          2
        ),
        run: run,
        over: over,
      };
      setTeamScore(teamBJsonScore);
    }
    const eventType = "update-score";

    sendData(eventType, updateData);

    oddHistory();
    if (isInt(over)) {
      let newLastOver = {
        over: parseInt(over)+1,
        run: 0,
        balls: [],
      };
      let updatedLast4Over = [newLastOver, ...last4over];

      // If the length exceeds 4, remove the last element
      if (updatedLast4Over.length > 4) {
        updatedLast4Over.pop(); // Remove the last element
      }
      setLast4over(updatedLast4Over);
      if (over > 0) {
        addcommentry();
      }
    } else if (isFloat(over)) {
      const indexs = last4over.findIndex((obj) => {
        return obj.over == parseInt(over)+1;
      });
      if (indexs >= 0) {
        // message === "Wicket" || message == "No Ball" || message=="No ball + by 4 run"
        if (parseInt(message) || recentBall.includes(message) || message==0) {
          let newLast4over = [...last4over];
          // newLast4over[indexs]["run"] = run;
          let filter = ShortKeyEnum.filter((list) => {
            return message == list.value;
          });

          newLast4over[indexs]["balls"] = [
            parseInt(message)|| message==0 ? message : filter[0]?.key,
            ...newLast4over[indexs]["balls"],
          ];
          let cwicket = commetaryWicketEnumm.filter((list) => {
            return list.value === message;
          });
          let crun = commetaryRunEnumm.filter((list) => {
            return list.value === message;
          });
          let addCommentaryData = {
            inning: currentInning,
            type: 1,
            overs: over,
            match_id: matchId,
            data: JSON.stringify({
              overs: over,
              wicket: cwicket[0]?.key ? cwicket[0]?.key : "",
              runs: parseInt(message)
                ? message
                : crun[0]?.key
                ? crun[0]?.key
                : 0,
              title: bowler?.player_name + " to " + batter[0]?.player_name,
              description: "",
            }),
          };
          const eventTypeCommentry = "add-commentary";
          sendData(eventTypeCommentry, addCommentaryData);
          let getData = await getOverRun({
            match_id: matchId,
            over: parseInt(over),
            inning: currentInning,
          });
          setOverRun({
            match_id: matchId,
            inning: currentInning,
            over: parseInt(over),
            run:
              getData.data.length > 0
                ? getData.data[0].run +
                  (parseInt(message)
                    ? parseInt(message)
                    : crun[0]?.key
                    ? crun[0]?.run
                    : 0)
                : parseInt(message)
                ? parseInt(message)
                : crun[0]?.key
                ? crun[0]?.run
                : 0,
            wicket: 0,
          });

          // newLast4over[indexs]["balls"] = [
          //   parseInt(message)
          //     ? message
          //     : message == "Wicket"
          //     ? "wk"
          //     : message == "No Ball"
          //     ? "nb"
          //     : message == "No ball + by 4 run"? "nbb4" : "" ,
          //   ...newLast4over[indexs]["balls"],
          // ];
          setLast4over(newLast4over);
        } else {
          setLast4over(last4over);
        }
      }
    }
  };

  const addcommentry = async () => {
    let getData = await getOverRun({
      match_id: matchId,
      over: parseInt(over - 1),
      inning: currentInning,
    });
    let addCommentaryData = {
      inning: currentInning,
      type: 2,
      overs: over - 1,
      match_id: matchId,
      data: JSON.stringify({
        overs: over,
        wicket: getData.data[0].wicket,
        runs: getData.data[0].run,
        title: `END OF OVER : ${over}`,
        team: teamName,
        team_score: run,
        team_wicket: wicket,
        batsman_1_name: batter[0].player_name,
        batsman_1_runs: batter[0].run,
        batsman_1_balls: batter[0].ball,
        batsman_2_name: batter[1].player_name,
        batsman_2_runs: batter[1].run,
        batsman_2_balls: batter[1].ball,
        bolwer_name: bowler?.player_name,
        bolwer_overs: bowler?.O,
        bolwer_maidens: bowler?.M,
        bolwer_runs: bowler?.R,
        bolwer_wickets: bowler?.W,
        description: "",
      }),
    };
    const eventTypeCommentry = "add-commentary";
    sendData(eventTypeCommentry, addCommentaryData);
  };

  const oddHistory = () => {
    const data = {
      score: run,
      inning: currentInning,
      overs: over,
      date_time: moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss"),
      team: teamName,
      match_id: matchId,
      runs: parseInt(message) ? message : message == "Wicket" ? "wk" : "0",
    };
    setOddHistory(data);
  };

  const submitSession = (e) => {
    e.preventDefault();
    setSession([...session, sessionField]);
    const addData = {
      session: JSON.stringify([...session, sessionField]),
      match_id: data?.match_id,
    };
    setSessions(addData).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setSessionField({ session: "", open: "", pass: "", min: "", max: "" });
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div className="full-detail scoreboard-main">
      <div className="match-topbar">
        <p>
          <img
            src="/assets/images/score-icon.png"
            alt=""
            className="img-fluid"
          />{" "}
          Scoreboard
        </p>
      </div>
      <div className="score-inner score-top text-center">
        <p className="drink-break">{message}</p>
        <form onSubmit={submitMessage}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Ball update"
              name="message"
              className="form-control"
              value={typeMessage}
              onChange={handleChange}
            />
          </div>
          <div className="update-btn text-center w-100">
            <button
              type="submit"
              class="btn btn-primary"
              disabled={data?.match_status == "Finished"}
            >
              Update
            </button>
          </div>
        </form>
      </div>
      <div className="score-inner score-middle text-center">
        <form onSubmit={updateScore}>
          <div className="total-score d-flex flex-wrap align-items-center">
            <div className="total-left">
              <p>
                <b>Runs:</b>
              </p>
            </div>
            <div className="total-right">
              <input
                type="number"
                placeholder=""
                name="run"
                className="form-control"
                value={run}
                onChange={handleChange}
                min="0"
              />
            </div>
            <div className="total-left">
              <p>
                <b>Wickets:</b>
              </p>
            </div>
            <div className="total-right">
              <input
                type="number"
                placeholder=""
                name="wicket"
                className="form-control"
                value={wicket}
                onChange={handleChange}
                min="0"
              />
            </div>
            <div className="total-left">
              <p>
                <b>Overs:</b>
              </p>
            </div>
            <div className="total-right">
              <input
                type="number"
                placeholder=""
                name="over"
                className="form-control"
                step="0.1"
                value={over}
                onChange={handleChange}
                min="0"
              />
            </div>
            <div className="update-btn text-center w-100">
              <button
                type="submit"
                class="btn btn-primary"
                // onClick={updateScore}
                disabled={data?.match_status == "Finished"}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="score-inner score-bottom text-center">
        <form onSubmit={submitComment}>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Comment 2"
              name="comment"
              onChange={handleChange}
              value={comment}
            ></textarea>
          </div>
          <div className="update-btn text-center w-100">
            <button
              type="submit"
              class="btn btn-primary"
              disabled={data?.match_status == "Finished"}
            >
              Update
            </button>
          </div>
        </form>
      </div>
      <div className="session-inner score-bottom text-center">
        <div className="session-table">
          <table>
            <thead>
              <tr>
                <td>Session</td>
                <td>Open</td>
                <td>Pass</td>
                <td>Min</td>
                <td>Max</td>
              </tr>
            </thead>
            <tbody>
              {session?.map((list, index) => {
                return (
                  <tr key={index}>
                    <td>{list.session}</td>
                    <td>{list.open}</td>
                    <td>{list.pass}</td>
                    <td>{list.min}</td>
                    <td>{list.max}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <p className="session-p">Add New Session:</p>
        <form onSubmit={submitSession}>
          <div className="form-group session-box">
            <input
              type="number"
              name="session"
              onChange={handleSession}
              value={sessionField.session}
              min="0"
            />
            <input
              type="number"
              name="open"
              onChange={handleSession}
              value={sessionField.open}
              min="0"
            />
            <input
              type="number"
              name="pass"
              onChange={handleSession}
              value={sessionField.pass}
              min="0"
            />
            <input
              type="number"
              name="min"
              onChange={handleSession}
              value={sessionField.min}
              min="0"
            />
            <input
              type="number"
              name="max"
              onChange={handleSession}
              value={sessionField.max}
              min="0"
            />
          </div>
          <div className="update-btn text-center w-100">
            <button
              type="submit"
              class="btn btn-primary"
              disabled={data?.match_status == "Finished"}
            >
              Add
            </button>
          </div>
        </form>
      </div>
      <div className="score-inner score-bottom text-center mt-4">
        <form onSubmit={submitComment3}>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Comment 3"
              name="comment3"
              onChange={handleChange}
              value={comment3}
            ></textarea>
          </div>
          <div className="update-btn text-center w-100">
            <button
              type="submit"
              class="btn btn-primary"
              disabled={data?.match_status == "Finished"}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FirstCircle;
