import React, { useEffect, useState } from "react";
import { useGetMatchByIdMutation } from "../../../redux/matchApi";
import { useGetScoreCardMatchMutation } from "../../../redux/scorecardApi";

function FullScoreCard({ match_id }) {
  const [getMatchById, { data }] = useGetMatchByIdMutation();
  const [getScoreCardMatch, { data: scoreCard }] =
    useGetScoreCardMatchMutation();
  const [filterScoreCard, setFilterScoreCard] = useState([]);
  useEffect(() => {
    if (match_id) {
      getMatchById({ match_id: match_id });
      getScoreCardMatch({ match_id: match_id });
    }
  }, [match_id]);

  useEffect(() => {
    if (scoreCard?.length > 0) {
      let filterData = scoreCard.filter((list) => {
        return list.team_id == data?.batting_team;
      });
      setFilterScoreCard(filterData);
    }
  }, [scoreCard]);

  const handleClick = (id) => {
    if (scoreCard?.length > 0) {
      let filterData = scoreCard.filter((list) => {
        return list.team_id == id;
      });
      setFilterScoreCard(filterData);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <button
          className="btn btn-primary"
          onClick={() => {
            handleClick(
              data?.batting_team == data?.team_a_id
                ? data?.team_a_id
                : data?.team_b_id
            );
          }}
        >
          {data?.batting_team == data?.team_a_id
            ? data?.team_a_short
            : data?.team_b_short}
        </button>
        <button
          className="btn btn-primary ms-3"
          onClick={() => {
            handleClick(
              data?.batting_team == data?.team_b_id
                ? data?.team_a_id
                : data?.team_b_id
            );
          }}
        >
          {data?.batting_team == data?.team_b_id
            ? data?.team_a_short
            : data?.team_b_short}
        </button>
        <div>
          {/* Batting player table  */}
          {filterScoreCard?.map((list) => {
            let team = JSON.parse(list.team);
            let batter = JSON.parse(list?.batsman);
            let bowler = JSON.parse(list?.bowler);
            let fallWicket = JSON.parse(list?.fallwicket);
            let extra_run = team?.extra_run;
            let total_ex =
              parseInt(extra_run?.w) +
              parseInt(extra_run?.nb) +
              parseInt(extra_run?.lb) +
              parseInt(extra_run?.b);
            return (
              <>
                <div className="team-class">
                  <div>{team.name} Innings</div>
                  <div>
                    {team.score}-{team.wicket} ({team.over} Ov)
                  </div>
                </div>
                <table className="w-100 mb-4">
                  <thead>
                    <tr>
                      <th>Batter</th>
                      <th></th>
                      <th>Run</th>
                      <th>Ball</th>
                      <th>4s</th>
                      <th>6s</th>
                      <th>SR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {batter?.map((batterList, index) => {
                      return (
                        <tr>
                          <td>{batterList.player_name} {batterList?.match_role?" ( "+ batterList?.match_role+" )":""}</td>
                          <td>{batterList.out_by}</td>
                          <td>{batterList.run}</td>
                          <td>{batterList.ball}</td>
                          <td>{batterList.fours}</td>
                          <td>{batterList.sixes}</td>
                          <td>{batterList.strike_rate}</td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan="2">Extras</td>
                      <td colSpan="5">
                        {total_ex +
                          "{ wd - " +
                          extra_run?.w +
                          ", nb - " +
                          extra_run?.nb +
                          ", lb - " +
                          extra_run?.lb +
                          ", b - " +
                          extra_run?.b +
                          " }"}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">Total</td>
                      <td colSpan="5">
                        {team.score} ({team.wicket} wkts, {team.over} Ov)
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* Fall of wicket  */}
                <div className="row bg2 div_table mt-4">
                  <div className="col-12 ">Fall of Wickets</div>
                </div>
                <div className="row div_table">
                  <div className="col-12">
                    {fallWicket &&
                      fallWicket.map((fallList, index) => {
                        return (
                          <>
                            <span key={index}>
                              {fallList.score}-{fallList.wicket} (
                              {fallList.player_name},{fallList.over})
                            </span>
                            ,
                          </>
                        );
                      })}
                  </div>
                </div>

                {/* Bowling player table */}
                 <div className="mt-4">

                <table className="w-100">
                  <thead>
                    <tr>
                      <th>Bowler</th>
                      <th>O</th>
                      <th>M</th>
                      <th>R</th>
                      <th>W</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bowler?.map((list) => {
                      return (
                        <tr>
                          <td>{list.player_name}</td>
                          <td>{list.O}</td>
                          <td>{list.M}</td>
                          <td>{list.R}</td>
                          <td>{list.W}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                 </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FullScoreCard;
