import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";
import { useGetNewsSeriesMutation } from "../../../../redux/newsApi";

function News({ series_id }) {
  const location=useLocation()
  const [getNewsSeries, { data }] = useGetNewsSeriesMutation();
  useEffect(() => {
    getNewsSeries({ series_id });
  }, []);
  useEffect(() => {
    if(location){
      localStorage.setItem("path", location.pathname);
    }
  }, [location])

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "title",
      text: "Title",
      className: "title",
      align: "left",
      sortable: true,
    },
    {
      key: "pub_date",
      text: "Publish Date",
      className: "pub_date",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <img src={record.image} alt="news-img" className="img-fluid" />;
      },
    },
    {
      key: "news-detail",
      text: "View",
      className: "news",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Link
            to={`/news-detail/${record.news_id}`}
            className="btn btn-primary"
          >
            View
          </Link>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div>
      
      <div>
        <div className="product-list-outer all-matches">
          <div className="buttonDirection">
            <Link to={`/add-news/${series_id}`} className="btn btn-primary">
              Add News
            </Link>
          </div>
          <ReactDatatable config={config} records={data} columns={columns} />
        </div>
      </div>
    </div>
  );
}

export default News;
