import React, { useState, useEffect } from "react";
import {useParams } from "react-router-dom";
import { useGetMatchSquadIdMutation, useGetPlayerTeamMutation, useSetMatchSquadMutation } from "../../../redux/playerApi";
import { toast } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";

function AddSquad() {
  const { teamAId, teamBId,match_id } = useParams();
  const [getMatchSquadId]=useGetMatchSquadIdMutation()
  const [getPlayerTeam] = useGetPlayerTeamMutation();
  const [setMatchSquad]=useSetMatchSquadMutation()
  const [record, setRecord] = useState([]);
  const [selectTeamPlayerIds, setSelectTeamPlayerIds] = useState([]);
  const [filterRecord, setFilterRecord] = useState([]);
  const [tab, setTab] = useState("");
  useEffect(() => {
    if(match_id){
      getSquadId()
    }
  }, [match_id])

  const getSquadId=async ()=>{
    const data=await getMatchSquadId({match_id})
    if(data.data.length>0){
      let arr=[]
      for (const idData of data.data){
        arr.push(parseInt(idData.team_player_id))
      }
      setSelectTeamPlayerIds(arr)
    }
  }
  
  useEffect(() => {
    if (teamAId) {
      setTab(teamAId);
    }
  }, [teamAId]);

  const fetchDataForTeam = async (teamId) => {
    try {
      const { data } = await getPlayerTeam({ team_id: teamId });
      setRecord((prevRecord) => [...prevRecord, ...data]);
    } catch (error) {
      console.error(`Error fetching data for team ${teamId}:`, error);
    }
  };

  useEffect(() => {
    if (teamAId && teamBId) {
      const teamIds = [teamAId, teamBId]; // Add more team IDs as needed
      teamIds.forEach(fetchDataForTeam);
    }
  }, [teamAId, teamBId]);

  useEffect(() => {
    if (record.length > 0 && tab) {
      let filterRecords = record.filter((list) => {
        return list.team_id === tab;
      });
      setFilterRecord(filterRecords);
    }
  }, [tab, record]);

  const handlechecked = (id) => {
    // Check if the id is already in the array
    const index = selectTeamPlayerIds.indexOf(id);

    if (index !== -1) {
      // If id is already present, remove it
      const updateTeamPlayerIds = [...selectTeamPlayerIds];
      updateTeamPlayerIds.splice(index, 1);
      setSelectTeamPlayerIds(updateTeamPlayerIds);
    } else {
      // If id is not present, add it
      setSelectTeamPlayerIds([...selectTeamPlayerIds, id]);
    }
  };

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "select",
      text: "",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        const isChecked = selectTeamPlayerIds.includes(record.id);
        return (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => {
              handlechecked(record.id);
            }}
          />
        );
      },
    },
    {
      key: "short_name",
      text: "Short Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "first_name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "player_role",
      text: "Role",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "born",
      text: "Born",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "birth_place",
      text: "Birth Place",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <img src={record.image} alt="team-flag" className="img-fluid img-size" />;
      },
    },
  ];

  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
   
  const onSubmit=()=>{
    //  if(selectTeamPlayerIds.length<22){
    //   toast.dismiss()
    //   toast.error("Please select at least 22 player")
    //   return false
    //  }
     const data={
      match_id:match_id,
      selected_player:JSON.stringify(selectTeamPlayerIds)
     }
     setMatchSquad(data).then((result)=>{
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        getSquadId()
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
     })

  }

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b> Player List</b>
                </h2>
              </div>
              <div >
              <div className="product-list-outer all-matches">
                <div className=" btn_4 tr mb-3">
                  <button
                    onClick={() => {
                      setTab(teamAId);
                    }}
                    style={{
                      background: tab === teamAId ? "#e65b3e" : "",
                      color: tab === teamAId ? "white" : "black",
                    }}
                  >
                    team A
                  </button>
                  <button
                    onClick={() => {
                      setTab(teamBId);
                    }}
                    style={{
                      background: tab === teamBId ? "#e65b3e" : "",
                      color: tab === teamBId ? "white" : "black",
                    }}
                  >
                    team B
                  </button>
                  <p>Selected Row : {selectTeamPlayerIds.length}</p>
                </div>
                <div className="buttonDirection">
                  <button className="btn btn-primary" onClick={()=>{onSubmit()}}>Save</button>
                </div>
                <ReactDatatable
                  config={config}
                  records={filterRecord}
                  columns={columns}
                />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSquad;
