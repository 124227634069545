import React from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import {
  useGetMatchQuery,
  useGetSeriesMatchQuery,
  useUpdateMoveLiveMutation,
} from "../../../redux/matchApi";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Match() {
  const navigate = useNavigate();
  const [updateMoveLive] = useUpdateMoveLiveMutation();
  const { data } = useGetMatchQuery({});
  const { data: seriesMatch } = useGetSeriesMatchQuery({});

  const moveLive = (id) => {
    const data = {
      match_id: id,
    };
    updateMoveLive(data).then((res) => {
      if (res.data.status) {
        toast.dismiss();
        toast.success(res.data?.message);
      }
    });
  };
  const columns = [
    {
      key: "match_id",
      text: "ID",
      className: "catergory",
      align: "left",
      sortable: true,
    },
    {
      key: "match_date",
      text: "DATE TIME",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <p>{moment(record.match_date).format("dddd, D MMMM YYYY h:mmA")}</p>
          </>
        );
      },
    },
    {
      key: "series",
      text: "SERIES NAME",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "team_a_short",
      text: "Team 1",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <img src={record.team_a_img} alt="team1" className="img-fluid" />
            <p>{record.team_a_short}</p>
          </>
        );
      },
    },
    {
      key: "team_b_short",
      text: "Team 2",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <img src={record.team_b_img} alt="team1" className="img-fluid" />
            <p>{record.team_b_short}</p>
          </>
        );
      },
    },
    {
      key: "match_type",
      text: "FORMAT",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "Move",
      text: "Move TO LIVE",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record.match_status == "Upcoming" ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  moveLive(record.match_id);
                }}
              >
                Move To Live
              </button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      key: "match_status",
      text: "GO TO LIVE",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              className="btn"
              style={{
                background:
                  record.match_status == "Upcoming"
                    ? "#ffff008f"
                    : record.match_status == "Finished"
                    ? "green"
                    : "red",
                color:
                  record.match_status == "Upcoming" ? "rgb(57 56 56)" : "white",
              }}
            >
              {record.match_status}
            </button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const liveNavigate = (id) => {
    navigate(`/view-match/${id}`);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="section-heading">
            <h2>
              <b>Match List</b>
            </h2>
          </div>

          <div className="match-types">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="btn btn-primary active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Live Matches
                </button>
                <button
                  class="btn btn-primary"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  All Matches
                </button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade active show"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div class="accordion" id="accordionExample">
                  {seriesMatch?.map((list) => {
                    return (
                      <div class="accordion-item" key={list.series_id}>
                        <h4
                          class="accordion-header d-flex align-items-center justify-content-between"
                          id={`heading${list.series_id}`}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${list.series_id}`}
                          aria-expanded="true"
                          aria-controls={`#collapse${list.series_id}`}
                        >
                          <span>1</span>
                          <div className="match-heading">{list.series}</div>
                          <div className="arrow-img">
                            <img
                              src="assets/images/arrow-down.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </h4>
                        <div
                          id={`collapse${list.series_id}`}
                          class="accordion-collapse collapse show"
                          aria-labelledby={`heading${list.series_id}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body ps-0 pe-0">
                            <ul className="matchdetail-head nav">
                              <li className="border-end text-center">POS</li>
                              <li className="border-end text-center">GI</li>
                              <li className="border-end text-center">
                                f_key/inn
                              </li>
                              <li className="border-end text-center">
                                Date-Time & Status
                              </li>
                              <li className="border-end text-center">
                                Series & Match
                              </li>
                              <li className="border-end text-center">
                                Team, score and Comment
                              </li>
                              <li className="text-center">Odds</li>
                            </ul>
                            {list.match?.map((matchList, index) => {
                              return (
                                <ul
                                  className="matchdetail-body nav mb-2"
                                  key={matchList.match_id}
                                  onClick={() => {
                                    liveNavigate(matchList.match_id);
                                  }}
                                >
                                  <li className="border-end text-center">0</li>
                                  <li className="border-end text-center">
                                    <input
                                      type="number"
                                      placeholder=""
                                      name=""
                                      className="form-control"
                                    />
                                  </li>
                                  <li className="border-end text-center">
                                    <p className="mb-0">LRE</p>
                                    <p className="mb-0 border-top">
                                      Inning {matchList.current_inning}
                                    </p>
                                  </li>
                                  <li className="border-end text-center">
                                    <p className="mb-0">
                                      {moment(matchList.match_date).format(
                                        "DD MMM, h:mm A"
                                      )}
                                    </p>
                                    <p className="mb-0 border-top">
                                      <button
                                        className="btn w-100"
                                        style={{
                                          background:
                                            matchList.match_status == "Upcoming"
                                              ? "#ffff008f"
                                              : matchList.match_status ==
                                                "Finished"
                                              ? "green"
                                              : "red",
                                          color:
                                            matchList.match_status == "Upcoming"
                                              ? "rgb(57 56 56)"
                                              : "white",
                                        }}
                                      >
                                        {matchList.match_status}
                                      </button>
                                    </p>
                                  </li>
                                  <li className="border-end text-center">
                                    <p className="mb-0">{matchList.series}</p>
                                    <p className="mb-0 border-top">
                                      {matchList.matchs}
                                    </p>
                                  </li>
                                  <li className="border-end text-center">
                                    <div className="team-cmnt d-flex">
                                      <div className="team-left team d-flex align-items-center">
                                        <div className="team-logo">
                                          <img
                                            src="assets/images/team-flag1.jpg"
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="team-total">
                                          <p className="mb-0">
                                            {matchList.team_a_name}
                                          </p>
                                          <p className="mb-0 border-top">
                                            {matchList.team_a_scores}
                                            {matchList.team_b_over
                                              ? "(" +
                                                matchList.team_b_over +
                                                ")"
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="team-right team d-flex align-items-center">
                                        <div className="team-logo">
                                          <img
                                            src="assets/images/team-flag2.jpg"
                                            alt=""
                                            className="img-fluid"
                                          />
                                        </div>
                                        <div className="team-total">
                                          <p className="mb-0">
                                            {matchList.team_b_name}
                                          </p>
                                          <p className="mb-0 border-top">
                                            {matchList.team_b_scores}
                                            {matchList.team_b_over
                                              ? "(" +
                                                matchList.team_b_over +
                                                ")"
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="current-status border-top text-start">
                                      <p className="m-0">
                                        {matchList.first_circle}
                                      </p>
                                    </div>
                                  </li>
                                  <li className="text-center">
                                    <div className="odd-outer d-flex justify-content-between align-items-center">
                                      <div className="odd-left odd-inner">
                                        <p className="m-0">India</p>
                                      </div>
                                      <div className="odd-right odd-inner">
                                        <span class="badge bg-primary">
                                          1.22
                                        </span>
                                        <span class="badge bg-danger">
                                          1.23
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="product-list-outer card-body all-matches p-0">
                  <ReactDatatable
                    config={config}
                    records={data}
                    columns={columns}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Match;
