import { getUmpireApi, insertUmpireApi, updateUmpireDetail } from "../components/constant/Api";
import { myApi } from "./api";

export const umpireApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getUmpire: builder.query({
      query: (post) => ({
        url: getUmpireApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["umpire"],
    }),
    setUmpire:builder.mutation({
        query:(post)=>({
            url: insertUmpireApi,
            method: "POST",
            body:post
        }),
        invalidatesTags: (_) => ["umpire"],
    }),
    updateUmpireDetail:builder.mutation({
      query:(post)=>({
          url: updateUmpireDetail,
          method: "PUT",
          body:post
      }),
      invalidatesTags: (_) => ["umpire"],
  })
  }),
});

export const {useGetUmpireQuery,useSetUmpireMutation,useUpdateUmpireDetailMutation}=umpireApi
