import {
  addMatchSquadApi,
  getMatchSquadApi,
  getMatchSquadIdApi,
  getMatchSquadXIApi,
  getMatchSquadXIIdApi,
  getPlayerApi,
  getPlayerByIdApi,
  getPlayerTeamIdApi,
  getSeriesPlayerApi,
  insertPlayerApi,
  insertPlayerTeamApi,
  setMatchSquadXIApi,
  setPlayerMatchRoleapi,
  updatePlayerApi,
} from "../components/constant/Api";
import { myApi } from "./api";

export const playerApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlayer: builder.query({
      query: () => ({
        url: getPlayerApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["player"],
    }),
    setPlayer: builder.mutation({
      query: (post) => ({
        url: insertPlayerApi,
        method: "POST",
        body: post,
        formData: true,
      }),

      invalidatesTags: (_) => ["player"],
    }),
    getPlayerTeam: builder.mutation({
      query: (post) => ({
        url: getPlayerTeamIdApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["teamPlayer"],
    }),
    setPlayerTeam: builder.mutation({
      query: (post) => ({
        url: insertPlayerTeamApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["teamPlayer"],
    }),
    updatePlayer: builder.mutation({
      query: (post) => ({
        url: updatePlayerApi,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: (_) => ["player"]
    }),
    getPlayerById: builder.query({
      query: (post) => ({
        url: getPlayerByIdApi+"/"+post.player_id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? "" : "";
      },

    }),
    getMatchSquad: builder.mutation({
      query: (post) => ({
        url: getMatchSquadApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? {} : {};
      },

    }),
    getMatchSquadXI: builder.mutation({
      query: (post) => ({
        url: getMatchSquadXIApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },

    }),
    setMatchSquad: builder.mutation({
      query: (post) => ({
        url: addMatchSquadApi,
        method: "POST",
        body: post,
      }),
    }),
    setMatchSquadXI: builder.mutation({
      query: (post) => ({
        url: setMatchSquadXIApi,
        method: "POST",
        body: post,
      }),
    }),
    getMatchSquadId: builder.mutation({
      query: (post) => ({
        url: getMatchSquadIdApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? {} : {};
      },

    }),
    getMatchSquadXIId: builder.mutation({
      query: (post) => ({
        url: getMatchSquadXIIdApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },

    }),
    setPlayerMatchRole: builder.mutation({
      query: (post) => ({
        url: setPlayerMatchRoleapi,
        method: "POST",
        body: post,
      }),
    }),
    getSeriesPlayer: builder.mutation({
      query: (post) => ({
        url: getSeriesPlayerApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },

    }),
  }),
});

export const {
  useGetPlayerQuery,
  useGetPlayerTeamMutation,
  useSetPlayerMutation,
  useSetPlayerTeamMutation,
  useUpdatePlayerMutation,
  useGetPlayerByIdQuery,
  useGetMatchSquadMutation,
  useSetMatchSquadMutation,
  useGetMatchSquadIdMutation,
  useGetMatchSquadXIIdMutation,
  useGetMatchSquadXIMutation,
  useSetMatchSquadXIMutation,
  useSetPlayerMatchRoleMutation,
  useGetSeriesPlayerMutation
} = playerApi;
