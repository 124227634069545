import React from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetRecentMatchQuery } from "../../../redux/matchApi";
import { Link } from "react-router-dom";

function RecentMatch() {
  const { data } = useGetRecentMatchQuery({});
  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "series",
      text: "Series",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_type",
      text: "Match Type",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "team_a_short",
      text: "Team A",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "team_b_short",
      text: "Team B",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_status",
      text: "Match Status",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_date",
      text: "Match Date",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "View",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link
              to={`/view-match/${record.match_id}`}
              className="btn btn-primary"
            >
              View
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="section-heading">
            <h2>
              <b> Recent Match List</b>
            </h2>
          </div>
          <div>
            <div className="product-list-outer all-matches">
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentMatch;
