import React, { useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { Link, useLocation } from "react-router-dom";
import { useGetVenueByseriesMutation } from "../../../../redux/venueApi";

function Venue({ series_id }) {
  const location=useLocation()
  const [getVenueByseries, { data: venueList }] = useGetVenueByseriesMutation();
  useEffect(() => {
    if (series_id) {
      const data = {
        series_id: series_id,
      };
      getVenueByseries(data);
    }
  }, [series_id]);

  useEffect(() => {
    if(location){
      localStorage.setItem("path", location.pathname);
    }
  }, [location])
  

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "venue_name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "capacity",
      text: "Capacity",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "location",
      text: "Location",
      className: "location",
      align: "left",
      sortable: true,
    },
    {
      key: "opened",
      text: "Opened",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link
              to={`/update-venue/${record.venue_id}`}
              className="btn btn-primary"
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div>
      <div className="product-list-outer all-matches fees_list_page">
        <ReactDatatable config={config} records={venueList} columns={columns} />
      </div>
    </div>
  );
}

export default Venue;
