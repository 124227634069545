import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import {
  useGetCommentaryMutation,
  useUpdateCommentaryMutation,
} from "../../../../redux/commentaryApi";
import config from "../../../constant/Config";
import { useGetMatchByIdMutation } from "../../../../redux/matchApi";
function Commentary({ match_id, sendData, ws }) {
  const [getMatchById, { data: matchDetail }] = useGetMatchByIdMutation();
  const [getCommentary, { data }] = useGetCommentaryMutation();
  const [updateCommentary] = useUpdateCommentaryMutation();
  const [commentaryData, setCommentaryData] = useState([]);
  useEffect(() => {
    if (match_id) {
      getMatchById({ match_id: match_id });
      getCommentary({ match_id: match_id });
    }
  }, [match_id]);

  useEffect(() => {
    if (data) {
      setCommentaryData(data);
    }
  }, [data]);

  const handleChange = (i, e) => {
    const { name, value } = e.target;
    // Make a copy of the commentaryData array
    let updatedCommentaryData = [...commentaryData];
    // Find the commentary object at index i
    let commentary = { ...updatedCommentaryData[i] };
    // Parse the JSON data of the commentary
    let data = JSON.parse(commentary.data);
    // Update the description in the data object
    data.description = value;
    // Convert the updated data back to JSON string and assign it to commentary
    commentary.data = JSON.stringify(data);
    // Replace the commentary object at index i with the updated one
    updatedCommentaryData[i] = commentary;
    // Update the state with the modified commentaryData array
    setCommentaryData(updatedCommentaryData);
    const decoded = jwtDecode(config);
    if (!commentary.user_id) {
      const eventType = "add-user-commentary";
      const userData = {
        user_id: decoded.id,
        commentary_id: commentary.commentary_id,
        match_id: match_id,
      };
      sendData(eventType, userData);
    }
  };

  useEffect(() => {
    if (ws && commentaryData) {
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.eventType === "recieve-commentary" && message.status) {
          const index = commentaryData.findIndex(
            (obj) => obj.commentary_id == message.data[0]?.commentary_id
          );

          if (index >= 0) {
            let updatedCommentaryData = [...commentaryData];
            updatedCommentaryData[index] = message.data[0];
            setCommentaryData(updatedCommentaryData);
          } else {
            setCommentaryData([message.data[0], ...commentaryData]);
          }
        }
      };
    }
  }, [ws, commentaryData]);

  const handlesubmit = (i, e) => {
    e.preventDefault();
    let updatedCommentaryData = [...commentaryData];
    let commentary = { ...updatedCommentaryData[i] };
    let data = {
      commentary_id: commentary.commentary_id,
      data: commentary.data,
      match_id: match_id,
    };
    const eventType = "update-commentary";
    sendData(eventType, data);
    // updateCommentary(data).then((result) => {
    //   if (result.data.status) {
    //     toast.dismiss();
    //     toast.success(result.data.message);
    //     getCommentary({ match_id: match_id });
    //   } else {
    //     toast.dismiss();
    //     toast.error(result.data.message);
    //   }
    // });
  };

  const handleKeyDown = (index, e) => {
    // Check if the Enter key is pressed
    if (e.key === "Enter" && !e.shiftKey) {
      // Call the submit function
      handlesubmit(index, e);
    }
  };

  const handeAddCommenatry = () => {
    const addCommentaryData = {
      match_id: match_id,
      type: 1,
      inning: matchDetail?.current_inning,
      data: JSON.stringify({
        runs: "",
        overs: "",
        title: "",
        wicket: "",
        description: "",
      }),
    };
    const eventTypeCommentry = "add-commentary";
    sendData(eventTypeCommentry, addCommentaryData);
  };

  const handleDelete = (i) => {
    // Make a copy of the commentaryData array
    let updatedCommentaryData = [...commentaryData];
    let commentary = { ...updatedCommentaryData[i] };
    let data = JSON.parse(commentary.data);
    data.description = "";
    commentary.data = JSON.stringify(data);
    updatedCommentaryData[i] = commentary;

    let updateData = {
      commentary_id: commentary.commentary_id,
      data: JSON.stringify(data),
      match_id: match_id,
    };
    const eventType = "update-commentary";
    sendData(eventType, updateData);
  };

  return (
    <div className="commentary-main">
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="commentary-outer">
            {commentaryData?.map((list, index) => {
              const jsonData = JSON.parse(list?.data);
              return list.type == "1" ? (
                <div className="commentary-left">
                  <span>{jsonData?.overs}</span>
                  <div className="add-remove-box d-flex flex-wrap justify-content-end">
                    <div className="add-box">
                      <button onClick={handeAddCommenatry}>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </div>

                    <div className="remove-box">
                      <button
                        onClick={() => {
                          handleDelete(index);
                        }}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div className="type-comment">
                    <form
                      onSubmit={(e) => {
                        handlesubmit(index, e);
                      }}
                    >
                      <div className="form-group">
                        <textarea
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          className="form-control"
                          onChange={(e) => {
                            handleChange(index, e);
                          }}
                          value={jsonData.description}
                        ></textarea>
                      </div>
                    </form>
                  </div>
                  {list.name ? (
                    <div className="typist">
                      <p>{list.name} is typing...</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="over-detail">
                  <ul className="nav">
                    <li className="current-over">
                      <h2 className="m-0">{jsonData.overs}</h2>
                    </li>
                    <li>Run Scored : {jsonData.runs}</li>
                    <li>
                      Score after {jsonData.overs} overs{" "}
                      <span className="d-block">
                        {" "}
                        {jsonData.team} {jsonData.team_score}-
                        {jsonData.team_wicket}
                      </span>
                    </li>
                    <li>
                      <span>
                        {jsonData.batsman_1_name} {jsonData.batsman_1_runs}(
                        {jsonData.batsman_1_balls})
                      </span>{" "}
                      <span>
                        {jsonData.batsman_2_name} {jsonData.batsman_2_runs}(
                        {jsonData.batsman_2_balls})
                      </span>
                    </li>
                    <li>
                      <span>{jsonData.bolwer_name}</span>{" "}
                      <span>
                        {" "}
                        {jsonData.bolwer_overs}-{jsonData.bolwer_maidens}-
                        {jsonData.bolwer_runs}-{jsonData.bolwer_wickets}
                      </span>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="commentary-right">
            <div className="mobile-topbar position-sticky">
              <img
                src="/assets/images/crick-logo.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="right-inner">
              {commentaryData?.map((list) => {
                const jsonData = JSON.parse(list?.data);
                return list.type == "1" ? (
                  <p>
                    <b>{jsonData.overs}</b>{" "}
                    {jsonData.description
                      ? jsonData.description
                      : jsonData.title}
                  </p>
                ) : (
                  <div className="over-detail">
                    <ul className="nav">
                      <li className="current-over">
                        <h2 className="m-0">{jsonData.overs}</h2>
                      </li>
                      <li>Run Scored : {jsonData.runs}</li>
                      <li>
                        Score after {jsonData.overs} overs{" "}
                        <span className="d-block">
                          {" "}
                          {jsonData.team} {jsonData.team_score}-
                          {jsonData.team_wicket}
                        </span>
                      </li>
                      <li>
                        <span>
                          {jsonData.batsman_1_name} {jsonData.batsman_1_runs}(
                          {jsonData.batsman_1_balls})
                        </span>{" "}
                        <span>
                          {jsonData.batsman_2_name} {jsonData.batsman_2_runs}(
                          {jsonData.batsman_2_balls})
                        </span>
                      </li>
                      <li>
                        <span>{jsonData.bolwer_name}</span>{" "}
                        <span>
                          {" "}
                          {jsonData.bolwer_overs}-{jsonData.bolwer_maidens}-
                          {jsonData.bolwer_runs}-{jsonData.bolwer_wickets}
                        </span>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Commentary;
