import React,{useState} from "react";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import "react-internet-meter/dist/index.css";

function ConnectionSpeed() {
  const [checkSpeed, setCheckSpeed] = useState("Finding internet speed.");
  const [txtSubHeading, setTxtSubHeading] = useState("Internet is too slow");
  const [classAdd, setclassAdd] = useState("")

  const handleNetworkTest = (speed) => {
    setCheckSpeed(speed);
    if (parseFloat(speed) <= 5) {
      setTxtSubHeading("Internet is too slow");
      setclassAdd("")
    } else {
      setTxtSubHeading(`${speed} MB/s`);
      setclassAdd("greenclass")
    }
  };

  return (
    <div className="net-speed">
      <ReactInternetSpeedMeter
        txtSubHeading={txtSubHeading}
        // outputType="alert"
        customClassName={`net-ping ${classAdd}`}
        txtMainHeading="ping"
        pingInterval={4000} // milliseconds
        thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
        threshold={100}
        imageUrl="https://via.placeholder.com/500"
        downloadSize="1781287" //bytes
        callbackFunctionOnNetworkDown={(speed) =>
          console.log(`Internet speed is down ${speed}`)
        }
        callbackFunctionOnNetworkTest={handleNetworkTest}
      />
   
    </div>
  );
}

export default ConnectionSpeed;
