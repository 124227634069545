import React, { useEffect,useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetSeriesQuery, useUpdateTredingSeriesMutation } from "../../../redux/seriesApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Series() {
  const { data, refetch } = useGetSeriesQuery({});
  const [updateTredingSeries]=useUpdateTredingSeriesMutation()
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    refetch();
  }, []);

  const updateStatus = async (series_id) => {
    setDisable(true);
    const data = {
      series_id: series_id,
    };
    updateTredingSeries(data).then((res) => {
      if (res.data.status) {
        toast.dismiss();
        toast.success(res.data.message);
        refetch();
        setDisable(false);
      } else {
        toast.error(res.data.message);
      }
    });
  };

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "series",
      text: "Series",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "series_type",
      text: "Series Type",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "start_date",
      text: "Start Date",
      className: "date",
      align: "left",
      sortable: true,
    },
    {
      key: "end_date",
      text: "End Date",
      className: "date",
      align: "left",
      sortable: true,
    },
    {
      key: "total_matches",
      text: "Total Matches",
      className: "total_matches",
      align: "left",
      sortable: true,
    },
    {
      key: "series_status",
      text: "Status",
      className: "total_matches",
      align: "left",
      sortable: true,
    },
    {
      key: "list",
      text: "Match List",
      className: "match",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Link
            to={`/series-match/${record.series_id}`}
            className="btn btn-primary"
          >
            List
          </Link>
        );
      },
    },
    {
      key: "news-list",
      text: "News List",
      className: "news",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Link
            to={`/series-news/${record.series_id}`}
            className="btn btn-primary"
          >
            List
          </Link>
        );
      },
    },
    {
      key: "point-list",
      text: "Point List",
      className: "point",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Link
            to={`/series-point/${record.series_id}/${record.series}`}
            className="btn btn-primary"
          >
            List
          </Link>
        );
      },
    },
    {
      key: "view",
      text: "Series view",
      className: "point",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Link
            to={`/series-view/${record.series_id}`}
            className="btn btn-primary"
          >
            View
          </Link>
        );
      },
    },
    {
      key: "Action",
      text: "Is Treding",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button onClick={()=>{updateStatus(record.series_id)}}  disabled={disable}>{record.is_trending==='1'?"Trending":"Not Trending"}</button>
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link
              to={`/update-series/${record.series_id}`}
              className="btn btn-primary"
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Series List</b>
              </h2>
            </div>
            <div >
              <div className="product-list-outer all-matches">
                <div className="buttonDirection">
                  <Link to="/add-series" className="btn btn-primary">
                    Add Series
                  </Link>
                </div>
                <ReactDatatable
                  config={config}
                  records={data}
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Series;
