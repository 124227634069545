import { getVenueApi, getVenueByIdApi, getVenueSeriesIdApi, insertVenueApi, updateVenueApi } from "../components/constant/Api";
import { myApi } from "./api";

export const venueApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getVenue: builder.query({
      query: () => ({
        url: getVenueApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["venue"],
    }),
    setVenue: builder.mutation({
      query: (post) => ({
        url: insertVenueApi,
        method: "POST",
        body: post,
        formData: true,
      }),

      invalidatesTags: (_) => ["venue"],
    }),
    getVenueByseries: builder.mutation({
      query: (post) => ({
        url: getVenueSeriesIdApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },

    }),
    updateVenue: builder.mutation({
      query: (post) => ({
        url: updateVenueApi,
        method: "PUT",
        body: post,
      }),
      invalidatesTags: (_) => ["venue"]
    }),
    getVenueById: builder.query({
      query: (post) => ({
        url: getVenueByIdApi+"/"+post.venue_id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? "" : "";
      },

    }),
  }),
});

export const { useSetVenueMutation, useGetVenueQuery,useGetVenueByseriesMutation,useUpdateVenueMutation,useGetVenueByIdQuery } = venueApi;
