import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { InputValid } from "../../validations/InputValid";
import { useSetCountryMutation } from "../../../redux/countryApi";

function AddCountryModal(props) {
  const [setCountry] = useSetCountryMutation();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [shortName, setShortName] = useState("");
  const [shortNameErr, setShortNameErr] = useState("");
  const [phoneCode, setPhoneCode] = useState("");

  const handleClose = () => {
    setName("");
    setNameErr("");
    setShortName("");
    setShortNameErr("");
    setPhoneCode("");
    props.setAddModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "shortName") {
      setShortName(value);
      const err = InputValid(name, value);
      setShortNameErr(err);
    }
    if (name === "phoneCode") {
      setPhoneCode(value);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const checkName = InputValid("name", name);
    const checkShortName = InputValid("shortName", shortName);
    if (checkName) {
      setNameErr(checkName);
      return false;
    }
    if (checkShortName) {
      setShortNameErr(checkShortName);
      return false;
    }
    const data = {
      name: name,
      short_name: shortName,
      phonecode: phoneCode,
    };
    setCountry(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div>
      <Modal show={props.addModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Country</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={name}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{nameErr}</span>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Short Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    name="shortName"
                    onChange={handleChange}
                    type="text"
                    value={shortName}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{shortNameErr}</span>
                </Form.Group>
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone Code</Form.Label>
                  <Form.Control
                    name="phoneCode"
                    onChange={handleChange}
                    type="tel"
                    value={phoneCode}
                  ></Form.Control>
                </Form.Group> */}
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddCountryModal;
