import {
  addSessionApi,
  getLiveMatchApi,
  getMatchApi,
  getMatchByIdApi,
  getMatchSeriesIdApi,
  getRecentMatchApi,
  getSeriesMatchApi,
  getUpcomingMatchApi,
  insertMatchApi,
  updateCommentApi,
  updateMatchApi,
  updateMoveLiveApi,
  updateRateApi,
  updateResultApi,
  updateStatusApi,
  updateTossApi,
  updateUmpireApi,
  updateYetToBatApi,
} from "../components/constant/Api";
import { myApi } from "./api";

export const matchApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getMatch: builder.query({
      query: () => ({
        url: getMatchApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["match"],
    }),
    setMatches: builder.mutation({
      query: (post) => ({
        url: insertMatchApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["series"],
    }),
    updateMatches: builder.mutation({
      query: (post) => ({
        url: updateMatchApi,
        method: "PUT",
        body: post,
      }),

      invalidatesTags: (_) => ["series"],
    }),
    getUpcomingMatch: builder.query({
      query: (post) => ({
        url: getUpcomingMatchApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["match"],
    }),
    getLiveMatch: builder.query({
      query: (post) => ({
        url: getLiveMatchApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["match"],
    }),
    getRecentMatch: builder.query({
      query: (post) => ({
        url: getRecentMatchApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["match"],
    }),
    getMatchSeriesId: builder.mutation({
      query: (post) => ({
        url: getMatchSeriesIdApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getMatchById: builder.mutation({
      query: (post) => ({
        url: getMatchByIdApi,
        method: "POSt",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? "" : "";
      },
    }),
    updateToss: builder.mutation({
      query: (post) => ({
        url: updateTossApi,
        method: "PUT",
        body: post,
      }),
    }),
    updateUmpire: builder.mutation({
      query: (post) => ({
        url: updateUmpireApi,
        method: "PUT",
        body: post,
      }),
    }),
    updateStatus: builder.mutation({
      query: (post) => ({
        url: updateStatusApi,
        method: "PUT",
        body: post,
      }),
    }),
    updateResult: builder.mutation({
      query: (post) => ({
        url: updateResultApi,
        method: "PUT",
        body: post,
      }),
    }),
    updateRate: builder.mutation({
      query: (post) => ({
        url: updateRateApi,
        method: "PUT",
        body: post,
      }),
    }),
    updateComment: builder.mutation({
      query: (post) => ({
        url: updateCommentApi,
        method: "PUT",
        body: post,
      }),
    }),
    setSessions: builder.mutation({
      query: (post) => ({
        url: addSessionApi,
        method: "PUT",
        body: post,
      }),
    }),
    updateYetToBat: builder.mutation({
      query: (post) => ({
        url: updateYetToBatApi,
        method: "PUT",
        body: post,
      }),
    }),
    updateMoveLive: builder.mutation({
      query: (post) => ({
        url: updateMoveLiveApi+"/"+post.match_id,
        method: "PUT",
      }),
      invalidatesTags: (_) => ["match"],
    }),
    getSeriesMatch: builder.query({
      query: (post) => ({
        url: getSeriesMatchApi,
        method: "Get",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["match"],
    }),
  }),
});

export const {
  useGetLiveMatchQuery,
  useGetRecentMatchQuery,
  useGetUpcomingMatchQuery,
  useGetMatchQuery,
  useSetMatchesMutation,
  useGetMatchSeriesIdMutation,
  useUpdateMatchesMutation,
  useGetMatchByIdMutation,
  useUpdateTossMutation,
  useUpdateUmpireMutation,
  useUpdateStatusMutation,
  useUpdateResultMutation,
  useUpdateYetToBatMutation,
  useUpdateMoveLiveMutation,
  useGetSeriesMatchQuery,
  useUpdateRateMutation,
  useUpdateCommentMutation,
  useSetSessionsMutation
} = matchApi;
