import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useUpdateResultMutation } from "../../../../redux/matchApi";

function Result({ data, squad, setResult, getMatchDetail, matchDetail }) {
  const [updateResult] = useUpdateResultMutation();
  const [winner, setwinner] = useState("");
  const [team, setTeam] = useState("");
  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState("");
  const [wonBy, setWonBy] = useState("");
  const [wonBySelect, setWonBySelect] = useState("");
  const [teamAscore, setteamAscore] = useState("");
  const [teamBscore, setteamBscore] = useState("");
  const [teamAover, setteamAover] = useState("");
  const [teamBover, setteamBover] = useState("");
  const [preview, setPreview] = useState("");
  const handleWinner = (value) => {
    setwinner(value);
    setPreview(value);
  };

  useEffect(() => {
    if (team) {
      if (squad?.team_a?.team_id == team) {
        setPlayers(squad?.team_a?.player);
      } else {
        setPlayers(squad?.team_b?.player);
      }
    }
  }, [team]);

  useEffect(() => {
    if (matchDetail?.team_a_score || matchDetail?.team_b_score) {
      setteamAscore(matchDetail?.team_a_scores);
      setteamBscore(matchDetail?.team_b_scores);
      setteamAover(matchDetail?.team_a_over);
      setteamBover(matchDetail?.team_b_over);
    }
  }, [matchDetail]);

  useEffect(() => {
    if (data && data?.team_a_score && data?.team_a_score) {
      setteamAscore(data?.team_a_scores);
      setteamBscore(data?.team_b_scores);
      setteamAover(data?.team_a_over);
      setteamBover(data?.team_b_over);
      setPreview(data?.result);
      setwinner(data?.winner ? data?.winner : "");
      setWonBy(data?.won_by ? data?.won_by : "");
      setWonBySelect(data?.won_by_select ? data?.won_by_select : "");
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let result;
    if (name === "wonBy") {
      setWonBy(value);
      result = winner + " won by " + value + " " + wonBySelect;
    }
    if (name === "wonBySelect") {
      setWonBySelect(value);
      result = winner + " won by " + wonBy + " " + value;
    }
    setPreview(result);
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    let result;
    if (wonBy && wonBySelect) {
      result = winner + " won by " + wonBy + " " + wonBySelect;
    } else {
      result = winner;
    }
    setResult(result);
    const updateData = {
      result: result,
      match_id: data?.match_id,
      winner: winner,
      won_by: wonBy,
      won_by_select: wonBySelect,
    };
    updateResult(updateData).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        getMatchDetail();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div className="result">
      <div className="row">
        <div className="col-md-3 d-flex align-items-center">
          <p>Enter Result or Go for Super Over</p>
        </div>
        <div className="col-md-9">
          <form onSubmit={handlesubmit}>
            <div className="res-btn">
              <span>Winner</span>

              <span
                className={`span ${
                  winner === data?.team_a_name ? "active" : ""
                }`}
                onClick={() => {
                  handleWinner(data?.team_a_name);
                  setTeam(data?.team_a_id);
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id={data?.team_a_name}
                  autocomplete="off"
                  onClick={() => {
                    handleWinner(data?.team_a_name);
                    setTeam(data?.team_a_id);
                  }}
                />
                <label for={data?.team_a_name}>{data?.team_a_name}</label>
              </span>
              <span
                className={`span ${
                  winner === data?.team_b_name ? "active" : ""
                }`}
                onClick={() => {
                  handleWinner(data?.team_b_name);
                  setTeam(data?.team_b_id);
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id={data?.team_b_name}
                  autocomplete="off"
                  onClick={() => {
                    handleWinner(data?.team_b_name);
                    setTeam(data?.team_b_id);
                  }}
                />
                <label for={data?.team_b_name}>{data?.team_b_name}</label>
              </span>
              <span
                className={`span ${winner === "Tied" ? "active" : ""}`}
                onClick={() => {
                  handleWinner("Tied");
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id="Tied"
                  autocomplete="off"
                  onClick={() => {
                    handleWinner("Tied");
                  }}
                />
                <label for="Tied">Tied</label>
              </span>
              <span
                className={`span ${winner === "Draw" ? "active" : ""}`}
                onClick={() => {
                  handleWinner("Draw");
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id="Draw"
                  autocomplete="off"
                  onClick={() => {
                    handleWinner("Draw");
                  }}
                />
                <label for="Draw">Draw</label>
              </span>
              <span
                className={`span ${
                  winner === "Match Abandoned" ? "active" : ""
                }`}
                onClick={() => {
                  handleWinner("Match Abandoned");
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id="Match-Abandoned"
                  autocomplete="off"
                  onClick={() => {
                    handleWinner("Match Abandoned");
                  }}
                />
                <label for="Match-Abandoned">Match Abandoned</label>
              </span>

              <span
                className={`span ${winner === "Cancelled" ? "active" : ""}`}
                onClick={() => {
                  handleWinner("Cancelled");
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id="Cancelled"
                  autocomplete="off"
                  onClick={() => {
                    handleWinner("Cancelled");
                  }}
                />
                <label for="Cancelled">Cancelled</label>
              </span>
              <span
                className={`span ${
                  winner === "Rain Cancelled" ? "active" : ""
                }`}
                onClick={() => {
                  handleWinner("Rain Cancelled");
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id="Rain-Cancelled"
                  autocomplete="off"
                  onClick={() => {
                    handleWinner("Rain Cancelled");
                  }}
                />
                <label for="Rain-Cancelled">Rain Cancelled</label>
              </span>

              <span
                className={`span ${
                  winner === "Low Light Cancelled" ? "active" : ""
                }`}
                onClick={() => {
                  handleWinner("Low Light Cancelled");
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id=" Low-Light-Cancelled"
                  autocomplete="off"
                  onClick={() => {
                    handleWinner("Low Light Cancelled");
                  }}
                />
                <label for=" Low-Light-Cancelled">Low Light Cancelled</label>
              </span>
              <span
                className={` span ${winner === "No Result" ? "active" : ""}`}
                onClick={() => {
                  handleWinner("No Result");
                }}
              >
                <input
                  type="checkbox"
                  class="btn-check"
                  id="btn-check-outlined"
                  autocomplete="off"
                  onClick={() => {
                    handleWinner("No Result");
                  }}
                />
                <label for="btn-check-outlined">No Result</label>
              </span>
              <select
                onChange={(e) => {
                  setPlayer(e.target.value);
                }}
                value={player}
              >
                <option value="">select player</option>
                {players.map((list) => {
                  return (
                    <option value={list.player_name}>{list.player_name}</option>
                  );
                })}
              </select>
            </div>
            <div className="won-by">
              <span>Won by</span>
              <input
                value={wonBy}
                type="number"
                name="wonBy"
                className="ms-3"
                onChange={handleChange}
              />
              <select
                className="ms-3"
                name="wonBySelect"
                value={wonBySelect}
                onChange={handleChange}
              >
                <option value="">select</option>
                <option value="run">run</option>
                <option value="wicket">wicket</option>
              </select>
            </div>
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </form>
          <div className="preview">
            <p className="p-head">Preview : </p>
            <p className="p-result">{preview}</p>
          </div>
          <div className="mt-3 res-score">
            <p>
              {data?.team_a_name} : {teamAscore} Overs {teamAover}
            </p>
            <p>
              {data?.team_b_name} : {teamBscore} Overs {teamBover}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Result;
