import { addOverRunApi, getOverRunApi } from "../components/constant/Api";
import { myApi } from "./api";
  
  export const overRunApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getOverRun: builder.mutation({
        query: (post) => ({
          url: getOverRunApi,
          method: "POST",
          body:post
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] : [];
        },
      }),
      setOverRun: builder.mutation({
        query: (post) => ({
          url: addOverRunApi,
          method: "POST",
          body: post,
          formData: true,
        })
      }),
    }),
  });
  
  export const {useSetOverRunMutation,useGetOverRunMutation } =overRunApi;
  