import { getTeamApi, getteamByIdApi, getTeamSeriesIdApi, insertTeamApi, updateTeamApi } from "../components/constant/Api";
import { myApi } from "./api";

export const teamApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeam: builder.query({
      query: () => ({
        url: getTeamApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["team"],
    }),
    setTeam: builder.mutation({
      query: (post) => ({
        url: insertTeamApi,
        method: "POST",
        body: post,
        formData: true,
      }),

      invalidatesTags: (_) => ["team"],
    }),
    getTeamByseries: builder.mutation({
      query: (post) => ({
        url: getTeamSeriesIdApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },

    }),
    updateTeam: builder.mutation({
      query: (post) => ({
        url: updateTeamApi,
        method: "PUT",
        body: post,
        formData: true,
      }),

      invalidatesTags: (_) => ["team"],
    }),
    getTeamById: builder.query({
      query: (post) => ({
        url: getteamByIdApi+"/"+post.team_id,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? "" : "";
      },

    }),
  }),
});

export const { useSetTeamMutation, useGetTeamQuery,useGetTeamByseriesMutation,useUpdateTeamMutation,useGetTeamByIdQuery } = teamApi;
