export const VenueValid = (name, value) => {
  let error = "";
  if (name === "venue_name") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    return error;
  }
  // if (name === "opened") {
  //   if (value === "") {
  //     error = "This field is required";
  //     return error;
  //   }
  //   return error;
  // }
  // if (name === "capacity") {
  //   if (value === "") {
  //     error = "This field is required";
  //     return error;
  //   }
  //   return error;
  // }
  if (name === "location") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    return error;
  }
  // if (name === "time_zone") {
  //   if (value === "") {
  //     error = "This field is required";
  //     return error;
  //   }
  //   return error;
  // }
  else{
    return error
  }
};
