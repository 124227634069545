import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { SocketUrl } from "../../constant/BaseUrl";
import FullScoreCard from "./FullScoreCard";
import Live from "./live/Live";
import Info from "./info/Info";
import Commentary from "./commentary/Commentary";

function MatchDetail() {
  const { match_id } = useParams();
  const [activeTag, setActiveTag] = useState("Live");
  const [ws, setWs] = useState(null);

  useEffect(() => {
    const webSocketCall=()=>{
      const newWs = new WebSocket(SocketUrl);
      newWs.onopen = () => {
        console.log("WebSocket connected");
      };
      newWs.onclose = () => {
        console.log("WebSocket disconnected");
        webSocketCall()
        
      };
      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        webSocketCall()
      };
      setWs(newWs);
    }
    
    webSocketCall()
  }, []);
 


  const sendData = (eventType, data) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const eventData = {
        eventType,
        data,
      };
      ws.send(JSON.stringify(eventData));
    } else {
      console.error("WebSocket connection not open.");
    }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div>
              <ul className="nav nav-tabs2">
                <li className="nav-item">
                  <span
                    className={`nav-link ${
                      activeTag === "Info" ? "active" : ""
                    }`}
                    aria-current="page"
                    onClick={() => {
                      setActiveTag("Info");
                    }}
                  >
                    Info
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={`nav-link ${
                      activeTag === "Live" ? "active" : ""
                    }`}
                    aria-current="page"
                    onClick={() => {
                      setActiveTag("Live");
                    }}
                  >
                    Live
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={`nav-link ${
                      activeTag === "Scorecard" ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveTag("Scorecard");
                    }}
                  >
                    Scorecard
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    className={`nav-link ${
                      activeTag === "Full_Commentary" ? "active" : ""
                    }`}
                    tabIndex="-1"
                    onClick={() => {
                      setActiveTag("Full_Commentary");
                    }}
                  >
                    Full Commentary
                  </span>
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="">
                <div className="product-list-outer">
                  <div className="add-product-btn text-center"></div>
                </div>
                {activeTag === "Info" ? <Info match_id={match_id} /> : ""}
                {activeTag === "Live" ? (
                  <Live match_id={match_id} sendData={sendData} ws={ws} />
                ) : (
                  ""
                )}
                {/* {activeTag === "SquadXI" ? <SquadXI match_id={match_id} /> : ""}
                {activeTag === "Squads" ? <Squad match_id={match_id} /> : ""} */}
                {activeTag === "Scorecard" ? (
                  <FullScoreCard match_id={match_id} />
                ) : (
                  ""
                )}
                {activeTag === "Full_Commentary"
                  ? <Commentary  match_id={match_id} sendData={sendData} ws={ws} />
                  : 
                    ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MatchDetail;
