import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { ImageValid } from "../../validations/ImageValid";
import { InputValid } from "../../validations/InputValid";
import { useSetTeamMutation } from "../../../redux/teamApi";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetCountryQuery } from "../../../redux/countryApi";
import { FormatArray, GenderEnum, TypeArray } from "../../constant/enum";
import { useNavigate } from "react-router-dom";

function AddTeam() {
  const navigation = useNavigate();
  const { data: countryList } = useGetCountryQuery();
  const [setTeam] = useSetTeamMutation();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [shortName, setShortName] = useState("");
  const [shortNameErr, setShortNameErr] = useState("");
  const [lowScore, setLowScore] = useState("0");
  const [lowScoreErr, setLowScoreErr] = useState("");
  const [highScore, setHighScore] = useState("0");
  const [HighScoreErr, setHighScoreErr] = useState("");
  const [seriesType, setSeriesType] = useState("");
  const [win, setWin] = useState("0");
  const [country, setCountry] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [board, setBoard] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState("");
  const [format, setFormat] = useState([]);
  const [type, setType] = useState([]);

  const handleClose = () => {
    setName("");
    setNameErr("");
    setShortName("");
    setShortNameErr("");
    setLowScore("0");
    setLowScoreErr("");
    setHighScore("0");
    setHighScoreErr("");
    setImage("");
    setImageErr("");
    setBoard("");
    setSeriesType("");
    setWin("0");
    setCountry("");
    setCountryErr("");
    setGender("");
    setGenderErr("");
    setFormat([]);
    setType([]);
  };

  const handleFormatChecked = (value) => {
    // Check if the id is already in the array

    const index = format.indexOf(value);

    if (index !== -1) {
      // If id is already present, remove it
      const updateFormat = [...format];
      updateFormat.splice(index, 1);
      setFormat(updateFormat);
    } else {
      // If id is not present, add it
      setFormat([...format, value]);
    }
  };
  const handleTypeChecked = (value) => {
    // Check if the id is already in the array
    const index = type.indexOf(value);

    if (index !== -1) {
      // If id is already present, remove it
      const updateType = [...type];
      updateType.splice(index, 1);
      setType(updateType);
    } else {
      // If id is not present, add it
      setType([...type, value]);
    }
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "teamimage") {
      setImage(image);
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "shortName") {
      setShortName(value);
      const err = InputValid(name, value);
      setShortNameErr(err);
    }
    if (name === "lowScore") {
      setLowScore(value);
      const err = InputValid(name, value);
      setLowScoreErr(err);
    }
    if (name === "highScore") {
      setHighScore(value);
      const err = InputValid(name, value);
      setHighScoreErr(err);
    }
    if (name === "country") {
      setCountry(value);
      const err = InputValid(name, value);
      setCountryErr(err);
    }
    if (name === "gender") {
      setGender(value);
      const err = InputValid(name, value);
      setGenderErr(err);
    }
    if (name === "seriesType") {
      setSeriesType(value);
    }
    if (name === "board") {
      setBoard(value);
    }
    if (name === "win") {
      setWin(value);
    }
  };

  const onsubmit = async (e) => {
    e.preventDefault();
    const checkName = InputValid("name", name);
    const checkShortName = InputValid("shortName", shortName);
    const checkCountry = InputValid("country", country);
    const checkGender = InputValid("gender", gender);
    if (checkName) {
      setNameErr(checkName);
      return false;
    }
    if (checkShortName) {
      setShortNameErr(checkShortName);
      return false;
    }
    if (checkGender) {
      setGenderErr(checkGender);
      return false;
    }
    if (checkCountry) {
      setCountryErr(checkCountry);
      return false;
    }
    // let checkImage = ImageValid("image", image);
    // if (checkImage) {
    //   setImageErr(checkImage);
    //   return false;
    // }
    let formats = format.join(",");
    let types = type.join(",");
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("short_name", shortName);
    formdata.append("low_score", lowScore);
    formdata.append("high_score", highScore);
    formdata.append("team_image", image);
    formdata.append("series_type", seriesType);
    formdata.append("board", board);
    formdata.append("win", win ? win : "0");
    formdata.append("country_id", country);
    formdata.append("format", formats);
    formdata.append("type", types);
    formdata.append("gender", gender);
    setTeam(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        navigation("/teams");
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content mb-4">
          <div className="page-content">
            <div className="section-heading">
              <h2 className="mt-3">
                <b>Add Team</b>
              </h2>
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <Form onSubmit={onsubmit}>
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {" "}
                          Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          name="name"
                          onChange={handlechange}
                          type="text"
                          value={name}
                        ></Form.Control>
                        <span style={{ color: "red" }}>{nameErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Short Name <span className="text-danger">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          name="shortName"
                          onChange={handlechange}
                          type="text"
                          value={shortName}
                        ></Form.Control>
                        <span style={{ color: "red" }}>{shortNameErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Gender <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="gender"
                          onChange={handlechange}
                          value={gender}
                        >
                          <option value="">Please select Gender</option>
                          {GenderEnum?.map((list) => {
                            return (
                              <option value={list.value}>{list.key}</option>
                            );
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{genderErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Type</Form.Label>
                        <div className="check-boxes d-flex">
                          {TypeArray.map((list) => {
                            const isChecked = type.includes(list);
                            return (
                              <div className="me-5">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => {
                                    handleTypeChecked(list);
                                  }}
                                  id={list}
                                />
                                <label
                                  className="form-check-label mx-2"
                                  htmlFor={list}
                                >
                                  {list}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Format</Form.Label>
                        <div className="d-flex">
                          {FormatArray.map((list) => {
                            const isChecked = format.includes(list);
                            return (
                              <div className="me-5">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={() => {
                                    handleFormatChecked(list);
                                  }}
                                  id={list}
                                />
                                <label
                                  className="form-check-label mx-2"
                                  htmlFor={list}
                                >
                                  {list}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Series Type </Form.Label>
                        <Form.Control
                          name="seriesType"
                          onChange={handlechange}
                          type="text"
                          value={seriesType}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Board</Form.Label>
                        <Form.Control
                          name="board"
                          onChange={handlechange}
                          type="text"
                          value={board}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Country <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="country"
                          onChange={handlechange}
                          value={country}
                        >
                          <option value="">Please select Country</option>
                          {countryList?.map((list) => {
                            return <option value={list.id}>{list.name}</option>;
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{countryErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Low Score </Form.Label>
                        <Form.Control
                          name="lowScore"
                          onChange={handlechange}
                          type="tel"
                          value={lowScore}
                        ></Form.Control>
                        <span style={{ color: "red" }}>{lowScoreErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>High Score </Form.Label>
                        <Form.Control
                          name="highScore"
                          onChange={handlechange}
                          type="tel"
                          value={highScore}
                        ></Form.Control>
                        <span style={{ color: "red" }}>{HighScoreErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Tournament Wons</Form.Label>
                        <Form.Control
                          name="win"
                          onChange={handlechange}
                          type="tel"
                          value={win}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Image
                        </Form.Label>
                        <img
                          style={{ width: "100px" }}
                          src={image}
                          className="img-fluid"
                          alt=""
                        />
                        <Form.Control
                          type="file"
                          name="teamimage"
                          onChange={handlechangeimage}
                        />
                        <span style={{ color: "red" }}>{imageErr}</span>
                      </Form.Group>
                    </div>
                  </div>

                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTeam;
