import React, { useEffect, useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { useParams } from "react-router-dom";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetPointBySeriesMutation } from "../../../redux/pointApi";
import AddPointModal from "../../partial/point/AddPointModal";
import UpdatePointModal from "../../partial/point/UpdatePointModal";
function PointSeries() {
  const { series_id, series_name } = useParams();
  const [getPointBySeries, { data }] = useGetPointBySeriesMutation({
    series_id,
  });
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "team_name",
      text: "Team Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "P",
      text: "P",
      className: "P",
      align: "left",
      sortable: true,
    },
    {
      key: "W",
      text: "W",
      className: "W",
      align: "left",
      sortable: true,
    },
    {
      key: "Pts",
      text: "Pts",
      className: "Pts",
      align: "left",
      sortable: true,
    },
    {
      key: "NRR",
      text: "NRR",
      className: "NRR",
      align: "left",
      sortable: true,
    },
    {
      key: "NR",
      text: "NR",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              onClick={() => {
                editFee(record);
              }}
              className="btn btn-primary"
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  useEffect(() => {
    getPoint();
  }, [series_id]);

  const getPoint = () => {
    const data = { series_id };
    getPointBySeries(data);
  };

  const editFee = (record) => {
    setUpdateModal(true);
    setCurrentRecord(record);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
            <div className="section-heading">
              <h2>
                <b>{series_name} Point List</b>
              </h2>
            </div>
            <div>
              <div className="product-list-outer all-matches">
                <div className="buttonDirection">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setAddModal(true);
                    }}
                  >
                    Add Point
                  </button>
                </div>
                <ReactDatatable
                  config={config}
                  records={data}
                  columns={columns}
                />
              </div>
            </div>
            <AddPointModal
              addModal={addModal}
              setAddModal={setAddModal}
              series_id={series_id}
              getPoint={getPoint}
            />
            <UpdatePointModal
              updateModal={updateModal}
              setUpdateModal={setUpdateModal}
              currentRecord={currentRecord}
              series_id={series_id}
              getPoint={getPoint}
            />
         
        </div>
      </div>
    </div>
  );
}

export default PointSeries;
