import { addCountryapi, getCountryApi, updateCountryApi } from "../components/constant/Api";
import { myApi } from "./api";

export const countryApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountry: builder.query({
      query: () => ({
        url: getCountryApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
      providesTags:(_)=>["country"]
    }),
    setCountry: builder.mutation({
      query: (post) => ({
        url: addCountryapi,
        method: "POST",
        body:post
      }),
      invalidatesTags: (_) => ["country"],
    }),
    updateCountry: builder.mutation({
      query: (post) => ({
        url: updateCountryApi,
        method: "PUT",
        body:post
      }),
      invalidatesTags: (_) => ["country"],
    }),
  
  }),
});

export const {useGetCountryQuery,useUpdateCountryMutation,useSetCountryMutation } = countryApi;