import React,{useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { useGetNewsByIdMutation, useGetNewsQuery } from '../../../redux/newsApi';
import Header from '../../widgets/Header';
import Navbar from '../../widgets/Navbar';

function NewsDetail() {
    const {news_id}=useParams()
    const [getNewsById,{ data }] = useGetNewsByIdMutation();
    useEffect(() => {
      getNewsById({news_id})
    }, [])
    
  return (
    <div id="layout-wrapper">
    <Header />
    <Navbar />
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="section-heading">
            <h2>
              <b>News Detail</b>
            </h2>
          </div>
          <div className="product-list-outer">
          <div className="row">
                <div className="col-xxl-5">
                <div className="card p-3 p-md-4">
                  {/* <div className="product-list-outer">
                    <div className="add-product-btn text-center"></div>
                  </div> */}
                  <div className="row  align-items-center">
                    <div className="col-md-5">
                      <div>
                        <img className="news_detail_img" src={data?.image} alt="" />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div>
                        <h3>{data?.title}</h3>
                        <p>{data?.pub_date}</p>
                        <p>{data?.description}</p>
                      </div>
                    </div>
                    <div className="col-12 mt-3 mb-4">
                     
                          <div 
                            dangerouslySetInnerHTML={{ __html: data?.content }}
                          />
                    
                    </div>
                  
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NewsDetail
