import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  useUpdateCommentMutation,
  useUpdateRateMutation,
} from "../../../../redux/matchApi";

function ScoreCard({ matchDetail, data, getMatchDetail }) {
  const [updateRate] = useUpdateRateMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [teamAscore, setteamAscore] = useState("");
  const [teamBscore, setteamBscore] = useState("");
  const [teamAover, setteamAover] = useState("");
  const [teamBover, setteamBover] = useState("");
  const [comment, setComment] = useState("");
  const [favTeam, setFavTeam] = useState("");
  const [session, setSession] = useState({
    sOver: "",
    sMin: "",
    sMax: "",
    sBall: "",
    sRun: "",
  });
  const [rate, setRate] = useState({
    minRate: "",
    maxRate: "",
    minRate1: "",
    maxRate1: "",
    minRate2: "",
    maxRate2: "",
  });
  const [lambi, setLambi] = useState({
    lambi_min: "",
    lambi_max: "",
    lambi_ovr: "",
  });

  useEffect(() => {
    if (matchDetail?.team_a_score || matchDetail?.team_b_score) {
      setteamAscore(matchDetail?.team_a_scores);
      setteamBscore(matchDetail?.team_b_scores);
      setteamAover(matchDetail?.team_a_over);
      setteamBover(matchDetail?.team_b_over);
    }
  }, [matchDetail]);

  useEffect(() => {
    if (data && data?.team_a_score && data?.team_a_score) {
      setteamAscore(data?.team_a_scores);
      setteamBscore(data?.team_b_scores);
      setteamAover(data?.team_a_over);
      setteamBover(data?.team_b_over);
    }
    if(data){
      setFavTeam(data?.fav_team);
      setSession({
        sOver: data?.s_ovr,
        sMin: data?.s_min,
        sMax: data?.s_max,
        sBall: data?.s_ball,
        sRun: data?.s_run,
      });
      setRate({
        minRate: data?.min_rate,
        maxRate: data?.max_rate,
        minRate1: data?.min_rate1,
        maxRate1: data?.max_rate1,
        minRate2: data?.min_rate2,
        maxRate2: data?.max_rate2,
      });
      setLambi({
        lambi_min: data?.lambi_min,
        lambi_max: data?.lambi_max,
        lambi_ovr: data?.lambi_ovr,
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "comment") {
      setComment(value);
    }
  };

  const submitComment = (e) => {
    e.preventDefault();
    const updateData = {
      comment_1: comment,
      match_id: data?.match_id,
    };
    updateComment(updateData).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setComment("");
        getMatchDetail();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  const handleSession = (e) => {
    const { name, value } = e.target;
    setSession({ ...session, [name]: value });
  };

  const handleLambi = (e) => {
    const { name, value } = e.target;
    setLambi({ ...lambi, [name]: value });
  };

  const handleRate = (e) => {
    const { name, value } = e.target;
    setRate({ ...rate, [name]: value });
  };

  const handleChage = (e) => {
    const { name, value } = e.target;
    if (name === "favTeam") {
      setFavTeam(value);
    }
  };

  const submitRate = (e) => {
    e.preventDefault();
    const updateData = {
      min_rate: rate.minRate,
      max_rate: rate.maxRate,
      s_ovr: session.sOver,
      s_min: session.sMin,
      s_max: session.sMax,
      s_ball: session.sBall,
      s_run: session.sRun,
      min_rate1: rate.minRate1,
      max_rate1: rate.maxRate1,
      min_rate2: rate.minRate2,
      max_rate2: rate.maxRate2,
      fav_team: favTeam,
      lambi_ovr: lambi.lambi_ovr,
      lambi_min: lambi.lambi_min,
      lambi_max: lambi.lambi_max,
      match_id: data?.match_id,
    };
    updateRate(updateData)
      .then((result) => {
        if (result.data.status) {
          toast.dismiss();
          toast.success(result.data.message);
          getMatchDetail();
        }
      })
      .catch();
  };

  return (
    <div className="score-card">
      <div className="row">
        <div className="col-md-4 col-sm-6 col-12">
          <div className="team-score d-flex">
            <div className="team-inner playing-team">
              <div className="img-outer">
                <img
                  src={data?.team_a_img}
                  alt="team-flag"
                  className="img-fluid"
                />
              </div>
              <div className="name-score">
                <p className="m-0">{data?.team_a_short}</p>
                <h5>
                  {teamAscore} {teamAover ? "(" + teamAover + ")" : ""}
                </h5>
                {data?.team_a_id == data?.batting_team &&
                data?.match_status == "Live" ? (
                  <span>CRR: {matchDetail?.curr_rate}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="team-inner not-playing">
              <div className="img-outer">
                <img
                  src={data?.team_b_img}
                  alt="team-flag"
                  className="img-fluid"
                />
              </div>
              <div className="name-score">
                <p className="m-0">{data?.team_b_short}</p>
                <h5>
                  {teamBscore} {teamBover ? "(" + teamBover + ")" : ""}
                </h5>
                {data?.team_b_id == data?.batting_team &&
                data?.match_status == "Live" ? (
                  <span>CRR: {matchDetail?.curr_rate}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {data?.current_inning == "2" && data?.match_status == "Live" && data?.match_type !== "Test" ? (
            <div>
              <span className="ms-3">
                <b>Target :</b> {matchDetail?.target}
              </span>
              <span className="ms-3">
                <b>Ball rem :</b> {matchDetail?.ball_rem}
              </span>
              <span className="ms-3">
                <b>Run need :</b> {matchDetail?.run_need}
              </span>
            </div>
          ) : (
            data?.match_status == "Live"?<div>{matchDetail.trail_lead}</div>:""
          )}
        </div>
        {data?.match_type === "Test" ? (
          <div className="col-md-4 col-sm-6 col-12">
            <div className="test-match-rate team-detail">
              <form onSubmit={submitRate}>
                <ul className="nav justify-content-between align-items-center">
                  <li>
                    {data?.team_a_name}
                    <div className="score-number d-flex">
                      <div className="number-inner number-left text-center">
                        {/* <span>19</span> */}
                        <input
                          type="number"
                          name="minRate"
                          className="form-control"
                          value={rate.minRate}
                          onChange={handleRate}
                          min='0'
                        />
                      </div>
                      <div className="number-inner number-right text-center">
                        {/* <span>20</span> */}
                        <input
                          type="number"
                          name="maxRate"
                          className="form-control"
                          value={rate.maxRate}
                          onChange={handleRate}
                          min='0'
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    {data?.team_b_name}
                    <div className="score-number d-flex">
                      <div className="number-inner number-left text-center">
                        <input
                          type="number"
                          name="minRate1"
                          className="form-control"
                          value={rate.minRate1}
                          onChange={handleRate}
                          min='0'
                        />
                      </div>
                      <div className="number-inner number-right text-center">
                        <input
                          type="number"
                          name="maxRate1"
                          className="form-control"
                          value={rate.maxRate1}
                          onChange={handleRate}
                          min='0'
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    Draw
                    <div className="score-number d-flex">
                      <div className="number-inner number-left text-center">
                        {/* <span>20</span> */}
                        <input
                          type="number"
                          name="minRate2"
                          className="form-control"
                          value={rate.minRate2}
                          onChange={handleRate}
                          min='0'
                        />
                      </div>
                      <div className="number-inner number-right text-center">
                        {/* <span>22</span> */}
                        <input
                          type="number"
                          name="maxRate2"
                          className="form-control"
                          value={rate.maxRate2}
                          onChange={handleRate}
                          min='0'
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="nav justify-content-between align-items-center">
                  <li className="d-flex flex-wrap align-items-center">
                    Session :{" "}
                    <div className="fav-team">
                      <input
                        class="form-control"
                        type="number"
                        name="sOver"
                        value={session.sOver}
                        onChange={handleSession}
                        min='0'
                      />
                    </div>
                  </li>
                  <li className="max-min">
                    <div className="score-number d-flex">
                      <div className="number-inner number-left d-flex flex-wrap align-items-center">
                        <label>Min : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="sMin"
                          value={session.sMin}
                          onChange={handleSession}
                          min='0'
                        />
                      </div>
                      <div className="number-inner number-right d-flex flex-wrap align-items-center">
                        <label>Max : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="sMax"
                          value={session.sMax}
                          onChange={handleSession}
                          min='0'
                        />
                      </div>
                    </div>
                  </li>
                  <li className="max-min">
                    <div className="score-number d-flex">
                      <div className="number-inner number-left d-flex flex-wrap align-items-center">
                        <label>Ball : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="sBall"
                          value={session.sBall}
                          onChange={handleSession}
                          min='0'
                        />
                      </div>
                      <div className="number-inner number-right d-flex flex-wrap align-items-center">
                        <label>Run : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="sRun"
                          value={session.sRun}
                          onChange={handleSession}
                          min='0'
                        />
                      </div>
                    </div>
                  </li>
                </ul>

                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div className="col-md-4 col-sm-6 col-12">
            <div className="team-detail test-match-rate">
              <form onSubmit={submitRate}>
                <ul className="nav justify-content-between align-items-center">
                  <li className="d-flex align-items-center">
                    Fav Team
                    <div className="fav-team">
                      <select
                        className="form-control"
                        onChange={handleChage}
                        name="favTeam"
                        value={favTeam}
                      >
                        <option value="">select</option>
                        <option value={data?.team_a_short}>
                          {data?.team_a_short}
                        </option>
                        <option value={data?.team_b_short}>
                          {data?.team_b_short}
                        </option>
                      </select>
                    </div>
                  </li>

                  <li className="max-min">
                    <div className="score-number d-flex">
                      <div className="number-inner number-left d-flex flex-wrap align-items-center">
                        <label>Min : </label>
                        <input
                          type="number"
                          name="minRate"
                          className="form-control"
                          value={rate.minRate}
                          onChange={handleRate}
                          min="0"
                        />
                      </div>
                      <div className="number-inner number-right d-flex flex-wrap align-items-center">
                        {" "}
                        <label>Max : </label>
                        <input
                          type="number"
                          name="maxRate"
                          className="form-control"
                          value={rate.maxRate}
                          onChange={handleRate}
                          min="0"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="nav justify-content-between align-items-center">
                  <li className="d-flex flex-wrap align-items-center">
                    Session :{" "}
                    <div className="fav-team">
                      <input
                        class="form-control"
                        type="number"
                        name="sOver"
                        value={session.sOver}
                        onChange={handleSession}
                        min="0"
                      />
                    </div>
                  </li>
                  <li className="max-min">
                    <div className="score-number d-flex">
                      <div className="number-inner number-left d-flex flex-wrap align-items-center">
                        <label>Min : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="sMin"
                          value={session.sMin}
                          onChange={handleSession}
                          min="0"
                        />
                      </div>
                      <div className="number-inner number-right d-flex flex-wrap align-items-center">
                        <label>Max : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="sMax"
                          value={session.sMax}
                          onChange={handleSession}
                          min="0"
                        />
                      </div>
                    </div>
                  </li>
                  <li className="max-min">
                    <div className="score-number d-flex">
                      <div className="number-inner number-left d-flex flex-wrap align-items-center">
                        <label>Ball : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="sBall"
                          value={session.sBall}
                          onChange={handleSession}
                          min="0"
                        />
                      </div>
                      <div className="number-inner number-right d-flex flex-wrap align-items-center">
                        <label>Run : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="sRun"
                          value={session.sRun}
                          onChange={handleSession}
                          min="0"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="nav justify-content-between align-items-center">
                  <li>
                    Lambi:
                    <div className="fav-team">
                      <input
                        class="form-control"
                        type="number"
                        name="lambi_ovr"
                        value={lambi.lambi_ovr}
                        onChange={handleLambi}
                        min="0"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="score-number d-flex">
                      <div className="number-inner number-left text-center">
                        <label>Min : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="lambi_min"
                          value={lambi.lambi_min}
                          onChange={handleLambi}
                          min="0"
                        />
                      </div>
                      <div className="number-inner number-right text-center">
                        <label>Max : </label>
                        <input
                          class="form-control"
                          type="number"
                          name="lambi_max"
                          value={lambi.lambi_max}
                          onChange={handleLambi}
                          min="0"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        )}

        <div className="col-md-4 col-sm-6 col-12">
          <div className="match-toss text-center">
            <p>
              <b>{data?.toss ? data?.toss : "Toss"}</b>
            </p>
          </div>
          <div className="match-toss text-center mt-2">
            <form onSubmit={submitComment}>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Comment 1"
                  name="comment"
                  onChange={handleChange}
                  value={comment}
                ></textarea>
              </div>
              <div className="update-btn text-center w-100">
                <button
                  type="submit"
                  class="btn btn-primary"
                  disabled={data?.match_status == "Finished"}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScoreCard;
