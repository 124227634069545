import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { InputValid } from "../../validations/InputValid";
import {
  useGetPlayerQuery,
  useSetPlayerTeamMutation,
} from "../../../redux/playerApi";

function AddTeamPlayerModal(props) {
  const { data: playerList } = useGetPlayerQuery({});
  const [setPlayerTeam] = useSetPlayerTeamMutation();
  const [playerId, setPlayerId] = useState("");
  const [playerIdErr, setPlayerIdErr] = useState("");

  const handleClose = () => {
    setPlayerId("");
    setPlayerIdErr("");
    props.setAddModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "playerId") {
      setPlayerId(value);
      const err = InputValid(name, value);
      setPlayerIdErr(err);
    }
  };

  const onsubmit = async (e) => {
    e.preventDefault();
    const checkPlayer = InputValid("playerId", playerId);

    if (checkPlayer) {
      setPlayerIdErr(checkPlayer);
      return false;
    }
    const data = {
      team_id: props.teamId,
      player_id: playerId,
    };
    setPlayerTeam(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        props.getTeamPlayer()
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div>
      <Modal show={props.addModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Player</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Players </Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="playerId"
                onChange={handleChange}
                value={playerId}
              >
                <option value="">Please select Team</option>
                {playerList?.map((list) => {
                  return (
                    <option value={list.player_id} key={list.player_id}>
                      {list.player_name}
                    </option>
                  );
                })}
              </Form.Select>
              <span style={{ color: "red" }}>{playerIdErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddTeamPlayerModal;
