import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ImageValid } from "../../validations/ImageValid";
import { VenueValid } from "../../validations/VenueValid";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { toast } from "react-toastify";
import {
  useGetVenueByIdQuery,
  useUpdateVenueMutation,
} from "../../../redux/venueApi";
import { useNavigate, useParams } from "react-router-dom";

function UpdateVenue() {
  const { venue_id } = useParams();
  const { data, refetch } = useGetVenueByIdQuery({ venue_id });
  const [updateVenue] = useUpdateVenueMutation();
  const navigation = useNavigate();
  const [venueField, setVenueField] = useState({
    venue_name: "",
    opened: "",
    capacity: "",
    location: "",
    time_zone: "",
    known_as: "",
    dimensions: "",
    ends: "",
    home_to: "",
    other_sports: "",
    floodlights: "",
    curator: "",
    stats:""
  });
  const [venueFieldErr, setVenueFieldErr] = useState({
    venue_name: "",
    opened: "",
    capacity: "",
    location: "",
    time_zone: "",
  });
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState("");
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data) {
      setVenueField({
        venue_name: data.venue_name,
        opened: data.opened,
        capacity: data.capacity,
        location: data.location,
        time_zone: data.time_zone,
        known_as: data.known_as,
        dimensions: data.dimensions,
        ends: data.ends,
        home_to: data.home_to,
        other_sports: data.other_sports,
        floodlights: data.floodlights,
        curator: data.curator,
        stats:data?.stats?data.stats:""
      });
      setDescription(data.description);
      setImageUrl(data.venue_image);
    }
  }, [data]);

  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "venueimage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueField({ ...venueField, [name]: value });
    let checkVenue = VenueValid(name, value);
    setVenueFieldErr({ ...venueFieldErr, [name]: checkVenue });
  };
  const onsubmit = (e) => {
    e.preventDefault();
    for (let key in venueField) {
      let checkvenue = VenueValid(key, venueField[key]);
      setVenueFieldErr({ ...venueFieldErr, [key]: checkvenue });
      if (checkvenue !== "") {
        return false;
      }
    }
    // if (image) {
    //   let checkImage = ImageValid("image", image);
    //   if (checkImage) {
    //     setImageErr(checkImage);
    //     return false;
    //   }
    // }

    if (description === "") {
      setDescriptionErr("This field is required");
      return false;
    }
    const formdata = new FormData();
    formdata.append("venue_id", venue_id);
    formdata.append("venue_name", venueField.venue_name);
    formdata.append("description", description);
    formdata.append("opened", venueField.opened);
    formdata.append("capacity", venueField.capacity);
    formdata.append("known_as", venueField.known_as);
    formdata.append("dimensions", venueField.dimensions);
    formdata.append("ends", venueField.ends);
    formdata.append("location", venueField.location);
    formdata.append("time_zone", venueField.time_zone);
    formdata.append("home_to", venueField.home_to);
    formdata.append("other_sports", venueField.other_sports);
    formdata.append("floodlights", venueField.floodlights);
    formdata.append("curator", venueField.curator);
    formdata.append("stats", venueField.stats);
    formdata.append("venue_image", image);
    updateVenue(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        if(localStorage.getItem('path')){
          navigation(localStorage.getItem('path'));
          localStorage.removeItem('path')
        }
        else{

          navigation("/venue");
        }
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Update Venue</b>
              </h2>
            </div>
            <div className="card mt-3">
            <div className="product-list-outer card-body">
              <Form onSubmit={onsubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Name* </Form.Label>
                      <Form.Control
                        name="venue_name"
                        onChange={handleChange}
                        type="text"
                        value={venueField.venue_name}
                      ></Form.Control>
                      <span style={{ color: "red" }}>
                        {venueFieldErr.venue_name}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        {" "}
                        Establish Year
                        
                      </Form.Label>
                      <Form.Control
                        name="opened"
                        onChange={handleChange}
                        type="number"
                        value={venueField.opened}
                        min="1800" max="2099"
                        step="1"
                  
                      ></Form.Control>
                      <span style={{ color: "red" }}>
                        {venueFieldErr.opened}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Capacity </Form.Label>
                      <Form.Control
                        name="capacity"
                        onChange={handleChange}
                        type="tel"
                        value={venueField.capacity}
                        onKeyPress={(event) => {
                          if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      ></Form.Control>
                      <span style={{ color: "red" }}>
                        {venueFieldErr.capacity}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        {" "}
                        Time Zone 
                      </Form.Label>
                      <Form.Control
                        name="time_zone"
                        onChange={handleChange}
                        type="text"
                        value={venueField.time_zone}
                      ></Form.Control>
                      <span style={{ color: "red" }}>
                        {venueFieldErr.time_zone}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Location* </Form.Label>
                      <Form.Control
                        name="location"
                        onChange={handleChange}
                        type="text"
                        value={venueField.location}
                      ></Form.Control>
                      <span style={{ color: "red" }}>
                        {venueFieldErr.location}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Knownas</Form.Label>
                      <Form.Control
                        name="known_as"
                        onChange={handleChange}
                        type="text"
                        value={venueField.known_as}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Dimensions</Form.Label>
                      <Form.Control
                        name="dimensions"
                        onChange={handleChange}
                        type="text"
                        value={venueField.dimensions}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-2">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Ends</Form.Label>
                      <Form.Control
                        name="ends"
                        onChange={handleChange}
                        type="text"
                        value={venueField.ends}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Home To</Form.Label>
                      <Form.Control
                        name="home_to"
                        onChange={handleChange}
                        type="text"
                        value={venueField.home_to}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Other Sports</Form.Label>
                      <Form.Control
                        name="other_sports"
                        onChange={handleChange}
                        type="text"
                        value={venueField.other_sports}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Floodlights</Form.Label>
                      <Form.Control
                        name="floodlights"
                        onChange={handleChange}
                        type="text"
                        value={venueField.floodlights}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Curator</Form.Label>
                      <Form.Control
                        name="curator"
                        onChange={handleChange}
                        type="text"
                        value={venueField.curator}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Stats</Form.Label>
                      <Form.Select
                          aria-label="Default select example"
                          name="stats"
                          onChange={handleChange}
                          value={venueField.stats}
                        ><option value="">Please select stats</option>
                          <option value="Test">Test</option>
                          <option value="ODI">ODI</option>
                          <option value="T20">T20</option>
                          <option value="T10">T10</option>
                        
                        </Form.Select>
                        </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        name="venueimage"
                        onChange={handlechangeimage}
                      />
                      <span style={{ color: "red" }}>{imageErr}</span>
                      <img
                        src={imageUrl}
                        alt="venue"
                        className="img-fluid mt-2"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-8">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Description*</Form.Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={description}
                        config={{
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "blockQuote",
                          ],
                          heading: {
                            options: [
                              {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                              },
                              {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "ck-heading_heading1",
                              },
                              {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "ck-heading_heading2",
                              },
                              {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                              },
                            ],
                          },
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                          if (data) {
                            setDescriptionErr("");
                          }
                        }}
                      />
                      <span style={{ color: "red" }}>{descriptionErr}</span>
                    </Form.Group>
                  </div>
                </div>

                <Button variant="primary" type="submit">
                  Update
                </Button>
              </Form>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateVenue;
