import {
  getPointSeriesIdApi,
  insertPointApi,
  updatePointApi,
} from "../components/constant/Api";
import { myApi } from "./api";

export const pointApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getPointBySeries: builder.mutation({
      query: (post) => ({
        url: getPointSeriesIdApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["point"],
    }),
    setPoint: builder.mutation({
      query: (post) => ({
        url: insertPointApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["point"],
    }),
    updatePoint: builder.mutation({
      query: (post) => ({
        url: updatePointApi,
        method: "PUT",
        body: post,
      }),
    }),
  }),
});

export const {
  useSetPointMutation,
  useGetPointBySeriesMutation,
  useUpdatePointMutation,
} = pointApi;
