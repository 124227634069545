import React, { useState } from "react";
import { SeriesTypeEnum } from "../../constant/enum";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useSetUmpireMutation } from "../../../redux/umpireApi";
import { toast } from "react-toastify";

function AddUmpireModal(props) {
  const [setUmpire] = useSetUmpireMutation();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [dob, setDob] = useState("");
  const [totalMatches, setTotalMatches] = useState("0");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [format, setFormat] = useState([]);

  const handleClose = () => {
    setName("");
    setDob("");
    setTotalMatches("");
    setCountry("");
    setCity("");
    setState("");
    setFormat([]);
    props.setAddModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
      if (value === "") {
        setNameErr("This field is required");
      }
      setNameErr("");
    }
    if (name === "dob") {
      setDob(value);
    }
    if (name === "totalMatches") {
      setTotalMatches(value);
    }
    if (name === "country") {
      setCountry(value);
    }
    if (name === "city") {
      setCity(value);
    }
    if (name === "state") {
      setState(value);
    }
    if (name === "format") {
      if (value != "") {
        setFormat([...format, value]);
      }
    }
  };

  const onsubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setNameErr("This field is required");
      return false;
    }
    const data = {
      name: name,
      dob: dob,
      total_matches: totalMatches,
      country: country,
      city: city,
      state: state,
      format: JSON.stringify(format),
    };
    setUmpire(data).then((result) => {
        if (result.data.status) {
          toast.dismiss();
          toast.success(result.data.message);
          handleClose();
        } else {
          toast.dismiss();
          toast.error(result.data.message);
        }
    })
  };

  const updatedFormatList =
    format.length > 0
      ? SeriesTypeEnum.filter((type) => !format.includes(type.key))
      : SeriesTypeEnum;

  const removeFormat = (i) => {
    let newformatValues = [...format];
    newformatValues.splice(i, 1);
    setFormat(newformatValues);
  };

  return (
    <div>
      <Modal show={props.addModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Umpire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <Form>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        {" "}
                        Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        name="name"
                        onChange={handleChange}
                        type="text"
                        value={name}
                      ></Form.Control>
                      <span className="text-danger">{nameErr}</span>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-4 col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>DOB</Form.Label>
                      <Form.Control
                        name="dob"
                        onChange={handleChange}
                        type="date"
                        value={dob}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-4 col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> Country </Form.Label>
                      <Form.Control
                        name="country"
                        onChange={handleChange}
                        type="text"
                        value={country}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-4 col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label> State </Form.Label>
                      <Form.Control
                        name="state"
                        onChange={handleChange}
                        type="text"
                        value={state}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-4 col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>City </Form.Label>
                      <Form.Control
                        name="city"
                        onChange={handleChange}
                        type="text"
                        value={city}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-4 col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Total Matches </Form.Label>
                      <Form.Control
                        name="totalMatches"
                        onChange={handleChange}
                        type="text"
                        value={totalMatches}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-4 col-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Format</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="format"
                        onChange={handleChange}
                      >
                        <option value="">Please select Format</option>
                        {updatedFormatList?.map((list) => {
                          return <option value={list.value}>{list.key}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-8 col-12">
                    <div className="mt-2">
                      {format?.map((list, i) => {
                        return (
                          <span className="mul-team">
                            {list}{" "}
                            <span
                              class="mdi mdi-close ms-2 mul-icon"
                              onClick={() => {
                                removeFormat(i);
                              }}
                            ></span>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddUmpireModal;
