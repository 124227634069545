import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { useUpdateStatusMutation } from "../../../../redux/matchApi";
import {
  InningEnum,
  matchStatusEnum,
  TestInningEnum,
} from "../../../constant/enum";

function Status({ data, getMatchDetail }) {
  const [updateStatus] = useUpdateStatusMutation();
  const [status, setStatus] = useState("");
  const [totalOvers, setTotalOvers] = useState("10");
  const [battingTeam, setBattingTeam] = useState("");
  const [matchId, setMatchId] = useState("");
  const [inning, setInning] = useState("1");
  const [type, setType] = useState("");


  useEffect(() => {
    if (data) {
      let matchDetail = data;
      setStatus(matchDetail?.match_status);
      setTotalOvers(matchDetail?.total_overs ? matchDetail?.total_overs : "");
      setBattingTeam(
        matchDetail?.batting_team ? matchDetail?.batting_team : ""
      );
      setMatchId(matchDetail?.match_id);
      setInning(
        matchDetail?.current_inning ? matchDetail?.current_inning : "1"
      );
      setType(matchDetail?.match_type);
     
    }
  }, [data]);

  const handleBattingTeam = (e) => {
    const { name, value } = e.target;
    if (name === "battingTeam") {
      setBattingTeam(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "status") {
      setStatus(value);
    }
    if (name === "totalOvers") {
      setTotalOvers(value);
    }
    if (name == "inning") {
      setInning(value);
    }
 
  };

  const onSubmit = () => {
    const data = {
      match_status: status,
      total_overs: totalOvers,
      match_id: matchId,
      batting_team: battingTeam,
      current_inning: inning,
    };
    updateStatus(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        getMatchDetail();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div className="status-card">
      <div className="row">
        <div className="col-md-6 status-in ">
          <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1">
            <Form.Label>
              Status :
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="status"
              onChange={handleChange}
              value={status}
            >
              {matchStatusEnum?.map((list) => {
                return <option value={list.value}>{list.key}</option>;
              })}
            </Form.Select>
          </Form.Group>
        </div>
        <div className="col-md-6 ">
          <Form.Group className="mb-3 d-flex total-over" controlId="exampleForm.ControlInput1">
            <Form.Label> Total Overs : </Form.Label>
            <Form.Control
              name="totalOvers"
              onChange={handleChange}
              type="number"
              value={totalOvers}
              min="10"
              max="150"
            ></Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-6 status-in">
          <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlInput1">
          <Form.Label>
              Inning : 
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="inning"
              onChange={handleChange}
              value={inning}
            >
              {type !== "Test"
                ? InningEnum?.map((list) => {
                    return <option value={list.value}>{list.key}</option>;
                  })
                : TestInningEnum?.map((list) => {
                    return <option value={list.value}>{list.key}</option>;
                  })}
            </Form.Select>
          </Form.Group>
        </div>
        <div className="col-md-6">
          <div className="batting-check">
          <Form.Label>
          Batting Team : 
            </Form.Label>
            <div className="bt">
              <input
                type="checkbox"
                id={data?.team_a_id}
                name="battingTeam"
                onChange={handleBattingTeam}
                checked={data?.team_a_id == battingTeam}
                value={data?.team_a_id}
              />
              <label
                className="form-check-label mx-2"
                htmlFor={data?.team_a_id}
              >
                {data?.team_a_short}
              </label>
            </div>
            <div className="bt">
              <label
                className="form-check-label mx-2 "
                htmlFor={data?.team_b_id}
              >
                {data?.team_b_short}
              </label>
              <input
                type="checkbox"
                id={data?.team_b_id}
                name="battingTeam"
                onChange={handleBattingTeam}
                checked={data?.team_b_id == battingTeam}
                value={data?.team_b_id}
              />
            </div>
          </div>
        </div>
       
        
        <div className="col-md-6 mt-2">
          <button className="btn btn-primary" onClick={onSubmit}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

export default Status;
