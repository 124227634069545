import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUpdateYetToBatMutation } from "../../../../redux/matchApi";
import { CRWEnum, WicketEnum } from "../../../constant/enum";

function Batter(props) {
  const [updateYetToBat] = useUpdateYetToBatMutation();
  const [selectedBatter, setSelectedBatter] = useState([]);
  const [batter, setBatter] = useState([]);
  const [fallWicket, setfallWicket] = useState([]);
  const [score, setScore] = useState("0");
  const [wicket, setWicket] = useState("0");
  const [over, setOver] = useState("0");
  const [flag, setFlag] = useState("");
  const [shortName, setShortName] = useState("");
  const [name, setName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [currentInning, setCurrentInning] = useState("");
  // const [status, setStatus] = useState("");
  const [matchId, setMatchId] = useState("");
  // const [comment, setComment] = useState("");
  const [checked, setChecked] = useState("");
  const [lastWicket, setLastWicket] = useState({});
  const [yetToBat, setyetToBat] = useState([]);
  const [yetToBatStatus, setyetToBatStatus] = useState(false);
  const [filteredBattingPlayers, setFilteredBattingPlayers] = useState([]);
  const [playerInjure, setPlayerInjure] = useState({});

  useEffect(() => {
    if (props.teamScore) {
      let matchDetail = props.data;
      setScore(props.teamScore[matchDetail?.current_inning]?.score);
      setWicket(props.teamScore[matchDetail?.current_inning]?.wicket);
      setOver(props.teamScore[matchDetail?.current_inning]?.over);
    }
  }, [props.teamScore]);

  useEffect(() => {
    if (props.scoreCard?.batsman) {
      setSelectedBatter(JSON.parse(props.scoreCard?.batsman));
      setfallWicket(
        props.scoreCard?.fallwicket
          ? JSON.parse(props.scoreCard?.fallwicket)
          : []
      );
      let teamEx = JSON.parse(props.scoreCard?.team);
      props.setExtraRun(teamEx.extra_run);
    } else {
      setSelectedBatter([]);
    }
  }, [props.scoreCard]);

  useEffect(() => {
    if (props.data) {
      let matchDetail = props.data;
      let jsonBatsman = matchDetail?.batsman
        ? JSON.parse(matchDetail?.batsman)
        : [];
      setMatchId(matchDetail?.match_id);
      setyetToBat(
        matchDetail?.yet_to_bat ? JSON.parse(matchDetail?.yet_to_bat) : []
      );
      // setStatus(matchDetail?.match_status);
      setTeamId(matchDetail?.batting_team);
      setBatter(jsonBatsman);
      setChecked(jsonBatsman.length > 0 ? jsonBatsman[0].player_id : "");
      setLastWicket(
        matchDetail?.lastwicket ? JSON.parse(matchDetail?.lastwicket) : {}
      );
      setPlayerInjure(
        matchDetail?.player_injured
          ? JSON.parse(matchDetail?.player_injured)
          : {}
      );
      setCurrentInning(matchDetail?.current_inning);
      if (matchDetail?.batting_team == matchDetail?.team_a_id) {
        if (matchDetail?.team_a_score) {
          let jsonScore = JSON.parse(matchDetail?.team_a_score);
          setScore(
            jsonScore[matchDetail?.current_inning]?.score
              ? jsonScore[matchDetail?.current_inning]?.score
              : 0
          );
          setWicket(
            jsonScore[matchDetail?.current_inning]?.wicket
              ? jsonScore[matchDetail?.current_inning]?.wicket
              : 0
          );
          setOver(
            jsonScore[matchDetail?.current_inning]?.over
              ? jsonScore[matchDetail?.current_inning]?.over
              : 0
          );
          setName(matchDetail?.team_a_name);
          setFlag(matchDetail?.team_a_img);
          setShortName(matchDetail?.team_a_short);
        }
      } else {
        if (matchDetail?.team_b_score) {
          let jsonScore = JSON.parse(matchDetail?.team_b_score);
          setScore(
            jsonScore[matchDetail?.current_inning]?.score
              ? jsonScore[matchDetail?.current_inning]?.score
              : 0
          );
          setWicket(
            jsonScore[matchDetail?.current_inning]?.wicket
              ? jsonScore[matchDetail?.current_inning]?.wicket
              : 0
          );
          setOver(
            jsonScore[matchDetail?.current_inning]?.over
              ? jsonScore[matchDetail?.current_inning]?.over
              : 0
          );
          setName(matchDetail?.team_b_name);
          setFlag(matchDetail?.team_b_img);
          setShortName(matchDetail?.team_b_short);
        }
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (batter) {
      let prun = 0;
      let pball = 0;
      for (let i = 0; i < batter.length; i++) {
        prun = prun + parseInt(batter[i].run);
        pball = pball + parseInt(batter[i].ball);
      }
      const data = {
        batter: JSON.stringify(batter),
        player_injured: JSON.stringify(playerInjure),
        partnership: JSON.stringify({ run: prun, ball: pball }),
        match_id: matchId,
      };
      props.setBatter(batter)
      const eventType = "update-batter";
      props.sendData(eventType, data);
    }
  }, [batter, playerInjure]);

  useEffect(() => {
    if (selectedBatter.length > 0) {
      updateScoreCard();
    }
  }, [selectedBatter, props.extraRun, fallWicket]);

  const updateScoreCard = () => {
    let matchDetail = props.data;
    const data = {
      team: JSON.stringify({
        team_id: teamId,
        name: name,
        flag: flag,
        score: props.teamScore[matchDetail?.current_inning]?.score
          ? props.teamScore[matchDetail?.current_inning]?.score
          : score,
        wicket: props.teamScore[matchDetail?.current_inning]?.wicket
          ? props.teamScore[matchDetail?.current_inning]?.wicket
          : wicket,
        over: props.teamScore[matchDetail?.current_inning]?.over
          ? props.teamScore[matchDetail?.current_inning]?.over
          : over,
        short_name: shortName,
        extra_run: props.extraRun,
      }),
      inning: currentInning,
      match_id: matchId,
      batter: JSON.stringify(selectedBatter),
      fallwicket: JSON.stringify(fallWicket),
      lastwicket: JSON.stringify(lastWicket),
      team_id: teamId,
    };
    const eventType = "update-score-card-batter";
    props.sendData(eventType, data);
  };

  const handleExtraRun = (e) => {
    const { name, value } = e.target;
    props.setExtraRun({ ...props.extraRun, [name]: value });
  };

  const handlecheck = (id) => {
    let filterBatter = props.battingPlayer?.filter((list) => {
      return list.player_id == id;
    });
    let pushData = {
      player_id: filterBatter[0].player_id,
      player_name: filterBatter[0].player_name,
      match_role: filterBatter[0].match_role,
      run: 0,
      ball: 0,
      fours: 0,
      sixes: 0,
      out_by: "Not out",
      strike_rate: "0",
    };
    setSelectedBatter([...selectedBatter, pushData]);
    setBatter([...batter, pushData]);
    setyetToBatStatus(!yetToBatStatus);
  };

  useEffect(() => {
    let filteredBattingPlayers = props.battingPlayer?.filter(
      (battingPlayerObj) =>
        !selectedBatter.some(
          (playerObj) => playerObj.player_id === battingPlayerObj.player_id
        )
    );
    let playerNames = filteredBattingPlayers.map(
      (player) => player.player_name
    );
    setyetToBat(playerNames);
    const data = {
      yet_to_bat: JSON.stringify(playerNames),
      match_id: matchId,
    };
    updateYetToBat(data).then(() => {
      props.getMatchDetail();
    });
  }, [yetToBatStatus]);

  useEffect(() => {
    let filteredBattingPlayer = props.battingPlayer?.filter(
      (battingPlayerObj) =>
        !selectedBatter.some(
          (playerObj) => playerObj.player_id === battingPlayerObj.player_id
        )
    );
    setFilteredBattingPlayers(filteredBattingPlayer);
  }, [selectedBatter, props]);

  // const handleComment = (e) => {
  //   const { name, value } = e.target;
  //   setComment(value);
  // };

  // const outBy = (id, index, e) => {
  //   e.preventDefault();
  //   let id=batter[0].player_id
  //   let filterBatter = batter.filter((list) => {
  //     return list.player_id != id;
  //   });
  //   setBatter(filterBatter);
  //   const newComment = comment;
  //   const updatedSelectedBatter = [...selectedBatter];
  //   updatedSelectedBatter[index] = {
  //     ...selectedBatter[index],
  //     out_by: newComment,
  //   };
  //   setSelectedBatter(updatedSelectedBatter);
  //   setComment("");
  //   let matchDetail = props.data;
  //   let falwicket = {
  //     player_id: updatedSelectedBatter[index].player_id,
  //     player_name: updatedSelectedBatter[index].player_name,
  //     score: props.teamScore[matchDetail?.current_inning]?.score
  //       ? props.teamScore[matchDetail?.current_inning]?.score
  //       : score,
  //     wicket: props.teamScore[matchDetail?.current_inning]?.wicket
  //       ? props.teamScore[matchDetail?.current_inning]?.wicket
  //       : wicket,
  //     over: props.teamScore[matchDetail?.current_inning]?.over
  //       ? props.teamScore[matchDetail?.current_inning]?.over
  //       : over,
  //   };
  //   setfallWicket([...fallWicket, falwicket]);
  //   setLastWicket({
  //     player_id: updatedSelectedBatter[index].player_id,
  //     player_name: updatedSelectedBatter[index].player_name,
  //     run: updatedSelectedBatter[index].run,
  //     ball: updatedSelectedBatter[index].ball,
  //   });
  //   setChecked("");
  // };

  useEffect(() => {
    if (WicketEnum.includes(props.firstCircle)) {
      outBy();
    }
  }, [props.firstCircle]);

  useEffect(() => {
    if (CRWEnum.includes(props.firstCircle)) {
      if (props.wicketBowler.length > 0) {
        outBy();
      }
    }
  }, [props.wicketBowler]);

  const outBy = () => {
    let id = batter[0]?.player_id;
    const index = selectedBatter.findIndex((obj) => obj.player_id === id);
    let filterBatter = batter.filter((list) => {
      return list.player_id != id;
    });
    setBatter(filterBatter);
    let newComment;
    if (WicketEnum.includes(props.firstCircle)) {
      newComment = "b " + props.bowler?.player_name;
    } else if (props.firstCircle === CRWEnum[0]) {
      newComment =
        "b " +
        props.bowler?.player_name +
        " c " +
        props.wicketBowler[0].player_name;
    } else if (props.firstCircle === CRWEnum[1]) {
      newComment =
        "runout ( " +
        props.wicketBowler[0].player_name +
        " / " +
        props.wicketBowler[1].player_name +
        " )";
    }
    const updatedSelectedBatter = [...selectedBatter];
    updatedSelectedBatter[index] = {
      ...selectedBatter[index],
      out_by: newComment,
    };
    setSelectedBatter(updatedSelectedBatter);
    
    let matchDetail = props.data;
    let falwicket = {
      player_id: updatedSelectedBatter[index].player_id,
      player_name: updatedSelectedBatter[index].player_name,
      score: props.teamScore[matchDetail?.current_inning]?.score
        ? props.teamScore[matchDetail?.current_inning]?.score
        : score,
      wicket: parseInt(props.teamScore[matchDetail?.current_inning]?.wicket)+1
        ? parseInt(props.teamScore[matchDetail?.current_inning]?.wicket)+1
        : parseInt(wicket)+1,
      over: props.teamScore[matchDetail?.current_inning]?.over
        ? props.teamScore[matchDetail?.current_inning]?.over
        : over,
    };
    setfallWicket([...fallWicket, falwicket]);
    setLastWicket({
      player_id: updatedSelectedBatter[index].player_id,
      player_name: updatedSelectedBatter[index].player_name,
      run: updatedSelectedBatter[index].run,
      ball: updatedSelectedBatter[index].ball,
    });
    setChecked("");
    props.setWicketBowler([]);
  };

  const handleChange = (i, e) => {
    const { name, value } = e.target;
    let newSelectedBatter = [...selectedBatter];
    let newBatter = [...batter];
    // const selectIndex=selectedBatter.findIndex(obj => obj.player_id === battingPlayer[i]?.player_id)
    const indexs = batter.findIndex(
      (obj) => obj.player_id === selectedBatter[i].player_id
    );
    newSelectedBatter[i][name] = value;
    if (indexs >= 0) {
      newBatter[indexs][name] = value;
    } else {
      setLastWicket({ ...lastWicket, [name]: value });
    }
    if (name == "run") {
      newSelectedBatter[i][name] = value;
      if (indexs >= 0) {
        newBatter[indexs][name] = value;
      }
      let striketRate =
        (parseFloat(value) /
          parseFloat(
            selectedBatter[i].ball == 0 ? 1 : selectedBatter[i].ball
          )) *
        100;
      newSelectedBatter[i]["strike_rate"] = striketRate.toFixed(2);
      if (indexs >= 0) {
        newBatter[indexs]["strike_rate"] = striketRate.toFixed(2);
      }
    }
    if (name === "ball") {
      newSelectedBatter[i][name] = value;
      if (indexs > 0) {
        newBatter[indexs][name] = value;
      } else {
        setLastWicket({ ...lastWicket, [name]: value });
      }
      let striketRate =
        (parseFloat(selectedBatter[i].run) / parseFloat(value)) * 100;
      newSelectedBatter[i]["strike_rate"] = striketRate.toFixed(2);
      // newBatter[indexs]["strike_rate"] = striketRate.toFixed(2);
      if (indexs >= 0) {
        newBatter[indexs]["strike_rate"] = striketRate.toFixed(2);
      }
    }
    setSelectedBatter(newSelectedBatter);
    setBatter(newBatter);
  };

  const handleStrike = (id) => {
    setChecked(id);
    if (batter.length == 2) {
      let firstPlayer = batter.filter((list) => {
        return list.player_id == id;
      });
      let secondPlayer = batter.filter((list) => {
        return list.player_id != id;
      });
      let arr = [firstPlayer[0], secondPlayer[0]];
      setBatter(arr);
    } else {
      let Player = selectedBatter.filter((list) => {
        return list.player_id == id;
      });

      setBatter([...batter, Player[0]]);
    }
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    const sourceIndex = parseInt(e.dataTransfer.getData("index"));
    const updatedItems = [...selectedBatter];
    const [draggedItem] = updatedItems.splice(sourceIndex, 1);
    updatedItems.splice(targetIndex, 0, draggedItem);
    setSelectedBatter(updatedItems);
  };

  const playerInjured = (i, e) => {
    setChecked("");
    const player = selectedBatter[i];
    let filterBatter = batter.filter((list) => {
      return list.player_id != player.player_id;
    });
    setBatter(filterBatter);
    setPlayerInjure(player);
  };

  const removePlayer=(id)=>{
      const selectFilter=selectedBatter.filter((list)=>{return list.player_id!=id})
      const filterBatter=batter.filter((list)=>{return list.player_id!=id})
      const getBatter=batter.filter((list)=>{return list.player_id==id})
      setSelectedBatter(selectFilter)
      setBatter(filterBatter)
      setyetToBat([...yetToBat,getBatter[0].player_name]);
  }

  return (
    <div className="full-detail bating-detail">
      <div className="match-topbar bating-heading">
        <p>
          <img src="/assets/images/batsmen.png" alt="" className="img-fluid" />{" "}
          Batsmen
        </p>
        <div className="extra-runs">
          <ul className="nav align-items-center">
            <li>Ex </li>
            <li>
              w
              <input
                type="number"
                name="w"
                value={props.extraRun?.w}
                placeholder=""
                min="0"
                className="form-control"
                onChange={handleExtraRun}
              />
            </li>
            <li>
              nb
              <input
                type="number"
                name="nb"
                value={props.extraRun?.nb}
                placeholder=""
                min="0"
                className="form-control"
                onChange={handleExtraRun}
              />
            </li>
            <li>
              lb
              <input
                type="number"
                name="lb"
                value={props.extraRun?.lb}
                placeholder=""
                min="0"
                className="form-control"
                onChange={handleExtraRun}
              />
            </li>
            <li>
              b
              <input
                type="number"
                name="b"
                value={props.extraRun?.b}
                placeholder=""
                min="0"
                className="form-control"
                onChange={handleExtraRun}
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="scoreboard-table bowler-table table-responsive">
        <table className="table table-striped align-middle">
          <thead>
            <tr>
              <th>ST</th>
              <th>Batsmen</th>
              <th>Runs</th>
              <th>Balls</th>
              <th>4s</th>
              <th>6s</th>
              <th>SR</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedBatter?.map((list, index) => {
              let filterbatter = batter?.filter((el) => {
                return el.player_id == list.player_id;
              });

              return (
                <tr
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                  key={list.player_id}
                >
                  <td>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={list.player_id == checked}
                      onChange={() => {
                        handleStrike(list.player_id);
                      }}
                      disabled={
                        playerInjure?.player_id == list.player_id
                          ? false
                          : filterbatter.length == 0
                          ? true
                          : batter.length > 0
                          ? false
                          : true
                      }
                    />
                  </td>
                  <td className="out">
                    <span className="last-wicket">
                      {list.player_id == lastWicket?.player_id
                        ? "Last wicket"
                        : ""}
                    </span>
                    <span>
                      {list.player_name}{" "}
                      {list.player_id == checked ? (
                        <i class="mdi mdi-cricket"></i>
                      ) : (
                        ""
                      )}{" "}
                    </span>
                    {filterbatter.length > 0 ? (
                      <span className="batting-player">Batting</span>
                    ) : (
                      <span className="out-by-player">{list.out_by}</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="run"
                      className="form-control"
                      value={list.run}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      // disabled={list.player_id != checked}
                      min="0"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="ball"
                      className="form-control"
                      value={list.ball}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      // disabled={list.player_id != checked}
                      min="0"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="fours"
                      className="form-control"
                      value={list.fours}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      // disabled={list.player_id != checked}
                      min="0"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="sixes"
                      className="form-control"
                      value={list.sixes}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      // disabled={list.player_id != checked}
                      min="0"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="strike_rate"
                      className="form-control"
                      value={list.strike_rate}
                      min="0"
                      disabled
                    />
                  </td>
                  <td>
                    <ul className="nav">
                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="/assets/images/vertical-dots.png"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <Link
                              to={`/update-player/${list.player_id}`}
                              className="dropdown-item"
                            >
                              Edit
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/player-news/${list.player_id}`}
                              className="dropdown-item"
                            >
                              Add News
                            </Link>
                          </li>
                          {/* <li>
                            <textarea
                              placeholder="Comment"
                              className="form-control"
                              onChange={handleComment}
                              value={comment}
                            ></textarea>
                          </li>*/}
                          <li>
                            <button
                              class="dropdown-item"
                              onClick={(e) => {
                                playerInjured(index, e);
                              }}
                            >
                              Player Injured
                            </button>
                          </li>
                          
                          <li>
                            <button
                              class="dropdown-item"
                              onClick={() => {
                                removePlayer(list.player_id);
                              }}
                            >
                             Remove Player
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              );
            })}
            {filteredBattingPlayers?.map((list) => {
              return (
                <tr key={list.player_id} className="no-bg">
                  <td>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      onClick={() => {
                        handlecheck(list.player_id);
                      }}
                    />
                  </td>
                  <td className="out">
                    {list.player_name}{" "}
                    <span className="yet-to-bat">
                      {yetToBat.includes(list.player_name) ? "Yet to bat" : ""}
                    </span>
                  </td>

                  <td colSpan={5}></td>

                  <td>
                    <ul className="nav">
                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="/assets/images/vertical-dots.png"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <Link
                              to={`/update-player/${list.player_id}`}
                              className="dropdown-item"
                            >
                              Edit
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/player-news/${list.player_id}`}
                              className="dropdown-item"
                            >
                              Add News
                            </Link>
                          </li>
                          <li>
                            <button
                              class="dropdown-item"
                              onClick={() => {
                                handlecheck(list.player_id);
                              }}
                            >
                              Batter
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Batter;
