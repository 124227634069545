import React, { useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { Link } from "react-router-dom";
import { useGetPlayerQuery } from "../../../redux/playerApi";

function Player() {
  const { data, refetch } = useGetPlayerQuery({});
  useEffect(() => {
    refetch();
  }, []);

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "first_name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "player_role",
      text: "Role",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "born",
      text: "Born",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "birth_place",
      text: "Birth Place",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <img
            src={record.image}
            alt="team-flag"
            className="img-fluid img-size"
          />
        );
      },
    },
    {
      key: "news",
      text: "News",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link
              to={`/player-news/${record.player_id}`}
              className="btn btn-primary"
            >
              News List
            </Link>
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link
              to={`/update-player/${record.player_id}`}
              className="btn btn-primary"
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="section-heading">
            <h2>
              <b>Player List</b>
            </h2>
          </div>
          <div>
            <div className="product-list-outer all-matches">
              <div className="buttonDirection">
                <Link to="/add-player" className="btn btn-primary">
                  Add Player
                </Link>
              </div>
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Player;
