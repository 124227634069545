import { insertOddHistoryApi } from "../components/constant/Api";
import { myApi } from "./api";

export const oddhistoryApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setOddHistory: builder.mutation({
      query: (post) => ({
        url: insertOddHistoryApi,
        method: "POST",
        body: post,
      }),
    }),
  }),
});

export const { useSetOddHistoryMutation } = oddhistoryApi;
