import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useUpdateTossMutation } from "../../../../redux/matchApi";

function Toss({ data, getMatchDetail,sendData }) {
  const [updateToss] = useUpdateTossMutation();
  const [winTossTeam, setWinTossTeam] = useState("");
  const [toss, setToss] = useState("");
  const [tossErr, setTossErr] = useState("");
  const [matchId, setMatchId] = useState("");

  useEffect(() => {
    if (data) {
      let matchDetail = data;
      setWinTossTeam(matchDetail?.win_toss_team);
      setToss(matchDetail?.toss);
      setMatchId(matchDetail?.match_id);
    }
  }, [data]);

  const handleToss = (e) => {
    const { name, value } = e.target;
    if (name === "winTossTeam") {
      setWinTossTeam(value);
      setToss("");
    }
    if (name === "toss") {
      setToss(value);
      setTossErr("");
    }
  };

  const updateMatchToss = () => {
    if (toss === "" || !toss) {
      setTossErr("Please select the toss");
      return false;
    }
    let data = {
      toss: toss,
      win_toss_team: winTossTeam,
      match_id: matchId,
    };
    updateToss(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        const datas={
          match_id: matchId,
          first_circle: toss,
        }
        const eventType = "update-first-circle";
        sendData(eventType,datas)
        getMatchDetail();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div className="toss-card text-center">
      <h3 className="text-center">Toss</h3>
      <div className="toss-check">
        <label className="toss-label">Toss Win: </label>
        <div>
          <input
            type="checkbox"
            id={data?.team_a_id}
            name="winTossTeam"
            onChange={handleToss}
            checked={data?.team_a_id == winTossTeam}
            disabled={data?.win_toss_team}
            value={data?.team_a_id}
          />
          <label className="form-check-label mx-2" htmlFor={data?.team_a_id}>
            {data?.team_a_name}
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id={data?.team_b_id}
            name="winTossTeam"
            onChange={handleToss}
            checked={data?.team_b_id == winTossTeam}
            disabled={data?.win_toss_team}
            value={data?.team_b_id}
          />
          <label className="form-check-label mx-2 " htmlFor={data?.team_b_id}>
            {data?.team_b_name}
          </label>
        </div>
      </div>
      <div className="toss-select text-start">
        <label className="">Toss : </label>
        <select
          disabled={data?.toss}
          value={toss}
          onChange={handleToss}
          name="toss"
          className="form-select"
        >
          <option value="">Select toss</option>
          {winTossTeam &&
            (data?.team_a_id == winTossTeam ? (
              <>
                <option
                  value={`${data?.team_a_name} won the toss and opt to bat`}
                >
                  {data?.team_a_name} won the toss and opt to bat
                </option>
                <option
                  value={`${data?.team_a_name} won the toss and opt to field`}
                >
                  {data?.team_a_name} won the toss and opt to field
                </option>
              </>
            ) : (
              <>
                <option
                  value={`${data?.team_b_name} won the toss and opt to bat`}
                >
                  {data?.team_b_name} won the toss and opt to bat
                </option>
                <option
                  value={`${data?.team_b_name} won the toss and opt to field`}
                >
                  {data?.team_b_name} won the toss and opt to field
                </option>
              </>
            ))}
        </select>
        <span style={{ color: "red" }}>{tossErr}</span>
      </div>
      <div>
        <button
          disabled={data?.toss}
          className="btn btn-primary"
          onClick={() => {
            updateMatchToss();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default Toss;
