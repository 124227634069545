import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BowlingPlayerModal from "../../../partial/selectPlayer/BowlingPlayerModal";

function Bowler(props) {
  const [selectedBowler, setSelectedBowler] = useState([]);
  const [lastBowler, setLastBowler] = useState({});
  const [bowler, setBowler] = useState({});
  const [matchId, setMatchId] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [score, setScore] = useState("0");
  const [wicket, setWicket] = useState("0");
  const [over, setOver] = useState("0");
  const [flag, setFlag] = useState("");
  const [shortName, setShortName] = useState("");
  const [name, setName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [currentInning, setCurrentInning] = useState("");
  const [status, setStatus] = useState("");
  const [filteredBowlingPlayers, setFilteredBowlingPlayers] = useState([]);

  useEffect(() => {
    if (props.teamScore) {
      let matchDetail = props.data;
      setScore(props.teamScore[matchDetail?.current_inning]?.score);
      setWicket(props.teamScore[matchDetail?.current_inning]?.wicket);
      setOver(props.teamScore[matchDetail?.current_inning]?.over);
    }
  }, [props.teamScore]);

  useEffect(() => {
    if (props.data) {
      let matchDetail = props.data;
      setMatchId(matchDetail?.match_id);
      setBowler(matchDetail?.bowler ? JSON.parse(matchDetail?.bowler) : {});
      props.setBowler(
        matchDetail?.bowler ? JSON.parse(matchDetail?.bowler) : {}
      );
      setLastBowler(
        matchDetail?.last_bowler ? JSON.parse(matchDetail?.last_bowler) : {}
      );
      setStatus(matchDetail?.match_status);
      setTeamId(matchDetail?.batting_team);
      setCurrentInning(matchDetail?.current_inning);
      if (matchDetail?.batting_team == matchDetail?.team_a_id) {
        if (matchDetail?.team_a_score) {
          let jsonScore = JSON.parse(matchDetail?.team_a_score);
          setScore(
            jsonScore[matchDetail?.current_inning]?.score
              ? jsonScore[matchDetail?.current_inning]?.score
              : 0
          );
          setWicket(
            jsonScore[matchDetail?.current_inning]?.wicket
              ? jsonScore[matchDetail?.current_inning]?.wicket
              : 0
          );
          setOver(
            jsonScore[matchDetail?.current_inning]?.over
              ? jsonScore[matchDetail?.current_inning]?.over
              : 0
          );
          setName(matchDetail?.team_a_name);
          setFlag(matchDetail?.team_a_img);
          setShortName(matchDetail?.team_a_short);
        }
      } else {
        if (matchDetail?.team_b_score) {
          let jsonScore = JSON.parse(matchDetail?.team_b_score);
          setScore(
            jsonScore[matchDetail?.current_inning]?.score
              ? jsonScore[matchDetail?.current_inning]?.score
              : 0
          );
          setWicket(
            jsonScore[matchDetail?.current_inning]?.wicket
              ? jsonScore[matchDetail?.current_inning]?.wicket
              : 0
          );
          setOver(
            jsonScore[matchDetail?.current_inning]?.over
              ? jsonScore[matchDetail?.current_inning]?.over
              : 0
          );
          setName(matchDetail?.team_b_name);
          setFlag(matchDetail?.team_b_img);
          setShortName(matchDetail?.team_b_short);
        }
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (props.scoreCard?.bowler) {
      setSelectedBowler(JSON.parse(props.scoreCard?.bowler));
      let teamEx = JSON.parse(props.scoreCard?.team);
      props.setExtraRun(teamEx?.extra_run);
    } else {
      setSelectedBowler([]);
    }
  }, [props.scoreCard]);

  useEffect(() => {
    if (bowler) {
      const data = {
        bowler: JSON.stringify(bowler),
        match_id: matchId,
      };
      const eventType = "update-bowler";
      props.sendData(eventType, data);
    }
  }, [bowler]);

  useEffect(() => {
    if (lastBowler) {
      const data = {
        last_bowler: JSON.stringify(lastBowler),
        match_id: matchId,
      };
      const eventType = "update-last-bowler";
      props.sendData(eventType, data);
    }
  }, [lastBowler]);

  const handlechecked = (id) => {
    let filterBowler = props.bowlingPlayer?.filter((list) => {
      return list.player_id == id;
    });
    let pushData = {
      player_id: filterBowler[0].player_id,
      player_name: filterBowler[0].player_name,
      match_role: filterBowler[0].match_role,
      O: 0,
      M: 0,
      R: 0,
      W: 0,
      ECO: 0,
    };
    setSelectedBowler([...selectedBowler, pushData]);
  };
  useEffect(() => {
    let filteredBowlingPlayer = props.bowlingPlayer?.filter(
      (bowlingPlayerObj) =>
        !selectedBowler?.some(
          (playerObj) => playerObj.player_id === bowlingPlayerObj.player_id
        )
    );
    setFilteredBowlingPlayers(filteredBowlingPlayer);
  }, [selectedBowler, props]);

  const covertBalls = (over) => {
    let balls=(Math.floor(over) * 6 + (parseFloat(over) % 1) * 10)
    return balls
  };


  const handleLastBowler = (id) => {
    let filterPlayer = selectedBowler?.filter((list) => {
      return list.player_id == id;
    });
    setLastBowler(filterPlayer[0]);
    if (id == bowler.player_id) {
      setBowler({});
      props.setBowler({});
    }
  };

  const handleBowler = (id) => {
    setLastBowler(bowler);
    let filterPlayer = selectedBowler?.filter((list) => {
      return list.player_id == id;
    });
    setBowler(filterPlayer[0]);
    props.setBowler(filterPlayer[0]);
  };

  const handleChange = (i, e) => {
    const { name, value } = e.target;

    let newSelectedBowler = [...selectedBowler];
    newSelectedBowler[i][name] = value;
    setBowler({ ...bowler, [name]: value });
    if (name == "R") {
      let economy =
        (parseFloat(value) /
        covertBalls(parseFloat(selectedBowler[i]["O"] == 0 ? 1 : selectedBowler[i].O)))*6;
      console.log(covertBalls(parseFloat(selectedBowler[i]["O"] == 0 ? 1 : selectedBowler[i].O)),"economy")
      newSelectedBowler[i]["ECO"] = economy.toFixed(2);
      setBowler({ ...bowler, [name]: value, ["ECO"]: economy.toFixed(2) });
      props.setBowler({
        ...bowler,
        [name]: value,
        ["ECO"]: economy.toFixed(2),
      });
    }
    if (name === "O") {
      let economy = (parseFloat(selectedBowler[i]["R"]) / covertBalls(parseFloat(value)))*6;
      newSelectedBowler[i]["ECO"] = economy.toFixed(2);
      setBowler({ ...bowler, [name]: value, ["ECO"]: economy.toFixed(2) });
      props.setBowler({
        ...bowler,
        [name]: value,
        ["ECO"]: economy.toFixed(2),
      });
    }
    
    props.setBowler({ ...bowler, [name]: value });
    setSelectedBowler(newSelectedBowler);
  };

  useEffect(() => {
    if (selectedBowler.length > 0) {
      updateScoreCard();
    }
  }, [selectedBowler]);

  const updateScoreCard = () => {
    const data = {
      team: JSON.stringify({
        team_id: teamId,
        name: name,
        flag: flag,
        score: score,
        wicket: wicket,
        over: over,
        short_name: shortName,
        extra_run: props.extraRun,
      }),
      inning: currentInning,
      match_id: matchId,
      bowler: JSON.stringify(selectedBowler),
      team_id: teamId,
    };
    const eventType = "update-score-card-bowler";
    props.sendData(eventType, data);
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    const sourceIndex = parseInt(e.dataTransfer.getData("index"));
    const updatedItems = [...selectedBowler];
    const [draggedItem] = updatedItems.splice(sourceIndex, 1);
    updatedItems.splice(targetIndex, 0, draggedItem);
    setSelectedBowler(updatedItems);
  };

  const handleRemoveBowler=(id)=>{
    const selectFilter=selectedBowler.filter((list)=>{return list.player_id!=id})
    setSelectedBowler(selectFilter);
    if(id==bowler.player_id){
      setBowler({})
    }
  }

  return (
    <div className="full-detail bowling-detail">
      <div className="match-topbar bowl-heading">
        <p>
          <img
            src="/assets/images/bowler-icon.png"
            alt=""
            className="img-fluid"
          />{" "}
          Bowlers
        </p>
        <div className="select-bowler">
          <button
            className="btn btn-primary"
            onClick={() => {
              setAddModal(true);
            }}
            disabled={status === "Finished" ? true : false}
          >
            Select Bowler
          </button>
        </div>
      </div>
      <div className="scoreboard-table bowler-table table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>ST</th>
              <th>Bowler</th>
              <th>O</th>
              <th>M</th>
              <th>R</th>
              <th>W</th>
              <th>ER</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedBowler?.map((list, index) => {
              return (
                <tr
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                  key={list.player_id}
                >
                  <td>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={list.player_id == bowler?.player_id}
                      onChange={() => {
                        handleBowler(list.player_id);
                      }}
                      disabled={list.player_id == lastBowler?.player_id}
                    />
                  </td>
                  <td>
                    {list.player_name}
                    <span style={{ color: "red" }}>
                      {list.player_id == lastBowler.player_id
                        ? "(Last Bowler)"
                        : ""}
                    </span>
                    <span style={{ color: "green" }}>
                      {list.player_id == bowler.player_id ? "(Bowler)" : ""}
                    </span>
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="O"
                      className="form-control"
                      value={list.O}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      step="0.1"
                      min="0"
                      disabled={list.player_id != bowler.player_id}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="M"
                      value={list.M}
                      className="form-control"
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      min="0"
                      disabled={list.player_id != bowler.player_id}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="R"
                      value={list.R}
                      className="form-control"
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      min="0"
                      disabled={list.player_id != bowler.player_id}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="W"
                      value={list.W}
                      className="form-control"
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      min="0"
                      disabled={list.player_id != bowler.player_id}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder=""
                      name="ECO"
                      value={list?.ECO}
                      className="form-control"
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      min="0"
                      disabled={list.player_id != bowler.player_id}
                    />
                  </td>
                  <td>
                    <ul className="nav">
                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="/assets/images/vertical-dots.png"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <Link
                              to={`/update-player/${list.player_id}`}
                              className="dropdown-item"
                            >
                              Edit
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/player-news/${list.player_id}`}
                              className="dropdown-item"
                            >
                              Add News
                            </Link>
                          </li>
                          <li>
                            <button
                              class="dropdown-item"
                              onClick={() => {
                                handleLastBowler(list.player_id);
                              }}
                              style={{
                                background:
                                  list.player_id == lastBowler.player_id
                                    ? "green"
                                    : "",
                              }}
                            >
                              LastBowler
                            </button>
                          </li>
                          <li>
                            <button
                              class="dropdown-item"
                              onClick={() => {
                                handleRemoveBowler(list.player_id);
                              }}
                            >
                              Remove
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              );
            })}
            {filteredBowlingPlayers?.map((list) => {
              return (
                <tr className="no-bg" key={list.player_id}>
                  <td>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={list.player_id == bowler?.player_id}
                      onChange={() => {
                        handlechecked(list.player_id);
                      }}
                    />
                  </td>
                  <td>{list.player_name}</td>
                  <td colSpan={5}></td>
                  <td>
                    <ul className="nav">
                      <li class="nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="/assets/images/vertical-dots.png"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <Link
                              to={`/update-player/${list.player_id}`}
                              className="dropdown-item"
                            >
                              Edit
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/player-news/${list.player_id}`}
                              className="dropdown-item"
                            >
                              Add News
                            </Link>
                          </li>
                          <li>
                            <button
                              class="dropdown-item"
                              onClick={() => {
                                handlechecked(list.player_id);
                              }}
                            >
                              Bowling
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <BowlingPlayerModal
          bowlingPlayer={props.allBowlingSquad}
          setWicketBowler={props.setWicketBowler}
          // handlechecked={handlechecked}
          addModal={addModal}
          setAddModal={setAddModal}
        />
      </div>
    </div>
  );
}

export default Bowler;
