import React,{useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'

function Info({ seriesData }) {
  const location=useLocation()
  useEffect(() => {
    if(location){
      localStorage.setItem("path", location.pathname);
    }
  }, [location])
  return (
    <div className='series-info-main'>
      <div className='card'>
        <div className='row'>
          <div className='col-md-3 col-12'>
            <div className='img-outer'>
              <img src={seriesData?.image} alt='' className='img-fluid' />
            </div>
          </div>
          <div className='col-md-9 col-12'>
            <div className='card series-data'>
              <ul className='nav'>
                <li>Series : <b>{seriesData?.series}</b></li>
                <li>Series Date : <b>{seriesData?.series_date}</b></li>
                <li>Series Start Date : <b>{seriesData?.start_date}</b></li>
                <li>Series End Date : <b>{seriesData?.end_date}</b></li>
                <li>Series Type : <b>{seriesData?.series_type}</b></li>
                <li>Series Short Name : <b>{seriesData?.short_name}</b></li>
                <li>Series Status : <b>{seriesData?.series_status}</b></li>
                <li>Series Format : <b>{seriesData?.format}</b></li>
                <li>Series Gender : <b>{seriesData?.gender}</b></li>
                <li>Month Wise : <b>{seriesData?.month_wise}</b></li>
                <li>Total Matches : <b>{seriesData?.total_matches}</b></li>
              </ul>
              <div className='button-update'>
                <Link to={`/update-series/${seriesData?.series_id}`} className='btn btn-primary mb-3' >Update</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info
