import React from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetVenueQuery } from "../../../redux/venueApi";
import { Link } from "react-router-dom";

function Venue() {
  const { data } = useGetVenueQuery({});
  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "venue_name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "capacity",
      text: "Capacity",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "location",
      text: "Location",
      className: "location",
      align: "left",
      sortable: true,
    },
    {
      key: "opened",
      text: "Opened",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link
              to={`/update-venue/${record.venue_id}`}
              className="btn btn-primary"
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="section-heading">
            <h2>
              <b>Venue List</b>
            </h2>
          </div>
          <div>
            <div className="product-list-outer all-matches fees_list_page">
              <div className="buttonDirection">
                <Link to="/add-venue" className="btn btn-primary">
                  Add Venue
                </Link>
              </div>
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Venue;
