import React, { useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import {
  useGetLiveMatchQuery,
  useGetRecentMatchQuery,
  useGetUpcomingMatchQuery,
} from "../../../redux/matchApi";
import { Link } from "react-router-dom";
import UpdateMatchModal from "../../partial/match/UpdateMatchModal";

function AllMatches() {
  const { data: live } = useGetLiveMatchQuery({});
  const { data: finished } = useGetRecentMatchQuery({});
  const { data: upcoming, refetch } = useGetUpcomingMatchQuery({});
  const [updateModal, setUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({})
  const [seriesId, setSeriesId] = useState("")
  const [tab, setTab] = useState("Live");

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "series",
      text: "Series",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_type",
      text: "Match Type",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "team_a_short",
      text: "Team A",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "team_b_short",
      text: "Team B",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_status",
      text: "Match Status",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_date",
      text: "Match Date",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "view",
      text: "View",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {tab === "Upcoming" ? (
              <button
                onClick={() => {
                  editFee(record);
                }}
                className="btn btn-primary"
              >
                <span className="mdi mdi-square-edit-outline"></span>
              </button>
            ) : (
              <Link
                to={`/view-match/${record.match_id}`}
                className="btn btn-primary"
              >
                View
              </Link>
            )}
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const editFee=(record)=>{
    setUpdateModal(true)
    setCurrentRecord(record)
    setSeriesId(record.series_id)
  }


  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="section-heading">
            <h2>
              <b>All Match List</b>
            </h2>
            <div>
              <button
                onClick={() => {
                  setTab("Live");
                }}
                className="btn"
                style={{
                  background: tab === "Live" ? "#e65b3e" : "",
                  color: tab === "Live" ? "white" : "black",
                  border: "1px solid #e65b3e"
                }}
              >
                Live
              </button>
              <button
                onClick={() => {
                  setTab("Upcoming");
                }}
                className="btn ms-3"
                style={{
                  background: tab === "Upcoming" ? "#e65b3e" : "",
                  color: tab === "Upcoming" ? "white" : "black",
                  border: "1px solid #e65b3e"
                }}
              >
                Upcoming
              </button>
              <button
                onClick={() => {
                  setTab("Finished");
                }}
                className="btn ms-3"
                style={{
                  background: tab === "Finished" ? "#e65b3e" : "",
                  color: tab === "Finished" ? "white" : "black",
                  border: "1px solid #e65b3e"
                }}
              >
                Finished
              </button>
            </div>
          </div>
          <div>
            <div className="product-list-outer all-matches">
              <ReactDatatable
                config={config}
                records={
                  tab === "Live"
                    ? live
                    : tab === "Upcoming"
                    ? upcoming
                    : finished
                }
                columns={columns}
              />
            </div>
          </div>
          <UpdateMatchModal updateModal={updateModal} setUpdateModal={setUpdateModal} currentRecord={currentRecord} series_id={seriesId} getMatch={refetch}/>
        </div>
      </div>
    </div>
  );
}

export default AllMatches;
