
import { getCommentaryApi, updatecommentaryApi } from "../components/constant/Api";
import { myApi } from "./api";

export const commentaryApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getCommentary: builder.mutation({
      query: (post) => ({
        url: getCommentaryApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] :[];
      },
    }),
    updateCommentary: builder.mutation({
      query: (post) => ({
        url: updatecommentaryApi,
        method: "PUT",
        body:post
      }),
    }),
  
  }),
});

export const {useGetCommentaryMutation,useUpdateCommentaryMutation } = commentaryApi;