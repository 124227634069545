import {
  getNewsApi,
  getNewsByIdApi,
  getNewsPlayerIdApi,
  getNewsSeriesIdApi,
  insertNewsApi,
} from "../components/constant/Api";
import { myApi } from "./api";

export const newsApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => ({
        url: getNewsApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["news"],
    }),
    setNews: builder.mutation({
      query: (post) => ({
        url: insertNewsApi,
        method: "POST",
        body: post,
        formData: true,
      }),

      invalidatesTags: (_) => ["news"],
    }),
    getNewsSeries: builder.mutation({
      query: (post) => ({
        url: getNewsSeriesIdApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getNewsPlayer: builder.mutation({
      query: (post) => ({
        url: getNewsPlayerIdApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
    getNewsById: builder.mutation({
      query: (post) => ({
        url: getNewsByIdApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? {} : {};
      },
    }),
  }),
});

export const { useGetNewsQuery, useGetNewsSeriesMutation, useSetNewsMutation,useGetNewsByIdMutation,useGetNewsPlayerMutation } =
  newsApi;
