import React, { useEffect, useState } from "react";
import { useGetSeriesPlayerMutation } from "../../../../redux/playerApi";
import { useGetTeamByseriesMutation } from "../../../../redux/teamApi";

function Teams({ series_id }) {
  const [getTeamByseries, { data: teamList }] = useGetTeamByseriesMutation({});
  const [getSeriesPlayer,{data:playerList}]=useGetSeriesPlayerMutation()
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredArray, setFilteredArray] = useState([]);
  useEffect(() => {
    if (series_id) {
      const data = {
        series_id: series_id,
      };
      getTeamByseries(data);
    }
  }, [series_id]);

  useEffect(() => {
    if (teamList) {
      setTeamName(teamList[0].name);
      setTeamId(teamList[0].team_id);
    }
  }, [teamList]);

  useEffect(() => {
    if(series_id && teamId){
        const data={
            series_id:series_id,
            team_id:teamId
        }
        getSeriesPlayer(data)
    }
  }, [teamId,series_id])
  

  const handleTeam = (team_id, team_name) => {
    setTeamName(team_name);
    setTeamId(team_id);
  };
  useEffect(() => {
   if(playerList){
    setFilteredArray(playerList)
   }
  }, [playerList])
  

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filtered = playerList?.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase())
      )
    );
    setFilteredArray(filtered);
  };
  return (
    <div className="row m-0">
      <div className="col-md-3 col-12 p-0 border-end">
        <div className="squad-team">
          <ul>
            {teamList?.map((list) => {
              return (
                <li
                  className="position-relative"
                  onClick={() => {
                    handleTeam(list.team_id, list.name);
                  }}
                  key={list.team_id}
                >
                  <div
                    className={`squad-inner d-flex align-items-center ${
                      teamId == list.team_id ? "active" : ""
                    }`}
                  >
                    <div className="img-outer">
                      <img src={list.image} alt="" className="img-fluid" />
                    </div>
                    <div className="content-outer">
                      <h6>{list.name}</h6>
                    </div>
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="full-link position-absolute top-0 start-0 w-100 h-100"
                  ></a>
                </li>
              );
            })}
          </ul>
          {/* <div className="add-remove">
            <a href="javascript:void(0)">+ Add / </a>
            <a href="javascript:void(0)">Remove Team</a>
          </div> */}
        </div>
      </div>
      <div className="col-md-9 col-12 p-0">
        <div className="squad-team-detail">
          <div className="squad-title">
            <h3>{teamName}</h3>
          </div>
          <div className="search-player">
            <div className="form-group position-relative">
              <input
                type="text"
                name="searchQuery"
                placeholder="Search for Player"
                className="form-control"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              {/* <div className="search-btn position-absolute end-0 top-0">
                <button type="submit" class="btn btn-primary">
                  Search
                </button>
              </div> */}
            </div>
          </div>
          <div className="squad-player-detail table-responsive">
            <table className="w-100">
              <thead>
                <tr>
                  <th>Player Name</th>
                  <th className="text-center">Role</th>
                  <th className="text-center">Nationality</th>
                </tr>
              </thead>
              <tbody>
                {filteredArray?.map((list)=>{
                    return (
                        <tr align="center" key={list.player_id}>
                        <td className="d-flex align-items-center">
                          <div className="img-outer">
                            <img
                              src={list.image}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="player-position">
                            <p>
                              {list.player_name}
                              {/* <span className="position-tag">c</span> */}
                            </p>
                          </div>
                        </td>
                        <td> {list.player_role}</td>
                        <td> {list.nationality}</td>
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teams;
