import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useGetTeamByseriesMutation } from "../../../redux/teamApi";
import { useSetPointMutation } from "../../../redux/pointApi";

function AddPointModal(props) {
  const [getTeamByseries, { data: teamList }] = useGetTeamByseriesMutation({});
  const [setPoint] = useSetPointMutation();
  const [teamId, setTeamId] = useState("");
  const [teamIdErr, setTeamIdErr] = useState("");
  const [P, setP] = useState("0");
  const [PErr, setPErr] = useState("");
  const [W, setW] = useState("0");
  const [WErr, setWErr] = useState("");
  const [NR, setNR] = useState("0");
  const [NRErr, setNRErr] = useState("");
  const [Pts, setPts] = useState("0");
  const [PtsErr, setPtsErr] = useState("");
  const [NRR, setNRR] = useState("0");
  const [NRRErr, setNRRErr] = useState("");
  const [L, setL] = useState("0");
  const [LErr, setLErr] = useState("");
  const [D, setD] = useState("0");
  const [DErr, setDErr] = useState("");
  const InputValid = (name, value) => {
    let error = "";
    if (value === "") {
      error = "This field is required";
      return error;
    }
    return error;
  };

  useEffect(() => {
    if (props.series_id) {
      const data = {
        series_id: props.series_id,
      };
      getTeamByseries(data);
    }
  }, [props.series_id]);

  const handleClose = () => {
    setTeamId("");
    setTeamIdErr("");
    setP("0");
    setPErr("");
    setW("0");
    setWErr("");
    setNR("0");
    setNRErr("");
    setPts("0");
    setPtsErr("");
    setNRR("0");
    setNRRErr("");
    setL("0");
    setLErr("")
    setD("0")
    setDErr("")
    props.setAddModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "teamId") {
      setTeamId(value);
      const err = InputValid(name, value);
      setTeamIdErr(err);
    }
    if (name === "P") {
      setP(value);
      const err = InputValid(name, value);
      setPErr(err);
    }
    if (name === "W") {
      setW(value);
      const err = InputValid(name, value);
      setWErr(err);
    }
    if (name === "NR") {
      setNR(value);
      const err = InputValid(name, value);
      setNRErr(err);
    }
    if (name === "Pts") {
      setPts(value);
      const err = InputValid(name, value);
      setPtsErr(err);
    }
    if (name === "NRR") {
      setNRR(value);
      const err = InputValid(name, value);
      setNRRErr(err);
    }
    if (name === "L") {
      setL(value);
      const err = InputValid(name, value);
      setLErr(err);
    }
    if (name === "D") {
      setD(value);
      const err = InputValid(name, value);
      setDErr(err);
    }
  };

  const onsubmit = async (e) => {
    e.preventDefault();
    const checkTeamId = InputValid("teamId", teamId);
    const checkP = InputValid("P", P);
    const checkW = InputValid("W", W);
    const checkNR = InputValid("NR", NR);
    const checkPts = InputValid("Pts", Pts);
    const checkNRR = InputValid("NRR", NRR);
    const checkL = InputValid("L", L);
    const checkD = InputValid("D", D);
    if (checkTeamId) {
      setTeamIdErr(checkTeamId);
      return false;
    }
    if (checkP) {
      setPErr(checkP);
      return false;
    }
    if (checkW) {
      setW(checkW);
      return false;
    }
    if (checkNR) {
      setNRErr(checkNR);
      return false;
    }
    if (checkPts) {
      setPtsErr(checkPts);
      return false;
    }
    if (checkNRR) {
      setNRRErr(checkNRR);
      return false;
    }
    if (checkL) {
      setLErr(checkL);
      return false;
    }
    if (checkD) {
      setDErr(checkD);
      return false;
    }

    const data = {
      series_id: props.series_id,
      team_id: teamId,
      P: P,
      W: W,
      NR: NR,
      Pts: Pts,
      NRR: NRR,
      L:L,
      D:D
    };
    setPoint(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        props.getPoint();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div>
      <Modal show={props.addModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Point</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <Form>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Team</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="teamId"
                        onChange={handleChange}
                        value={teamId}
                      >
                        <option value="">Please select Team</option>
                        {teamList?.map((list) => {
                          return (
                            <option value={list.team_id}>{list.name}</option>
                          );
                        })}
                      </Form.Select>
                      <span style={{ color: "red" }}>{teamIdErr}</span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>P</Form.Label>
                      <Form.Control
                        name="P"
                        onChange={handleChange}
                        type="tel"
                        value={P}
                      ></Form.Control>
                      <span style={{ color: "red" }}>{PErr}</span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>W</Form.Label>
                      <Form.Control
                        name="W"
                        onChange={handleChange}
                        type="tel"
                        value={W}
                      ></Form.Control>
                      <span style={{ color: "red" }}>{WErr}</span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>NR</Form.Label>
                      <Form.Control
                        name="NR"
                        onChange={handleChange}
                        type="tel"
                        value={NR}
                      ></Form.Control>
                      <span style={{ color: "red" }}>{NRErr}</span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Pts</Form.Label>
                      <Form.Control
                        name="Pts"
                        onChange={handleChange}
                        type="tel"
                        value={Pts}
                      ></Form.Control>
                      <span style={{ color: "red" }}>{PtsErr}</span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>NRR</Form.Label>
                      <Form.Control
                        name="NRR"
                        onChange={handleChange}
                        type="tel"
                        value={NRR}
                      ></Form.Control>
                      <span style={{ color: "red" }}>{NRRErr}</span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>L</Form.Label>
                      <Form.Control
                        name="L"
                        onChange={handleChange}
                        type="tel"
                        value={L}
                      ></Form.Control>
                      <span style={{ color: "red" }}>{LErr}</span>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>D</Form.Label>
                      <Form.Control
                        name="D"
                        onChange={handleChange}
                        type="tel"
                        value={D}
                      ></Form.Control>
                      <span style={{ color: "red" }}>{DErr}</span>
                    </Form.Group>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddPointModal;
