const sideMenuList = [
  {
    id: 1,
    title: "Dashboard",
    route: "/dashboard",
    icon: "mdi mdi-home-outline",
  },
  {
    id: 6,
    title: "Matches",
    route: "/matches",
    icon: "mdi mdi-cricket",
  },
  {
    id: 5,
    title: "Series",
    route: "/series",
    icon: "mdi mdi-trophy-outline",
  },
  {
    id: 7,
    title: "All Matches",
    route: "/all-match",
    icon: "mdi mdi-cricket",
  },
  {
    id: 4,
    title: "Players",
    route: "/players",
    icon: "mdi mdi-account-outline",
  },
  {
    id: 3,
    title: "Team",
    route: "/teams",
    icon: "mdi mdi-account-group-outline",
  },
  {
    id: 10,
    title: "News",
    route: "/news",
    icon: "mdi mdi-newspaper",
  },
  {
    id: 2,
    title: "Venue",
    route: "/venue",
    icon: "mdi mdi-map-marker",
  },
  {
    id: 2,
    title: "Country",
    route: "/country",
    icon: "mdi mdi-map-marker",
  },
  {
    id: 2,
    title: "Umpire",
    route: "/umpire",
    icon: "mdi mdi-account-outline",
  },
  {
    id: 7,
    title: "Upcoming Matches",
    route: "/upcoming-match",
    icon: "mdi mdi-cricket",
  },
  {
    id: 8,
    title: "Live Matches",
    route: "/live-match",
    icon: "mdi mdi-cricket",
  },
  {
    id: 9,
    title: "Recent Matches",
    route: "/recent-match",
    icon: "mdi mdi-cricket",
  },

];
export default sideMenuList;
