import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { ImageValid } from "../../validations/ImageValid";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { NewsValid } from "../../validations/NewsValid";
import { useNavigate, useParams } from "react-router-dom";
import { useSetNewsMutation } from "../../../redux/newsApi";

function AddNews() {
  const { series_id, player_id } = useParams();
  const navigation = useNavigate();
  const [setNews] = useSetNewsMutation();
  const [newsField, setNewsField] = useState({
    title: "",
    description: "",
    pubDate: "",
  });
  const [newsFieldErr, setNewsFieldErr] = useState({
    title: "",
    description: "",
    pubDate: "",
  });
  const [content, setContent] = useState("");
  const [contentErr, setContentErr] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState("");

  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "venueimage") {
      setImage(image);
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewsField({ ...newsField, [name]: value });
    let checkNews = NewsValid(name, value);
    setNewsFieldErr({ ...newsFieldErr, [name]: checkNews });
  };

  const onsubmit = (e) => {
    e.preventDefault();
    for (let key in newsField) {
      let checkNews = NewsValid(key, newsField[key]);
      setNewsFieldErr({ ...newsFieldErr, [key]: checkNews });
      if (checkNews !== "") {
        return false;
      }
    }
    let checkImage = ImageValid("image", image);
    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }
    if (content === "") {
      setContentErr("This field is required");
      return false;
    }

    const formdata = new FormData();
    formdata.append("title", newsField.title);
    formdata.append("description", newsField.description);
    formdata.append("content", content);
    formdata.append("pub_date", newsField.pubDate);
    formdata.append("series_id", series_id ? series_id : "");
    formdata.append("player_id", player_id ? player_id : "");
    formdata.append("news_image", image);
    setNews(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        if (player_id) {
          navigation(`/player-news/${player_id}`, { replace: true });
        } else if (series_id) {
          if (localStorage.getItem("path")) {
            navigation(localStorage.getItem("path"), { replace: true });
            localStorage.removeItem("path");
          } else {
            navigation(`/series-news/${series_id}`, { replace: true });
          }
        } else {
          navigation("/news", { replace: true });
        }
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Add News</b>
              </h2>
            </div>
            <div className="card p-4 mt-3">
              <div className="product-list-outer card-boay">
                <Form onSubmit={onsubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Title* </Form.Label>
                        <Form.Control
                          name="title"
                          onChange={handleChange}
                          type="text"
                          value={newsField.title}
                        ></Form.Control>
                        <span style={{ color: "red" }}>
                          {newsFieldErr.title}
                        </span>
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Description* </Form.Label>
                        <Form.Control
                          name="description"
                          onChange={handleChange}
                          type="text"
                          value={newsField.description}
                        ></Form.Control>
                        <span style={{ color: "red" }}>
                          {newsFieldErr.description}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Publish Date* </Form.Label>
                        <Form.Control
                          name="pubDate"
                          onChange={handleChange}
                          type="date"
                          value={newsField.pubDate}
                          min={new Date().toISOString().split("T")[0]}
                        ></Form.Control>
                        <span style={{ color: "red" }}>
                          {newsFieldErr.pubDate}
                        </span>
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Image*</Form.Label>
                        <Form.Control
                          type="file"
                          name="venueimage"
                          onChange={handlechangeimage}
                        />
                        <span style={{ color: "red" }}>{imageErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-12">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Content*</Form.Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={content}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                            ],
                            heading: {
                              options: [
                                {
                                  model: "paragraph",
                                  title: "Paragraph",
                                  class: "ck-heading_paragraph",
                                },
                                {
                                  model: "heading1",
                                  view: "h1",
                                  title: "Heading 1",
                                  class: "ck-heading_heading1",
                                },
                                {
                                  model: "heading2",
                                  view: "h2",
                                  title: "Heading 2",
                                  class: "ck-heading_heading2",
                                },
                                {
                                  model: "heading3",
                                  view: "h3",
                                  title: "Heading 3",
                                  class: "ck-heading_heading3",
                                },
                              ],
                            },
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setContent(data);
                            if (data) {
                              setContentErr("");
                            }
                          }}
                        />
                        <span style={{ color: "red" }}>{contentErr}</span>
                      </Form.Group>
                    </div>
                  </div>

                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNews;
