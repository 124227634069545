import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./components/auth/Login";
import Dashboard from "./components/widgets/Dashboard";
import Team from "./components/pages/team/Team";
import Player from "./components/pages/player/Player";
import Series from "./components/pages/series/Series";
import Match from "./components/pages/match/Match";
import Venue from "./components/pages/venue/Venue";
import AddPlayer from "./components/partial/player/AddPlayer";
import TeamPlayer from "./components/pages/player/TeamPlayer";
import AddVenue from "./components/partial/venue/AddVenue";
import Addseries from "./components/partial/series/Addseries";
import SeriesMatch from "./components/pages/match/SeriesMatch";
import UpcomingMatch from "./components/pages/match/UpcomingMatch";
import RecentMatch from "./components/pages/match/RecentMatch";
import LiveMatch from "./components/pages/match/LiveMatch";
import News from "./components/pages/news/News";
import NewsSeries from "./components/pages/news/NewsSeries";
import AddNews from "./components/partial/news/AddNews";
import UpdateVenue from "./components/partial/venue/UpdateVenue";
import UpdatePlayer from "./components/partial/player/UpdatePlayer";
import NewsDetail from "./components/pages/news/NewsDetail";
import PointSeries from "./components/pages/point/PointSeries";
import AddSquad from "./components/pages/player/AddSquad";
import AddTeam from "./components/partial/team/AddTeam";
import UpdateTeam from "./components/partial/team/UpdateTeam";
import MatchDetail from "./components/pages/viewmatch/MatchDetail";
import UpdateSeries from "./components/partial/series/UpdateSeries";
import SeriesDetail from "./components/pages/series/seriesdetail/SeriesDetail";
import NewsPlayer from "./components/pages/news/NewsPlayer";
import Umpire from "./components/pages/umpire/Umpire";
import AllMatches from "./components/pages/match/AllMatches";
import ConnectionSpeed from "./components/constant/ConnectionSpeed";
import Country from "./components/pages/country/Country";

function App() {
  const { login } = useAuth();

  useEffect(() => {
    // Check if a JWT token is stored in localStorage and log in the user if found
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);
  return (
    <div className="App">
      <ConnectionSpeed/>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          <Route
            path="/teams"
            element={<ProtectedRoute component={<Team />} />}
          />
          <Route
            path="/add-team"
            element={<ProtectedRoute component={<AddTeam />} />}
          />
          <Route
            path="/update-team/:team_id"
            element={<ProtectedRoute component={<UpdateTeam />} />}
          />
          <Route
            path="/players"
            element={<ProtectedRoute component={<Player />} />}
          />
          <Route
            path="/team-player/:team_id/:team_name"
            element={<ProtectedRoute component={<TeamPlayer />} />}
          />
          <Route
            path="/add-player"
            element={<ProtectedRoute component={<AddPlayer />} />}
          />
          <Route
            path="/update-player/:player_id"
            element={<ProtectedRoute component={<UpdatePlayer />} />}
          />
          <Route
            path="/series"
            element={<ProtectedRoute component={<Series />} />}
          />
          <Route
            path="/add-series"
            element={<ProtectedRoute component={<Addseries />} />}
          />
          <Route
            path="/update-series/:series_id"
            element={<ProtectedRoute component={<UpdateSeries />} />}
          />
          <Route
            path="/matches"
            element={<ProtectedRoute component={<Match />} />}
          />
          <Route
            path="/view-match/:match_id"
            element={<ProtectedRoute component={<MatchDetail />} />}
          />
          <Route
            path="/series-match/:series_id"
            element={<ProtectedRoute component={<SeriesMatch />} />}
          />
            <Route
            path="/series-view/:series_id"
            element={<ProtectedRoute component={<SeriesDetail />} />}
          />
           <Route
            path="/series-point/:series_id/:series_name"
            element={<ProtectedRoute component={<PointSeries />} />}
          />
          <Route
            path="/upcoming-match"
            element={<ProtectedRoute component={<UpcomingMatch />} />}
          />
           <Route
            path="/add-squad/:teamAId/:teamBId/:match_id"
            element={<ProtectedRoute component={<AddSquad />} />}
          />
          <Route
            path="/recent-match"
            element={<ProtectedRoute component={<RecentMatch />} />}
          />
          <Route
            path="/live-match"
            element={<ProtectedRoute component={<LiveMatch />} />}
          />
           <Route
            path="/all-match"
            element={<ProtectedRoute component={<AllMatches />} />}
          />
          <Route
            path="/venue"
            element={<ProtectedRoute component={<Venue />} />}
          />
          <Route
            path="/add-venue"
            element={<ProtectedRoute component={<AddVenue />} />}
          />
          <Route
            path="/update-venue/:venue_id"
            element={<ProtectedRoute component={<UpdateVenue />} />}
          />
          <Route
            path="/news"
            element={<ProtectedRoute component={<News />} />}
          />
          <Route
            path="/add-news"
            element={<ProtectedRoute component={<AddNews />} />}
          />
          <Route
            path="/add-news/:series_id"
            element={<ProtectedRoute component={<AddNews />} />}
          />
           <Route
            path="/add-news-player/:player_id"
            element={<ProtectedRoute component={<AddNews />} />}
          />
           <Route
            path="/news-detail/:news_id"
            element={<ProtectedRoute component={<NewsDetail />} />}
          />
            <Route
            path="/series-news/:series_id"
            element={<ProtectedRoute component={<NewsSeries />} />}
          />
          <Route
            path="/player-news/:player_id"
            element={<ProtectedRoute component={<NewsPlayer />} />}
          />
          <Route
            path="/umpire"
            element={<ProtectedRoute component={<Umpire />} />}
          />
            <Route
            path="/country"
            element={<ProtectedRoute component={<Country />} />}
          />
        </Routes>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
