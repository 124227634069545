import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetMatchByIdMutation } from "../../../../redux/matchApi";
import { useGetMatchSquadMutation, useGetMatchSquadXIMutation } from "../../../../redux/playerApi";
import { useGetScoreCardInningMutation } from "../../../../redux/scorecardApi";
import Batter from "./Batter";
import Bowler from "./Bowler";
import FirstCircle from "./FirstCircle";
import Result from "./Result";
import ScoreCard from "./ScoreCard";
import Status from "./Status";
import Toss from "./Toss";
// import Umpire from "./Umpire";

function Live({ match_id, sendData, ws }) {
  const [getMatchSquad, { data: allSquad }] = useGetMatchSquadMutation();
  const [getMatchSquadXI, { data: squad }] = useGetMatchSquadXIMutation();
  const [batter, setBatter] = useState([])
  const [getScoreCardInning, { data: scoreCard }] =
    useGetScoreCardInningMutation();
  const [getMatchById, { data }] = useGetMatchByIdMutation();
  const [battingTeam, setBattingTeam] = useState("");
  const [bowlingTeam, setBowlingTeam] = useState("");
  const [firstCircle, setFirstCircle] = useState("");
  const [wicketBowler, setWicketBowler] = useState([]);
  const [battingPlayer, setBattingPlayer] = useState([]);
  const [bowlingPlayer, setBowlingPlayer] = useState([]);
  const [teamScore, setTeamScore] = useState({});
  const [matchDetail, setMatchDetail] = useState({});
  const [currentInning, setCurrentInning] = useState("");
  const [showResult, setShowResult] = useState(false)
  const [allBowlingSquad, setAllBowlingSquad] = useState([])
  const [bowler, setBowler] = useState({});
  const [extraRun, setExtraRun] = useState({
    w: "0",
    nb: "0",
    lb: "0",
    b: "0",
  });
  const [result, setResult] = useState("");
  const [status, setStatus] = useState("");
  const [last4over, setLast4over] = useState([]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.eventType === "recieve-match-detail" && message.status) {
          setMatchDetail(message.data[0]);
        }
      };
    }
  }, [ws]);

  useEffect(() => {
    if (match_id) {
      getMatchSquadXI({ match_id: match_id });
      getMatchSquad({ match_id: match_id })
    }
  }, [match_id]);

  useEffect(() => {
    getMatchDetail();
  }, []);

  useEffect(() => {
    if (battingTeam) {
      if (squad?.team_a?.team_id == battingTeam) {
        setBattingPlayer(squad?.team_a?.player);
      } else {
        setBattingPlayer(squad?.team_b?.player);
      }
    }
    if (bowlingTeam) {
      if (squad?.team_a?.team_id == bowlingTeam) {
        setBowlingPlayer(squad?.team_a?.player);
        setAllBowlingSquad(allSquad?.team_a?.player)
      } else {
        setBowlingPlayer(squad?.team_b?.player);
        setAllBowlingSquad(allSquad?.team_b?.player)
      }
    }
  }, [battingTeam, bowlingTeam, match_id, squad]);
  useEffect(() => {
    if (currentInning) {
      getScoreCardInning({ match_id: match_id, inning: currentInning });
    }
  }, [currentInning]);

  const getMatchDetail = async () => {
    const data = await getMatchById({ match_id: match_id });
    if (data.data) {
      let matchDetail = data.data;
      setBattingTeam(matchDetail?.batting_team);
      setBowlingTeam(matchDetail?.bowling_team);
      setMatchDetail(matchDetail);
      setCurrentInning(matchDetail?.current_inning);
      setResult(matchDetail?.result ? matchDetail?.result : "");
      setStatus(matchDetail?.match_status);
      setLast4over(
        matchDetail?.last4overs ? JSON.parse(matchDetail?.last4overs) : []
      );
      if(matchDetail?.match_status==='Finished'){
        setShowResult(true)
      }
      else{
        setShowResult(false)
      }
    }
  };
  useEffect(() => {
    if(last4over.length>0 && match_id){
      const data = {
        match_id: match_id,
        last_4_overs: JSON.stringify(last4over),
      };
      const eventType = "update-last-4-over";
      sendData(eventType, data);
    }
  }, [last4over,match_id])
  
  const scrollTop=()=>{
 
  window.scrollTo({
    top: 400,
    behavior: 'smooth',
});
setShowResult(true);
  }

  const handleRecent=(i,e)=>{
    const { name, value } = e.target;
    let newLast4over = [...last4over];
    newLast4over[i][name] = value;
    setLast4over(newLast4over);
  }

  const handleRecentBall=(i,bi,e)=>{
    const { name, value } = e.target;
    let newLast4over = [...last4over];
    newLast4over[i][name][bi] = value;
    setLast4over(newLast4over);
  }

  const removeBall=(i,bi)=>{
    let newLast4over = [...last4over];
    newLast4over[i].balls.splice(bi,1)
    setLast4over(newLast4over);
  }

  return (
    <>
      {/* {status === "Live" ? ( */}
        <>
          <div className=" h-auto">
            <div className="row">
              <div className="col-md-3">
                <Toss
                  data={data}
                  getMatchDetail={getMatchDetail}
                  sendData={sendData}
                />
              </div>
              <div className="col-md-5">
                <div className="match-name">
                  <h3 className="text-center">
                    {data?.team_a_name} VS {data?.team_b_name}, {data?.matchs} (
                    {data?.match_type})
                  </h3>

                  <div className="mc-detail">
                    <p>
                      <b>Series: </b>
                      {data?.series}
                    </p>

                    <p>
                      <b>Date & Time: </b>
                      {data?.match_date}
                    </p>
                  </div>
                  <p>
                    <b>Venue: </b>
                    {data?.venue}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <Status data={data} getMatchDetail={getMatchDetail} />
              </div>
              <div className="col-12">
                <ScoreCard
                  matchDetail={matchDetail}
                  data={data}
                  setResult={setResult}
                  getMatchDetail={getMatchDetail}
                />
              </div>
            </div>
          </div>
          {last4over.length > 0 && (
            <div className="this-over">
              <div className="row align-items-center">
                <div className="col-md-3 col-sm-6 col-12">
                  <div className="ball-score">
                    <ul className="nav">
                      <li>
                        <span>This Over: </span>
                      </li>
                      <li>
                        <p className="m-0">{last4over[0]?.over}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                {last4over.slice(0, 3)?.map((list,index) => {
                  return (
                    <div className="col-md-3 col-sm-6 col-12">
                      <div className="ball-score">
                        {list?.balls.length > 0 && (
                          <ul className="nav">
                            <li>
                              <input
                                type="number"
                                name="run"
                                placeholder=""
                                className="form-control p-0"
                                value={list.run}
                                onChange={(e)=>{handleRecent(index,e)}}
                              /> 
                            </li>
                            <li>=</li>
                            {list?.balls?.map((balls,ballIndex) => {
                              return (
                                <li className="recent-ball">
                                  {/* <p className="m-0">{balls}</p> */}
                                  <input
                                type="text"
                                name="balls"
                                placeholder=""
                                className="form-control p-0"
                                value={balls}
                                onChange={(e)=>{handleRecentBall(index,ballIndex,e)}}
                              />
                              <span class="mdi mdi-close" onClick={()=>{removeBall(index,ballIndex)}}></span>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  );
                })}
                {/*          
              <div className="col-md-3 col-sm-6 col-12">
                <div className="ball-score">
                  <ul className="nav">
                    <li>
                      <input type="number" name="" placeholder="" className="form-control p-0"/>
                    </li>
                    <li>
                      <p className="m-0">0</p>
                    </li>
                    <li>
                      <p className="m-0">2</p>
                    </li>
                    <li>
                      <p className="m-0">0</p>
                    </li>
                    <li>
                      <p className="m-0">4</p>
                    </li>
                    <li>
                      <p className="m-0">6</p>
                    </li>
                    <li>
                      <p className="m-0">1</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12">
                <div className="ball-score">
                  <ul className="nav">
                    <li>
                      <input type="number" name="" placeholder="" className="form-control p-0"/>
                    </li>
                    <li>
                      <p className="m-0">0</p>
                    </li>
                    <li>
                      <p className="m-0">2</p>
                    </li>
                    <li>
                      <p className="m-0">0</p>
                    </li>
                    <li>
                      <p className="m-0">4</p>
                    </li>
                    <li>
                      <p className="m-0">6</p>
                    </li>
                    <li>
                      <p className="m-0">1</p>
                    </li>
                  </ul>
                </div>
              </div> */}
              </div>
            </div>
          )}
          {showResult && (<Result data={data} squad={squad} setResult={setResult} matchDetail={matchDetail} getMatchDetail={getMatchDetail}/>)}
          <div className="scoreboard">
            <div className="row">
              <div className="col-md-2">
                <FirstCircle
                  data={data}
                  setTeamScore={setTeamScore}
                  result={result}
                  sendData={sendData}
                  setLast4over={setLast4over}
                  last4over={last4over}
                  getMatchDetail={getMatchDetail}
                  setFirstCircle={setFirstCircle}
                  bowler={bowler}
                  batter={batter}
                />
              </div>
              <div className="col-md-4 p-0">
                <Bowler
                  bowlingPlayer={bowlingPlayer}
                  data={data}
                  teamScore={teamScore}
                  scoreCard={scoreCard}
                  extraRun={extraRun}
                  setExtraRun={setExtraRun}
                  sendData={sendData}
                  allBowlingSquad={allBowlingSquad}
                  setBowler={setBowler}
                  setWicketBowler={setWicketBowler}
                />
              </div>
              <div className="col-md-6">
                <Batter
                  battingPlayer={battingPlayer}
                  data={data}
                  teamScore={teamScore}
                  scoreCard={scoreCard}
                  setExtraRun={setExtraRun}
                  extraRun={extraRun}
                  getMatchDetail={getMatchDetail}
                  sendData={sendData}
                  bowler={bowler}
                  firstCircle={firstCircle}
                  setWicketBowler={setWicketBowler}
                  wicketBowler={wicketBowler}
                  setBatter={setBatter}
                />
              </div>
            </div>
          </div>
          <div>
            <button className="btn btn-danger mt-4" onClick={()=>{scrollTop()}}>Match Finished</button>
            <Link className="btn btn-danger mt-4 ms-3" to={`/series-point/${data?.series_id}/${data?.series}`}>Update Point</Link>
            <Link className="btn btn-danger mt-4 ms-3" to={`/update-venue/${data?.venue_id}`}>Update Venue</Link>
                      </div>
        </>
      {/* ) : (
        <div className="card">
          <div className="card-body">
            <h2>
              {data?.team_a_short} {data?.team_a_scores} ({data?.team_a_over})
            </h2>

            <h2>
              {data?.team_b_short} {data?.team_b_scores} ({data?.team_b_over})
            </h2>
            <p className="text-danger">{data?.result}</p>
            <div>
              <div className="row mt-4">
                <div className="col-md-3">
                  <h3 className="matc_info">Match Info</h3>
                  <div className="left_matc_info">
                    <p>
                      <span>
                        <strong>Match</strong>
                      </span>
                      <span>
                        {data?.team_a_short} vs {data?.team_b_short},{" "}
                        {data?.series}
                      </span>
                    </p>
                    <p>
                      <span>
                        <strong>Date</strong>
                      </span>
                      <span>{data?.match_date}</span>
                    </p>
                    <p>
                      <span>
                        <strong>Toss</strong>
                      </span>
                      <span>{data?.toss}</span>
                    </p>
                    <p>
                      <span>
                        <strong>Venue</strong>
                      </span>
                      <span>{data?.venue}</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-9"></div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default Live;
