import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetMatchByIdMutation,
  useUpdateUmpireMutation,
} from "../../../../redux/matchApi";
import {
  useGetMatchSquadMutation,
  useGetMatchSquadXIMutation,
  useSetMatchSquadXIMutation,
  useSetPlayerMatchRoleMutation,
} from "../../../../redux/playerApi";
import { useGetScoreCardMatchMutation } from "../../../../redux/scorecardApi";

function Info({ match_id }) {
  const [getMatchById, { data }] = useGetMatchByIdMutation();
  const [getMatchSquad, { data: squad }] = useGetMatchSquadMutation();
  const [getMatchSquadXI, { data: squadXI }] = useGetMatchSquadXIMutation();
  const [getScoreCardMatch, { data: scoreCard }] =
    useGetScoreCardMatchMutation();
  const [setPlayerMatchRole] = useSetPlayerMatchRoleMutation();
  const [setMatchSquadXI] = useSetMatchSquadXIMutation();
  const [updateUmpire] = useUpdateUmpireMutation();
  const [umpire, setUmpire] = useState("");
  const [thirdUmpire, setThirdUmpire] = useState("");
  const [legUmpire, setLegUmpire] = useState("");
  const [referee, setReferee] = useState("");
  const [pitchReport, setPitchReport] = useState("");
  const [pitchBehaviour, setPitchBehaviour] = useState("");
  const [selectedTeamAIds, setSelectedTeamAIds] = useState([]);
  const [selectedTeamBIds, setSelectedTeamBIds] = useState([]);
  const [teamAPlayer, setTeamAPlayer] = useState([]);
  const [filterTeamAPlayer, setFilterTeamAPlayer] = useState([]);
  const [filterTeamBPlayer, setFilterTeamBPlayer] = useState([]);
  const [teamBPlayer, setTeamBPlayer] = useState([]);
  const [favTeam, setFavTeam] = useState("");
  const [selectedTeamA, setSelectedTeamA] = useState([]);
  const [selectedTeamB, setSelectedTeamB] = useState([]);
  const [squadClass, setSquadClass] = useState("");
  const [scoreClass, setScoreClass] = useState("");
  const [teamAForms, setTeamAForms] = useState("");
  const [teamBForms, setTeamBForms] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryB, setSearchQueryB] = useState("");
  const [paceSpin, setPaceSpin] = useState({
    pace_wkt: 0,
    spin_wkt: 0,
    pace_percent: 0,
    spin_percent: 0,
  });
  const [headToHead, setHeadToHead] = useState({
    total_matches: 0,
    win_bat_first: 0,
    win_bowl_first: 0,
    team_a_win_count: 0,
    team_b_win_count: 0,
    average_first_inning_score: 0,
    average_second_inning_score: 0,
    highest_total: 0,
    lowest_total: 0,
    highest_cheased: 0,
    lowest_defend: 0,
  });
  const [venueWeather, setVenueWeather] = useState({
    temp_c: "",
    temp_f: "",
    weather: "",
    weather_icon: "",
    wind_mph: "",
    wind_kph: "",
    wind_dir: "",
    humidity: "",
    cloud: "",
  });

  useEffect(() => {
    if (match_id) {
      getMatchDetail();
      getMatchSquad({ match_id: match_id });
      getMatchSquadXI({ match_id: match_id });
    }
  }, [match_id]);

  useEffect(() => {
    if (squadXI) {
      let idA = squadXI?.team_a?.player.map((obj) => obj.id);
      let idB = squadXI?.team_b?.player.map((obj) => obj.id);
      setSelectedTeamA(squadXI?.team_a?.player);
      setSelectedTeamB(squadXI?.team_b?.player);
      setSelectedTeamAIds(idA);
      setSelectedTeamBIds(idB);
    }
  }, [squadXI]);

  const onRefreshScore = () => {
    setScoreClass("rotate-icon");
    getScoreCardMatch({ match_id: match_id });
    setTimeout(() => {
      setScoreClass("");
    }, 2000);
  };

  const onRefreshSquad = () => {
    setSquadClass("rotate-icon");
    getMatchSquad({ match_id: match_id });
    getMatchSquadXI({ match_id: match_id });
    setTimeout(() => {
      setSquadClass("");
    }, 2000);
  };

  const getMatchDetail = async () => {
    getMatchById({ match_id: match_id });
  };

  useEffect(() => {
    if (data) {
      let matchDetail = data;
      setUmpire(matchDetail?.umpire);
      setThirdUmpire(matchDetail?.third_umpire);
      setLegUmpire(matchDetail?.leg_umpire ? matchDetail?.leg_umpire : "");
      setReferee(matchDetail?.referee);
      setFavTeam(matchDetail?.fav_team);
      setPitchReport(
        matchDetail?.pitch_report ? matchDetail?.pitch_report : ""
      );
      setPitchBehaviour(
        matchDetail?.pitch_behaviour ? matchDetail?.pitch_behaviour : ""
      );
      setTeamAForms(matchDetail?.team_a_forms);
      setTeamBForms(matchDetail?.team_b_forms);
      setVenueWeather(
        matchDetail?.venue_weather
          ? JSON.parse(matchDetail?.venue_weather)
          : {
              temp_c: "",
              temp_f: "",
              weather: "",
              weather_icon: "",
              wind_mph: "",
              wind_kph: "",
              wind_dir: "",
              humidity: "",
              cloud: "",
            }
      );
      setPaceSpin(
        matchDetail?.pace_spin
          ? JSON.parse(matchDetail?.pace_spin)
          : {
              pace_wkt: 0,
              spin_wkt: 0,
              pace_percent: 0,
              spin_percent: 0,
            }
      );
      setHeadToHead(matchDetail?.head_to_head?JSON.parse(matchDetail?.head_to_head):{
        total_matches: 0,
        win_bat_first: 0,
        win_bowl_first: 0,
        team_a_win_count: 0,
        team_b_win_count: 0,
        average_first_inning_score: 0,
        average_second_inning_score: 0,
        highest_total: 0,
        lowest_total: 0,
        highest_cheased: 0,
        lowest_defend: 0,
      })
    }
  }, [data]);

  useEffect(() => {
    // if (teamAPlayer.length > 0 && selectedTeamAIds.length > 0) {
    //   // Filter the players whose IDs are present in selectedTeamAIds

    //   // Sort the filtered players based on the order of IDs in selectedTeamAIds
    //   let sortedPlayers = filteredPlayers.sort((a, b) => {
    //     return selectedTeamAIds.indexOf(b.id) - selectedTeamAIds.indexOf(a.id);
    //   });

    //   // Concatenate the sorted players with the remaining players
    //   let remainingPlayers = teamAPlayer.filter(
    //     (player) => !selectedTeamAIds.includes(player.id)
    //   );
    //   let finalPlayers = sortedPlayers.concat(remainingPlayers);

    // }
    if (squad?.team_a?.player.length > 0 && selectedTeamAIds.length > 0) {
      let filteredPlayers = squad?.team_a?.player.filter(
        (player) => !selectedTeamAIds.includes(player.id)
      );
      setTeamAPlayer(filteredPlayers);
      setFilterTeamAPlayer(filteredPlayers);
    } else {
      setTeamAPlayer(squad?.team_a?.player);
      setFilterTeamAPlayer(squad?.team_a?.player);
    }
  }, [selectedTeamAIds, squad]);

  useEffect(() => {
    if (squad?.team_b?.player.length > 0 && selectedTeamBIds.length > 0) {
      // Filter the players whose IDs are present in selectedTeamAIds
      let filteredPlayers = squad?.team_b?.player.filter(
        (player) => !selectedTeamBIds.includes(player.id)
      );

      // // Sort the filtered players based on the order of IDs in selectedTeamAIds
      // let sortedPlayers = filteredPlayers.sort((a, b) => {
      //   return selectedTeamBIds.indexOf(b.id) - selectedTeamBIds.indexOf(a.id);
      // });

      // // Concatenate the sorted players with the remaining players
      // let remainingPlayers = teamBPlayer.filter(
      //   (player) => !selectedTeamBIds.includes(player.id)
      // );
      // let finalPlayers = sortedPlayers.concat(remainingPlayers);

      setTeamBPlayer(filteredPlayers);
      setFilterTeamBPlayer(filteredPlayers);
    } else {
      setTeamBPlayer(squad?.team_b?.player);
      setFilterTeamBPlayer(squad?.team_b?.player);
    }
  }, [selectedTeamBIds, squad]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "umpire") {
      setUmpire(value);
    }
    if (name === "thirdUmpire") {
      setThirdUmpire(value);
    }
    if (name === "referee") {
      setReferee(value);
    }
    if (name === "legUmpire") {
      setLegUmpire(value);
    }
    if (name === "favTeam") {
      setFavTeam(value);
    }
    if (name === "pitchBehaviour") {
      setPitchBehaviour(value);
    }
    if (name === "pitchReport") {
      setPitchReport(value);
    }
    if (name === "teamAForms") {
      setTeamAForms(value);
    }
    if (name === "teamBForms") {
      setTeamBForms(value);
    }
  };

  const updateMatchUmpire = (e) => {
    e.preventDefault();
    let updateData = {
      umpire: umpire,
      third_umpire: thirdUmpire,
      leg_umpire: legUmpire,
      referee: referee,
      match_id: match_id,
      fav_team: favTeam,
      pitch_behaviour: pitchBehaviour,
      pitch_report: pitchReport,
      team_a_forms: teamAForms,
      team_b_forms: teamBForms,
      team_a_id: data?.team_a_id,
      team_b_id: data?.team_b_id,
      venue_weather: JSON.stringify(venueWeather),
      pace_spin: JSON.stringify(paceSpin),
      head_to_head:JSON.stringify(headToHead)
    };
    updateUmpire(updateData).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        getMatchDetail();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  const handleWeather = (e) => {
    const { name, value } = e.target;
    setVenueWeather({ ...venueWeather, [name]: value });
  };

  const handleHeadToHead = (e) => {
    const { name, value } = e.target;
    setHeadToHead({ ...headToHead, [name]: value });
  };

  const handlePaceSpin = (e) => {
    const { name, value } = e.target;
    setPaceSpin({ ...paceSpin, [name]: value });
  };

  const handlecheckedTeamA = (id) => {
    const index = selectedTeamAIds.indexOf(id);
    if (index !== -1) {
      const updateTeamPlayerIds = [...selectedTeamAIds];
      updateTeamPlayerIds.splice(index, 1);
      const filter = selectedTeamA.filter((list) => {
        return list.id != id;
      });
      setSelectedTeamAIds(updateTeamPlayerIds);
      setSelectedTeamA(filter);
    } else {
      const filter = teamAPlayer.filter((list) => {
        return list.id == id;
      });
      setSelectedTeamAIds([...selectedTeamAIds, id]);
      setSelectedTeamA([...selectedTeamA, filter[0]]);
    }
  };

  const handlecheckedTeamB = (id) => {
    const index = selectedTeamBIds.indexOf(id);
    if (index !== -1) {
      const updateTeamPlayerIds = [...selectedTeamBIds];
      updateTeamPlayerIds.splice(index, 1);
      const filter = selectedTeamB.filter((list) => {
        return list.id != id;
      });
      setSelectedTeamBIds(updateTeamPlayerIds);
      setSelectedTeamB(filter);
    } else {
      const filter = teamBPlayer.filter((list) => {
        return list.id == id;
      });
      setSelectedTeamBIds([...selectedTeamBIds, id]);
      setSelectedTeamB([...selectedTeamB, filter[0]]);
    }
  };

  const onSubmitSquad = () => {
    let selectTeamPlayerIds = selectedTeamAIds.concat(selectedTeamBIds);
    if (selectTeamPlayerIds.length < 22) {
      toast.dismiss();
      toast.error("Please select at least 22 player");
      return false;
    }
    const playerArray = selectTeamPlayerIds.map((num) => `"${num}"`).join(",");
    const data = {
      match_id: match_id,
      selected_player: playerArray,
    };
    setMatchSquadXI(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        getMatchSquadXI({ match_id: match_id });
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  const updateMatchRole = (index, id, matchRole, team) => {
    const data = {
      id: id,
      match_role: matchRole,
    };
    setPlayerMatchRole(data);
    if (team == "A") {
      let newPlayer = [...selectedTeamA];
      newPlayer[index] = { ...newPlayer[index], match_role: matchRole };
      setSelectedTeamA(newPlayer);
    } else {
      let newPlayer = [...selectedTeamB];
      newPlayer[index] = { ...newPlayer[index], match_role: matchRole };
      setSelectedTeamB(newPlayer);
    }
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filtered = filterTeamAPlayer?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(query.toLowerCase())
      )
    );
    setTeamAPlayer(filtered);
  };
  const handleSearchChangeB = (event) => {
    const query = event.target.value;
    setSearchQueryB(query);

    const filtered = filterTeamBPlayer?.filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(query.toLowerCase())
      )
    );
    setTeamBPlayer(filtered);
  };

  return (
    <div className="info-main">
      <div className="row">
        <div className="col-md-9 col-12 align-self-center">
          <div className="info-nav">
            <ul className="nav">
              <li className="border-end">
                <Link to={`/update-venue/${data?.venue_id}`}>
                  <span class="mdi mdi-pencil"></span> Venue
                </Link>
              </li>
              <li className="border-end">
                <Link
                  to={`/add-squad/${data?.team_a_id}/${data?.team_b_id}/${data?.match_id}`}
                >
                  <span class="mdi mdi-pencil"></span> Squad
                </Link>
              </li>
              <li className="border-end">
                <button onClick={onRefreshSquad} className={squadClass}>
                  <span class="mdi mdi-cached"></span> Squads
                </button>
              </li>
              <li className="border-end">
                <button className={scoreClass} onClick={onRefreshScore}>
                  <span class="mdi mdi-cached"></span> Scorecards
                </button>
              </li>
              <li>{/* <a href="javascript:void(0)">Select All</a> */}</li>
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="btn-update text-end">
            <button className="btn btn-primary" onClick={onSubmitSquad}>
              Update Squad
            </button>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="general-info info-outer">
            <div className="info-head">
              <h4>
                <b>Generl Info</b>
              </h4>
            </div>
            <div className="info-body">
              <form onSubmit={updateMatchUmpire}>
                <ul>
                  <li className="d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <span>Series</span>
                    </div>
                    <div className="info-end d-flex align-items-center justify-content-between">
                      <Link
                        to={`/series-view/${data?.series_id}`}
                        className="d-flex justify-content-between align-items-center w-100"
                      >
                        {data?.series}{" "}
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <span>Date</span>
                    </div>
                    <div className="info-end">
                      <p className="m-0">
                        {moment(data?.match_date).format("D MMMM YYYY")}
                      </p>
                    </div>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <span>Time</span>
                    </div>
                    <div className="info-end">
                      <p className="m-0">
                        {moment(data?.match_date).format(" h:mm:ss A")}
                      </p>
                    </div>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <span>Venue</span>
                    </div>
                    <div className="info-end d-flex align-items-center justify-content-between">
                      <Link
                        to={`/update-venue/${data?.venue_id}`}
                        className="d-flex justify-content-between align-items-center w-100"
                      >
                        {data?.venue}
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <span>Toss</span>
                    </div>
                    <div className="info-end">
                      <p className="m-0">{data?.toss}</p>
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <label>Umpire</label>
                    </div>
                    <div className="info-end">
                      <input
                        placeholder="Umpire Name"
                        className="form-control"
                        name="umpire"
                        onChange={handleChange}
                        type="text"
                        value={umpire}
                      />
                    </div>
                  </li>

                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <label>Third Umpire</label>
                    </div>
                    <div className="info-end">
                      <input
                        placeholder="Third Umpire Name"
                        className="form-control"
                        name="thirdUmpire"
                        onChange={handleChange}
                        type="text"
                        value={thirdUmpire}
                      />
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <label>Referee</label>
                    </div>
                    <div className="info-end">
                      <input
                        placeholder="Referee Name"
                        className="form-control"
                        name="referee"
                        onChange={handleChange}
                        type="text"
                        value={referee}
                      />
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <label>Leg Umpire</label>
                    </div>
                    <div className="info-end">
                      <input
                        placeholder="Leg Umpire"
                        className="form-control"
                        name="legUmpire"
                        onChange={handleChange}
                        type="text"
                        value={legUmpire}
                      />
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <label>Pitch Report</label>
                    </div>
                    <div className="info-end">
                      <input
                        placeholder="Pitch Report"
                        className="form-control"
                        name="pitchReport"
                        onChange={handleChange}
                        type="text"
                        value={pitchReport}
                      />
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="info-start">
                      <label>Pitch Behaviour</label>
                    </div>
                    <div className="info-end">
                      <input
                        placeholder="Pitch Behaviour"
                        className="form-control"
                        name="pitchBehaviour"
                        onChange={handleChange}
                        type="text"
                        value={pitchBehaviour}
                      />
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="batting-check">
                      <h5>Fav Team :</h5>
                      <div className="ms-3">
                        <input
                          type="checkbox"
                          id={data?.team_a_id}
                          name="favTeam"
                          onChange={handleChange}
                          checked={data?.team_a_short == favTeam}
                          value={data?.team_a_short}
                        />
                        <label
                          className="form-check-label mx-2"
                          htmlFor={data?.team_a_id}
                        >
                          {data?.team_a_short}
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id={data?.team_b_id}
                          name="favTeam"
                          onChange={handleChange}
                          checked={data?.team_b_short == favTeam}
                          value={data?.team_b_short}
                        />
                        <label
                          className="form-check-label mx-2 "
                          htmlFor={data?.team_b_id}
                        >
                          {data?.team_b_short}
                        </label>
                      </div>
                    </div>
                  </li>
                  <li className="head-to">
                    <div className="head-heading">
                      <p>Head to Head</p>
                    </div>
                    <div className="head-to-head d-flex flex-wrap align-items-center">
                      <div className="head-inner head-left-main d-flex align-items-center">
                        <div className="head-left text-center">
                          <div className="team-flag">
                            <img
                              src="/assets/images/team-flag1.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="team-name">
                            <p className="m-0">{data?.team_a_short}</p>
                          </div>
                        </div>
                        <div className="head-right">
                          <div className="team-score">
                            <input
                              type="number"
                              name="team_a_win_count"
                              placeholder="0"
                              className="form-control"
                              value={headToHead.team_a_win_count}
                              onChange={handleHeadToHead}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="head-inner head-right-main d-flex align-items-center">
                        <div className="head-left text-center">
                          <div className="team-flag">
                            <img
                              src="/assets/images/team-flag1.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                          <div className="team-name">
                            <p className="m-0">{data?.team_b_short}</p>
                          </div>
                        </div>
                        <div className="head-right">
                          <div className="team-score">
                            <input
                              type="number"
                              name="team_b_win_count"
                              placeholder="0"
                              className="form-control"
                              value={headToHead.team_b_win_count}
                              onChange={handleHeadToHead}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Total Matches</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="total_matches"
                          placeholder="Total Matches"
                          className="form-control"
                          value={headToHead.total_matches}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Win Bat First</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="win_bat_first"
                          placeholder="win_bat_first"
                          className="form-control"
                          value={headToHead.win_bat_first}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Win Bowl First</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="win_bowl_first"
                          placeholder="win_bowl_first"
                          className="form-control"
                          value={headToHead.win_bowl_first}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label className="form-label">Average First Inning Score</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="average_first_inning_score"
                          placeholder="average_first_inning_score"
                          className="form-control"
                          value={headToHead.average_first_inning_score}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Average Second Inning Score</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="average_second_inning_score"
                          placeholder="average_second_inning_score"
                          className="form-control"
                          value={headToHead.average_second_inning_score}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Highest Total</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="highest_total"
                          placeholder="highest_total"
                          className="form-control"
                          value={headToHead.highest_total}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Lowest Total</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="lowest_total"
                          placeholder="lowest_total"
                          className="form-control"
                          value={headToHead.lowest_total}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Highest Cheased</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="highest_cheased"
                          placeholder="highest_cheased"
                          className="form-control"
                          value={headToHead.highest_cheased}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Lowest Defend</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="lowest_defend"
                          placeholder="lowest_defend"
                          className="form-control"
                          value={headToHead.lowest_defend}
                          onChange={handleHeadToHead}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="team-form w-100">
                      <p className="m-0">Team Form</p>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>{data?.team_a_short}</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="teamAForms"
                          placeholder="L.W.W.W"
                          className="form-control"
                          value={teamAForms}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>{data?.team_b_short}</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="teamBForms"
                          placeholder="L.W.W.W"
                          className="form-control"
                          value={teamBForms}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="team-form w-100">
                      <p className="m-0">Pace Spin</p>
                    </div>

                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Pace Wkt</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="pace_wkt"
                          placeholder="pace_wkt"
                          className="form-control"
                          value={paceSpin.pace_wkt}
                          onChange={handlePaceSpin}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Spin Wkt</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="spin_wkt"
                          placeholder="spin_wkt"
                          className="form-control"
                          value={paceSpin.spin_wkt}
                          onChange={handlePaceSpin}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Pace Percent</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="pace_percent"
                          placeholder="pace_percent"
                          className="form-control"
                          value={paceSpin.pace_percent}
                          onChange={handlePaceSpin}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Spin Percent</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="number"
                          name="spin_percent"
                          placeholder="spin_percent"
                          className="form-control"
                          value={paceSpin.spin_percent}
                          onChange={handlePaceSpin}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="form-list d-flex flex-wrap align-items-center">
                    <div className="team-form w-100">
                      <p className="m-0">Venue Weather</p>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Temp C</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="temp_c"
                          placeholder="temp_c"
                          className="form-control"
                          value={venueWeather.temp_c}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Temp F</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="temp_f"
                          placeholder="temp_f"
                          className="form-control"
                          value={venueWeather.temp_f}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Weather</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="weather"
                          placeholder="weather"
                          className="form-control"
                          value={venueWeather.weather}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Weather Icon</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="weather_icon"
                          placeholder="weather_icon"
                          className="form-control"
                          value={venueWeather.weather_icon}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Wind mph</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="wind_mph"
                          placeholder="wind_mph"
                          className="form-control"
                          value={venueWeather.wind_mph}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Wind kph</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="wind_kph"
                          placeholder="wind_kph"
                          className="form-control"
                          value={venueWeather.wind_kph}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Wind dir</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="wind_dir"
                          placeholder="wind_dir"
                          className="form-control"
                          value={venueWeather.wind_dir}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Humidity</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="humidity"
                          placeholder="humidity"
                          className="form-control"
                          value={venueWeather.humidity}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                    <div className="team-form-inner d-flex flex-wrap align-items-center">
                      <div className="info-start">
                        <label>Cloud</label>
                      </div>
                      <div className="info-end">
                        <input
                          type="text"
                          name="cloud"
                          placeholder="cloud"
                          className="form-control"
                          value={venueWeather.cloud}
                          onChange={handleWeather}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="add-btn">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={updateMatchUmpire}
                      // disabled={data?.match_status == "Finished"}
                    >
                      Add
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="info-outer">
            <div className="info-head d-flex flex-wrap align-items-center">
              <div className="squad-left">
                <img src={squad?.team_a?.flag} alt="" className="img-fluid" />
              </div>
              <div className="squad-right">
                <p className="d-flex align-items-center justify-content-between">
                  <b>{squad?.team_a?.name} Squads</b>
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </p>
              </div>
            </div>
            <div className="info-body">
              <div className="playing-player d-flex flex-wrap align-items-center justify-content-between">
                <div className="playing-left">
                  <p className="m-0">
                    <b>Playing XI</b>
                  </p>
                </div>
                <div className="playing-right">
                  <p
                    className="m-0"
                    style={{
                      backgroundColor:
                        selectedTeamAIds.length >= 11 ? "green" : "red",
                    }}
                  >
                    {selectedTeamAIds.length}
                  </p>
                </div>
              </div>
              <ul>
                {selectedTeamA?.map((list, index) => {
                  const isChecked = selectedTeamAIds.includes(list.id);
                  return (
                    <>
                      <li className="d-flex flex-wrap align-items-center">
                        <div className="play-left">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            onChange={() => {
                              handlecheckedTeamA(list.id);
                            }}
                            checked={isChecked}
                          />
                        </div>
                        <div className="play-right d-flex flex-wrap align-items-center justify-content-between">
                          <div className="player-name">
                            <p className="m-0">{list.player_name}</p>
                          </div>
                          <div className="player-type d-flex align-items-center">
                            <div
                              className="bat-bowl"
                              style={{
                                backgroundColor: list.match_role ? "red" : "",
                                color: list.match_role ? "white" : "",
                              }}
                            >
                              {list.match_role ? (
                                <span>{list.match_role}</span>
                              ) : (
                                <span className="mdi mdi-cricket"></span>
                              )}
                            </div>
                            <div className="player-option">
                              <ul className="nav">
                                <li className="nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img
                                      src="/assets/images/vertical-dots.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <Link
                                        to={`/update-player/${list.player_id}`}
                                        className="dropdown-item"
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/player-news/${list.player_id}`}
                                        className="dropdown-item"
                                      >
                                        Add News
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          updateMatchRole(
                                            index,
                                            list.id,
                                            "C",
                                            "A"
                                          );
                                        }}
                                      >
                                        Captain
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          updateMatchRole(
                                            index,
                                            list.id,
                                            "VC",
                                            "A"
                                          );
                                        }}
                                      >
                                        Vice Captain
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          updateMatchRole(
                                            index,
                                            list.id,
                                            "WK",
                                            "A"
                                          );
                                        }}
                                      >
                                        Wicket Keeper
                                      </button>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
                <div className="player-bench">
                  <h2>On Bench</h2>
                  <input
                    type="text"
                    name="searchQuery"
                    placeholder="Search for Player"
                    className="form-control"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                {teamAPlayer?.map((list) => {
                  const isChecked = selectedTeamAIds.includes(list.id);
                  return (
                    <>
                      <li className="d-flex flex-wrap align-items-center">
                        <div className="play-left">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            onChange={() => {
                              handlecheckedTeamA(list.id);
                            }}
                            checked={isChecked}
                          />
                        </div>
                        <div className="play-right d-flex flex-wrap align-items-center justify-content-between">
                          <div className="player-name">
                            <p className="m-0">{list.player_name}</p>
                          </div>
                          <div className="player-type d-flex align-items-center">
                            <div className="bat-bowl">
                              <span className="mdi mdi-cricket"></span>
                            </div>
                            <div className="player-option">
                              <ul className="nav">
                                <li className="nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img
                                      src="/assets/images/vertical-dots.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <Link
                                        to={`/update-player/${list.player_id}`}
                                        className="dropdown-item"
                                      >
                                        Edit
                                      </Link>
                                      <li>
                                        <Link
                                          to={`/player-news/${list.player_id}`}
                                          className="dropdown-item"
                                        >
                                          Add News
                                        </Link>
                                      </li>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="info-outer">
            <div className="info-head d-flex flex-wrap align-items-center">
              <div className="squad-left">
                <img src={squad?.team_a?.flag} alt="" className="img-fluid" />
              </div>
              <div className="squad-right">
                <p className="d-flex align-items-center justify-content-between">
                  <b>{squad?.team_b?.name} Squads</b>
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </p>
              </div>
            </div>
            <div className="info-body">
              <div className="playing-player d-flex flex-wrap align-items-center justify-content-between">
                <div className="playing-left">
                  <p className="m-0">
                    <b>Playing XI</b>
                  </p>
                </div>
                <div className="playing-right">
                  <p
                    className="m-0"
                    style={{
                      backgroundColor:
                        selectedTeamBIds.length >= 11 ? "green" : "red",
                    }}
                  >
                    {selectedTeamBIds.length}
                  </p>
                </div>
              </div>
              <ul>
                {selectedTeamB?.map((list, index) => {
                  const isChecked = selectedTeamBIds.includes(list.id);
                  return (
                    <>
                      <li className="d-flex flex-wrap align-items-center">
                        <div className="play-left">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            onChange={() => {
                              handlecheckedTeamB(list.id);
                            }}
                            checked={isChecked}
                          />
                        </div>
                        <div className="play-right d-flex flex-wrap align-items-center justify-content-between">
                          <div className="player-name">
                            <p className="m-0">{list.player_name}</p>
                          </div>
                          <div className="player-type d-flex align-items-center">
                            <div
                              className="bat-bowl"
                              style={{
                                backgroundColor: list.match_role ? "red" : "",
                                color: list.match_role ? "white" : "",
                              }}
                            >
                              {list.match_role ? (
                                <span>{list.match_role}</span>
                              ) : (
                                <span className="mdi mdi-cricket"></span>
                              )}
                            </div>
                            <div className="player-option">
                              <ul className="nav">
                                <li className="nav-item dropdown">
                                  <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img
                                      src="/assets/images/vertical-dots.png"
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <Link
                                        to={`/update-player/${list.player_id}`}
                                        className="dropdown-item"
                                      >
                                        Edit
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to={`/player-news/${list.player_id}`}
                                        className="dropdown-item"
                                      >
                                        Add News
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          updateMatchRole(
                                            index,
                                            list.id,
                                            "C",
                                            "B"
                                          );
                                        }}
                                      >
                                        Captain
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          updateMatchRole(
                                            index,
                                            list.id,
                                            "VC",
                                            "B"
                                          );
                                        }}
                                      >
                                        Vice Captain
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          updateMatchRole(
                                            index,
                                            list.id,
                                            "WK",
                                            "B"
                                          );
                                        }}
                                      >
                                        Wicket Keeper
                                      </button>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
                <div className="player-bench">
                  <h2>On Bench</h2>
                  <input
                    type="text"
                    name="searchQuery"
                    placeholder="Search for Player"
                    className="form-control"
                    value={searchQueryB}
                    onChange={handleSearchChangeB}
                  />
                </div>
                {teamBPlayer?.map((list) => {
                  const isChecked = selectedTeamBIds.includes(list.id);
                  return (
                    <li className="d-flex flex-wrap align-items-center">
                      <div className="play-left">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          onChange={() => {
                            handlecheckedTeamB(list.id);
                          }}
                          checked={isChecked}
                        />
                      </div>
                      <div className="play-right d-flex flex-wrap align-items-center justify-content-between">
                        <div className="player-name">
                          <p className="m-0">{list.player_name}</p>
                        </div>
                        <div className="player-type d-flex align-items-center">
                          <div className="bat-bowl">
                            <span className="mdi mdi-cricket"></span>
                          </div>
                          <div className="player-option">
                            <ul className="nav">
                              <li className="nav-item dropdown">
                                <a
                                  className="nav-link dropdown-toggle"
                                  href="#"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img
                                    src="/assets/images/vertical-dots.png"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </a>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link
                                      to={`/update-player/${list.player_id}`}
                                      className="dropdown-item"
                                    >
                                      Edit
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to={`/player-news/${list.player_id}`}
                                      className="dropdown-item"
                                    >
                                      Add News
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
