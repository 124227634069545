import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetSeriesByIdMutation } from "../../../../redux/seriesApi";
import Header from "../../../widgets/Header";
import Navbar from "../../../widgets/Navbar";
import Fixture from "./Fixture";
import Info from "./Info";
import News from "./News";
import Point from "./Point";
import Teams from "./Teams";
import Venue from "./Venue";

function SeriesDetail() {
  const { series_id } = useParams();
  const [getSeriesById, { data }] = useGetSeriesByIdMutation();
  const [tab, setTab] = useState("team");

  useEffect(() => {
    if(series_id){
      getSeriesById({series_id})
    }
  }, [series_id])
  
  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="series-main">
            <div className="series-head d-flex flex-wrap justify-content-between">
              <div className="head-left">
                <h3 className="mb-0">{data?.series}</h3>
              </div>
              <div className="head-right">
                <ul className="nav">
                  <li>
                    <span
                      className={`${tab === "team" ? "active" : ""}`}
                      onClick={() => {
                        setTab("team");
                      }}
                    >
                      Teams
                    </span>
                  </li>
                  <li>
                    <span
                      className={`${tab === "fixture" ? "active" : ""}`}
                      onClick={() => {
                        setTab("fixture");
                      }}
                    >
                      Fixtures
                    </span>
                  </li>
                  <li>
                    <span
                      className={`${tab === "point" ? "active" : ""}`}
                      onClick={() => {
                        setTab("point");
                      }}
                    >
                      Points Table
                    </span>
                  </li>
                  <li>
                    <span
                      className={`${tab === "venue" ? "active" : ""}`}
                      onClick={() => {
                        setTab("venue");
                      }}
                    >
                      Venue
                    </span>
                  </li>
                  <li>
                    <span
                      className={`${tab === "news" ? "active" : ""}`}
                      onClick={() => {
                        setTab("news");
                      }}
                    >
                      News
                    </span>
                  </li>
                  <li>
                    <span
                      className={`${tab === "info" ? "active" : ""}`}
                      onClick={() => {
                        setTab("info");
                      }}
                    >
                      Info
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="series-squad-detail">
              {tab==='team'?<Teams series_id={series_id}/>:""}
              {tab==='fixture'?<Fixture series_id={series_id}/>:""}
              {tab==='point'?<Point series_id={series_id}/>:""}
              {tab==='news'?<News series_id={series_id}/>:""}
              {tab==='venue'?<Venue series_id={series_id}/>:""}
              {tab==='info'?<Info seriesData={data}/>:""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeriesDetail;
