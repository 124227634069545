import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  useGetPlayerByIdQuery,
  useUpdatePlayerMutation,
} from "../../../redux/playerApi";
import { toast } from "react-toastify";
import { ImageValid } from "../../validations/ImageValid";
import { InputValid } from "../../validations/InputValid";
import {
  GenderEnum,
  PlayerRoleEnum,
  PlayerMatchTypeEnum,
  PlayerStyleBatingEnum,
  PlayerStyleBowlingEnum,
  StatusEnum,
} from "../../constant/enum";
import { useGetTeamQuery } from "../../../redux/teamApi";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useNavigate, useParams } from "react-router-dom";
function UpdatePlayer() {
  const { player_id } = useParams();
  const navigation = useNavigate();
  const { data: teamList } = useGetTeamQuery({});
  const { data, refetch } = useGetPlayerByIdQuery({ player_id });
  const [updatePlayer] = useUpdatePlayerMutation();
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [shortName, setShortName] = useState("");
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState("");
  const [born, setBorn] = useState("");
  const [height, setHeight] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [nationality, setNationality] = useState("");
  const [teams, setTeams] = useState([]);
  const [styleBating, setStyleBating] = useState("--");
  const [styleBowling, setStyleBowling] = useState("--");
  const [status, setStatus] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState("");
  const [bio, setBio] = useState("");
  const [batingCareerField, setBatingCareerField] = useState({
    match_type: "",
    matches: 0,
    inning: 0,
    runs: 0,
    balls: 0,
    hundreds: 0,
    fifty: 0,
    high_score: 0,
    sr: 0,
    avg: 0,
    fours: 0,
    sixes: 0,
    not_out: 0,
    ducks: 0,
    two_hundreds: 0,
    three_hundreds: 0,
    four_hundreds: 0,
  });
  const [bowlingCareerField, setBowlingCareerField] = useState({
    match_type: "",
    matches: 0,
    inning: 0,
    runs: 0,
    balls: 0,
    wkts: 0,
    economy: 0,
    avg: 0,
    sr: 0,
    bbi: 0,
    four_wkt: 0,
    five_wkt: 0,
    ten_wkt: 0,
  });
  const [batingCareer, setBatingCareer] = useState([]);
  const [bowlingCareer, setBowlingCareer] = useState([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data) {
      setFirstName(data?.first_name ? data?.first_name : "");
      setLastName(data?.last_name ? data?.last_name : "");
      setNationality(data?.nationality ? data?.nationality : "");
      setGender(data?.gender ? data?.gender : "");
      setName(data.player_name);
      setRole(data.player_role);
      setBorn(data.born);
      setHeight(data.height);
      setBirthPlace(data.birth_place);
      setStyleBating(data.style_bating);
      setStyleBowling(data.style_bowling);
      setImageUrl(data.image);
      setTeams(data?.teams ? data?.teams.split(",") : []);
      setStatus(data?.status ? data?.status : "");
      setShortName(data?.short_name ? data?.short_name : "");
      setBio(data?.bio ? data?.bio : "");
      setBatingCareer(
        data?.batting_career ? JSON.parse(data?.batting_career) : []
      );
      setBowlingCareer(
        data?.bowling_career ? JSON.parse(data?.bowling_career) : []
      );
    }
  }, [data]);

  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "teamimage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
      const err = InputValid(name, value);
      setFirstNameErr(err);
    }
    if (name === "lastName") {
      setLastName(value);
      // const err = InputValid(name, value);
      // setLastNameErr(err);
    }
    if (name === "gender") {
      setGender(value);
      const err = InputValid(name, value);
      setGenderErr(err);
    }
    if (name === "name") {
      setName(value);
      // const err = InputValid(name, value);
      // setNameErr(err);
    }
    if (name === "role") {
      setRole(value);
      const err = InputValid(name, value);
      setRoleErr(err);
    }
    if (name === "born") {
      setBorn(value);
    }
    if (name === "height") {
      setHeight(value);
    }
    if (name === "birthPlace") {
      setBirthPlace(value);
    }
    if (name === "shortName") {
      setShortName(value);
    }
    if (name === "styleBating") {
      setStyleBating(value);
    }
    if (name === "styleBowling") {
      setStyleBowling(value);
    }
    if (name === "nationality") {
      setNationality(value);
    }
    if (name === "status") {
      setStatus(value);
    }
    if (name === "teams") {
      setTeams([...teams, value]);
    }
  };
  
  const onsubmit = async (e) => {
    e.preventDefault();
    const checkFirstName = InputValid("first_name", firstName);
    // const checkLastName = InputValid("last_name", lastName);
    const checkGender = InputValid("gender", gender);
    const checkName = InputValid("name", name);
    const checkRole = InputValid("role", role);
    if (checkFirstName) {
      setFirstNameErr(checkFirstName);
      return false;
    }
    // if (checkLastName) {
    //   setLastNameErr(checkLastName);
    //   return false;
    // }
    if (checkGender) {
      setGenderErr(checkGender);
      return false;
    }
    // if (checkName) {
    //   setNameErr(checkName);
    //   return false;
    // }
    if (checkRole) {
      setRoleErr(checkRole);
      return false;
    }
    // if (image) {
    //   let checkImage = ImageValid("image", image);
    //   if (checkImage) {
    //     setImageErr(checkImage);
    //     return false;
    //   }
    // }
    let teamArray = teams.join(",");
    const formdata = new FormData();
    formdata.append("player_id", player_id);
    formdata.append("first_name", firstName);
    formdata.append("last_name", lastName);
    formdata.append("short_name", shortName);
    formdata.append("gender", gender);
    formdata.append("player_name", name);
    formdata.append("player_role", role);
    formdata.append("born", born);
    formdata.append("height", height);
    formdata.append("birth_place", birthPlace);
    formdata.append("teams", teamArray);
    formdata.append("style_bating", styleBating);
    formdata.append("style_bowling", styleBowling);
    formdata.append("player_image", image);
    formdata.append("nationality", nationality);
    formdata.append("status", status);
    formdata.append("bio", bio);
    formdata.append("batting_career", JSON.stringify(batingCareer));
    formdata.append("bowling_career", JSON.stringify(bowlingCareer));
    updatePlayer(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        navigation("/players");
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  const updatedTeamList =
    teams.length > 0
      ? teamList?.filter((team) => !teams?.includes(team.name))
      : teamList;
  const removeTeam = (i) => {
    let newteamValues = [...teams];
    newteamValues.splice(i, 1);
    setTeams(newteamValues);
  };

  const handleBatting = (e) => {
    const { name, value } = e.target;
    setBatingCareerField({ ...batingCareerField, [name]: value });
  };

  const handleBowling = (e) => {
    const { name, value } = e.target;
    setBowlingCareerField({ ...bowlingCareerField, [name]: value });
  };

  const addBatting = () => {
    let checkFilter = batingCareer.filter((list) => {
      return list.match_type == batingCareerField.match_type;
    });
    if (checkFilter.length > 0) {
      toast.error("This type already exist");
      return false;
    }
    setBatingCareer([...batingCareer, batingCareerField]);
    setBatingCareerField({
      match_type: "",
      matches: 0,
      inning: 0,
      runs: 0,
      balls: 0,
      hundreds: 0,
      fifty: 0,
      high_score: 0,
      sr: 0,
      avg: 0,
      fours: 0,
      sixes: 0,
      not_out: 0,
      ducks: 0,
      two_hundreds: 0,
      three_hundreds: 0,
      four_hundreds: 0,
    });
  };

  const addBowling = () => {
    let checkFilter = bowlingCareer.filter((list) => {
      return list.match_type == bowlingCareerField.match_type;
    });
    if (checkFilter.length > 0) {
      toast.error("This type already exist");
      return false;
    }
    setBowlingCareer([...bowlingCareer, bowlingCareerField]);
    setBowlingCareerField({
      match_type: "",
      matches: 0,
      inning: 0,
      runs: 0,
      balls: 0,
      wkts: 0,
      economy: 0,
      avg: 0,
      sr: 0,
      bbi: 0,
      four_wkt: 0,
      five_wkt: 0,
      ten_wkt: 0,
    });
  };

  const editBatting = (index) => {
    setBatingCareerField(batingCareer[index]);
    let newFormValues = [...batingCareer];
    newFormValues.splice(index, 1);
    setBatingCareer(newFormValues);
  };

  const editBowling = (index) => {
    setBowlingCareerField(bowlingCareer[index]);
    let newFormValues = [...bowlingCareer];
    newFormValues.splice(index, 1);
    setBowlingCareer(newFormValues);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Update Player</b>
              </h2>
            </div>
            <div className="card mt-3">
              <div className="product-list-outer card-body">
                <Form onSubmit={onsubmit}>
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {" "}
                          First Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          name="firstName"
                          onChange={handleChange}
                          type="text"
                          value={firstName}
                        ></Form.Control>
                        <span style={{ color: "red" }}>{firstNameErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {" "}
                          Last Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          name="lastName"
                          onChange={handleChange}
                          type="text"
                          value={lastName}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Gender <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="gender"
                          onChange={handleChange}
                          value={gender}
                        >
                          <option value="">Please select Gender</option>
                          {GenderEnum?.map((list) => {
                            return (
                              <option value={list.value}>{list.key}</option>
                            );
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{genderErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {" "}
                          Player Name
                        </Form.Label>
                        <Form.Control
                          name="name"
                          onChange={handleChange}
                          type="text"
                          value={name}
                        ></Form.Control>
                        <span style={{ color: "red" }}>{nameErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Short Name</Form.Label>
                        <Form.Control
                          name="shortName"
                          onChange={handleChange}
                          type="text"
                          value={shortName}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Player role <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="role"
                          onChange={handleChange}
                          value={role}
                        >
                          <option value="">Please select Role</option>
                          {PlayerRoleEnum?.map((list) => {
                            return (
                              <option value={list.value}>{list.key}</option>
                            );
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{roleErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-2">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Born </Form.Label>
                        <Form.Control
                          name="born"
                          onChange={handleChange}
                          type="date"
                          value={born}
                          max={new Date().toISOString().split("T")[0]}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Nationality </Form.Label>
                        <Form.Control
                          name="nationality"
                          onChange={handleChange}
                          type="text"
                          value={nationality}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Height </Form.Label>
                        <Form.Control
                          name="height"
                          onChange={handleChange}
                          type="text"
                          value={height}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Birth Place</Form.Label>
                        <Form.Control
                          name="birthPlace"
                          onChange={handleChange}
                          type="text"
                          value={birthPlace}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          onChange={handleChange}
                          value={status}
                        >
                          <option value="">Please select Status</option>
                          {StatusEnum?.map((list) => {
                            return (
                              <option value={list.value}>{list.key}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Style Bating</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="styleBating"
                          onChange={handleChange}
                          value={styleBating}
                        >
                          <option value="--">Please select Style</option>
                          {PlayerStyleBatingEnum?.map((list) => {
                            return (
                              <option value={list.value}>{list.key}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Style Bowling</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="styleBowling"
                          onChange={handleChange}
                          value={styleBowling}
                        >
                          <option value="--">Please select Style</option>
                          {PlayerStyleBowlingEnum?.map((list) => {
                            return (
                              <option value={list.value}>{list.key}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Teams </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="teams"
                          onChange={handleChange}
                        >
                          <option value="">Please select Team</option>
                          {updatedTeamList?.map((list) => {
                            return (
                              <option value={list.name} key={list.team_id}>
                                {list.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <div className="mt-2">
                          {teams?.map((list, i) => {
                            return (
                              <span className="mul-team">
                                {list}{" "}
                                <span
                                  class="mdi mdi-close ms-2 mul-icon"
                                  onClick={() => {
                                    removeTeam(i);
                                  }}
                                ></span>
                              </span>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Image
                        </Form.Label>

                        <Form.Control
                          type="file"
                          name="teamimage"
                          onChange={handlechangeimage}
                        />
                        <span style={{ color: "red" }}>{imageErr}</span>
                        <img
                          style={{ width: "100px" }}
                          src={imageUrl}
                          className="img-fluid mt-2"
                          alt=""
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Bio</Form.Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={bio}
                          config={{
                            toolbar: [
                              "heading",
                              "|",
                              "bold",
                              "italic",
                              "link",
                              "bulletedList",
                              "numberedList",
                              "blockQuote",
                            ],
                            heading: {
                              options: [
                                {
                                  model: "paragraph",
                                  title: "Paragraph",
                                  class: "ck-heading_paragraph",
                                },
                                {
                                  model: "heading1",
                                  view: "h1",
                                  title: "Heading 1",
                                  class: "ck-heading_heading1",
                                },
                                {
                                  model: "heading2",
                                  view: "h2",
                                  title: "Heading 2",
                                  class: "ck-heading_heading2",
                                },
                                {
                                  model: "heading3",
                                  view: "h3",
                                  title: "Heading 3",
                                  class: "ck-heading_heading3",
                                },
                              ],
                            },
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setBio(data);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-12">
                      <h4>Batting Career</h4>
                      {batingCareer.length < 4 && (
                        <div className="row">
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Match Type{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="match_type"
                                onChange={handleBatting}
                                value={batingCareerField.match_type}
                              >
                                <option value="">Please select Type</option>
                                {PlayerMatchTypeEnum?.map((list) => {
                                  return (
                                    <option value={list.value}>
                                      {list.key}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Matches</Form.Label>
                              <Form.Control
                                type="number"
                                name="matches"
                                onChange={handleBatting}
                                value={batingCareerField.matches}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Inning</Form.Label>
                              <Form.Control
                                type="number"
                                name="inning"
                                onChange={handleBatting}
                                value={batingCareerField.inning}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Runs</Form.Label>
                              <Form.Control
                                type="number"
                                name="runs"
                                onChange={handleBatting}
                                value={batingCareerField.runs}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Balls</Form.Label>
                              <Form.Control
                                type="number"
                                name="balls"
                                onChange={handleBatting}
                                value={batingCareerField.balls}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Hundreds</Form.Label>
                              <Form.Control
                                type="number"
                                name="hundreds"
                                onChange={handleBatting}
                                value={batingCareerField.hundreds}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Fifty</Form.Label>
                              <Form.Control
                                type="number"
                                name="fifty"
                                onChange={handleBatting}
                                value={batingCareerField.fifty}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>High Score</Form.Label>
                              <Form.Control
                                type="number"
                                name="high_score"
                                onChange={handleBatting}
                                value={batingCareerField.high_score}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Sr</Form.Label>
                              <Form.Control
                                type="number"
                                name="sr"
                                onChange={handleBatting}
                                value={batingCareerField.sr}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Avg</Form.Label>
                              <Form.Control
                                type="number"
                                name="avg"
                                onChange={handleBatting}
                                value={batingCareerField.avg}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Fours</Form.Label>
                              <Form.Control
                                type="number"
                                name="fours"
                                onChange={handleBatting}
                                value={batingCareerField.fours}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Sixes</Form.Label>
                              <Form.Control
                                type="number"
                                name="sixes"
                                onChange={handleBatting}
                                value={batingCareerField.sixes}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Not Out</Form.Label>
                              <Form.Control
                                type="number"
                                name="not_out"
                                onChange={handleBatting}
                                value={batingCareerField.not_out}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Ducks</Form.Label>
                              <Form.Control
                                type="number"
                                name="ducks"
                                onChange={handleBatting}
                                value={batingCareerField.ducks}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Two Hundreds</Form.Label>
                              <Form.Control
                                type="number"
                                name="two_hundreds"
                                onChange={handleBatting}
                                value={batingCareerField.two_hundreds}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Three Hundreds</Form.Label>
                              <Form.Control
                                type="number"
                                name="three_hundreds"
                                onChange={handleBatting}
                                value={batingCareerField.three_hundreds}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Four Hundreds</Form.Label>
                              <Form.Control
                                type="number"
                                name="four_hundreds"
                                onChange={handleBatting}
                                value={batingCareerField.four_hundreds}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Button onClick={addBatting}>Add</Button>
                          </div>
                        </div>
                      )}
                      {batingCareer.length > 0 && (
                        <div>
                          <table className="w-100 mb-4">
                            <thead>
                              <tr>
                                <th>match_type</th>
                                <th>matches</th>
                                <th>inning</th>
                                <th>runs</th>
                                <th>balls</th>
                                <th>hundreds</th>
                                <th>fifty</th>
                                <th>high_score</th>
                                <th>sr</th>
                                <th>avg</th>
                                <th>fours</th>
                                <th>sixes</th>
                                <th>not_out</th>
                                <th>ducks</th>
                                <th>two_hundreds</th>
                                <th>three_hundreds</th>
                                <th>four_hundreds</th>
                              </tr>
                            </thead>
                            <tbody>
                              {batingCareer?.map((list, index) => {
                                return (
                                  <tr>
                                    <td>{list.match_type}</td>
                                    <td>{list.matches}</td>
                                    <td>{list.inning}</td>
                                    <td>{list.runs}</td>
                                    <td>{list.balls}</td>
                                    <td>{list.hundreds}</td>
                                    <td>{list.fifty}</td>
                                    <td>{list.high_score}</td>
                                    <td>{list.sr}</td>
                                    <td>{list.avg}</td>
                                    <td>{list.fours}</td>
                                    <td>{list.sixes}</td>
                                    <td>{list.not_out}</td>
                                    <td>{list.ducks}</td>
                                    <td>{list.two_hundreds}</td>
                                    <td>{list.three_hundreds}</td>
                                    <td>{list.four_hundreds}</td>
                                    <td>
                                      <Button
                                        onClick={() => {
                                          editBatting(index);
                                        }}
                                      >
                                        <span className="mdi mdi-square-edit-outline"></span>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                      <h4>Bowling Career</h4>
                      {bowlingCareer.length < 4 && (
                        <div className="row">
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Match Type{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="match_type"
                                onChange={handleBowling}
                                value={bowlingCareerField.match_type}
                              >
                                <option value="">Please select Type</option>
                                {PlayerMatchTypeEnum?.map((list) => {
                                  return (
                                    <option value={list.value}>
                                      {list.key}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Matches</Form.Label>
                              <Form.Control
                                type="number"
                                name="matches"
                                onChange={handleBowling}
                                value={bowlingCareerField.matches}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Inning</Form.Label>
                              <Form.Control
                                type="number"
                                name="inning"
                                onChange={handleBowling}
                                value={bowlingCareerField.inning}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Runs</Form.Label>
                              <Form.Control
                                type="number"
                                name="runs"
                                onChange={handleBowling}
                                value={bowlingCareerField.runs}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Balls</Form.Label>
                              <Form.Control
                                type="number"
                                name="balls"
                                onChange={handleBowling}
                                value={bowlingCareerField.balls}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Wkts</Form.Label>
                              <Form.Control
                                type="number"
                                name="wkts"
                                onChange={handleBowling}
                                value={bowlingCareerField.wkts}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Economy</Form.Label>
                              <Form.Control
                                type="number"
                                name="economy"
                                onChange={handleBowling}
                                value={bowlingCareerField.economy}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Avg</Form.Label>
                              <Form.Control
                                type="number"
                                name="avg"
                                onChange={handleBowling}
                                value={bowlingCareerField.avg}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Sr</Form.Label>
                              <Form.Control
                                type="number"
                                name="sr"
                                onChange={handleBowling}
                                value={bowlingCareerField.sr}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Bbi</Form.Label>
                              <Form.Control
                                type="number"
                                name="bbi"
                                onChange={handleBowling}
                                value={bowlingCareerField.bbi}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Four Wkt</Form.Label>
                              <Form.Control
                                type="number"
                                name="four_wkt"
                                onChange={handleBowling}
                                value={bowlingCareerField.four_wkt}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Five Wkt</Form.Label>
                              <Form.Control
                                type="number"
                                name="five_wkt"
                                onChange={handleBowling}
                                value={bowlingCareerField.five_wkt}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Ten Wkt</Form.Label>
                              <Form.Control
                                type="number"
                                name="ten_wkt"
                                onChange={handleBowling}
                                value={bowlingCareerField.ten_wkt}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-2">
                            <Button onClick={addBowling}>Add</Button>
                          </div>
                        </div>
                      )}
                      {bowlingCareer.length > 0 && (
                        <div>
                          <table className="w-100 mb-2">
                            <thead>
                              <tr>
                                <th>match_type</th>
                                <th>matches</th>
                                <th>inning</th>
                                <th>runs</th>
                                <th>balls</th>
                                <th>wkts</th>
                                <th>economy</th>
                                <th>avg</th>
                                <th>sr</th>
                                <th>bbi</th>
                                <th>four_wkt</th>
                                <th>five_wkt</th>
                                <th>ten_wkt</th>
                              </tr>
                            </thead>
                            <tbody>
                              {bowlingCareer?.map((list, index) => {
                                return (
                                  <tr>
                                    <td>{list.match_type}</td>
                                    <td>{list.matches}</td>
                                    <td>{list.inning}</td>
                                    <td>{list.runs}</td>
                                    <td>{list.balls}</td>
                                    <td>{list.wkts}</td>
                                    <td>{list.economy}</td>
                                    <td>{list.avg}</td>
                                    <td>{list.sr}</td>
                                    <td>{list.bbi}</td>
                                    <td>{list.four_wkt}</td>
                                    <td>{list.five_wkt}</td>
                                    <td>{list.ten_wkt}</td>
                                    <td>
                                      <Button
                                        onClick={() => {
                                          editBowling(index);
                                        }}
                                      >
                                        <span className="mdi mdi-square-edit-outline"></span>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>

                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePlayer;
