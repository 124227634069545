import React from "react";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useGetDashboardQuery } from "../../redux/dashboardApi";

function Dashboard() {
  const { data, isLoading } = useGetDashboardQuery();

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid p-0 mt-2">
            <div className="row dashbord_man">
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/venue">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Venue</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.venue
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-map-marker"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/series">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Series</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.series
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-trophy-outline"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/players">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Players</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.player
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-account-group-outline"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/teams">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Teams</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.team
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <i class="mdi mdi-account-group-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/matches">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Match</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.match
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-cricket"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/news">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total News</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.news
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span class="mdi mdi-newspaper"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Dashboard;
