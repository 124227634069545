import { ScoreCardByInningApi, scoreCardByMatchApi } from "../components/constant/Api";
import { myApi } from "./api";

export const scorecardApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getScoreCardInning: builder.mutation({
      query: (post) => ({
        url: ScoreCardByInningApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? "" : "";
      },
    }),
    getScoreCardMatch: builder.mutation({
      query: (post) => ({
        url: scoreCardByMatchApi,
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
    }),
  }),
});

export const { useGetScoreCardInningMutation,useGetScoreCardMatchMutation } = scorecardApi;
