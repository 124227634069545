import { getSeriesApi, getSeriesByIdApi, insertSeriesApi, updateSeriesApi, updateTredingSeriesApi } from "../components/constant/Api";
import { myApi } from "./api";

export const seriesApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getSeries: builder.query({
      query: () => ({
        url: getSeriesApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      providesTags: (_) => ["series"],
    }),
    setSeriesName: builder.mutation({
      query: (post) => ({
        url: insertSeriesApi,
        method: "POST",
        body: post,
        formData: true,
      }),

      invalidatesTags: (_) => ["series"],
    }),
    getSeriesById: builder.mutation({
      query: (post) => ({
        url: getSeriesByIdApi,
        method: "POST",
        body:post
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data[0] ?? "" : "";
      },
    }),
    updateSeries: builder.mutation({
      query: (post) => ({
        url: updateSeriesApi,
        method: "PUT",
        body: post,
      }),
    }),
    updateTredingSeries:builder.mutation({
      query:(data)=>({
          url:updateTredingSeriesApi,
          method:"POST",
          body:data
      }),
      invalidatesTags: (_) => ["series"]
    })
  }),
});

export const { useSetSeriesNameMutation, useGetSeriesQuery,useGetSeriesByIdMutation, useUpdateSeriesMutation,useUpdateTredingSeriesMutation } = seriesApi;
