import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { toast } from "react-toastify";
import { useGetVenueByseriesMutation } from "../../../redux/venueApi";
import { useGetTeamByseriesMutation } from "../../../redux/teamApi";
import { matchStatusEnum, matchTypeEnum } from "../../constant/enum";
import { InputValid } from "../../validations/InputValid";
import { useUpdateMatchesMutation } from "../../../redux/matchApi";

function UpdateMatchModal(props) {
  const [getVenueByseries, { data: venueList }] = useGetVenueByseriesMutation();
  const [getTeamByseries, { data: teamList }] = useGetTeamByseriesMutation({});
  const [updateMatches] = useUpdateMatchesMutation();
  const [matchId, setMatchId] = useState("");
  const [venueId, setVenueId] = useState("");
  const [venueIdErr, setVenueIdErr] = useState("");
  const [matchType, setMatchType] = useState("");
  const [matchTypeErr, setMatchTypeErr] = useState("");
  const [matchStatus, setMatchStatus] = useState("Upcoming");
  const [matchStatusErr, setMatchStatusErr] = useState("");
  const [matchDate, setMatchDate] = useState(
    moment(
      new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    ).format("YYYY-MM-DDTHH:mm")
  );
  const [match, setMatch] = useState("");
  const [matchErr, setMatchErr] = useState("");
  const [teamAId, setTeamAId] = useState("");
  const [teamAIdErr, setTeamAIdErr] = useState("");
  const [teamBId, setTeamBId] = useState("");
  const [teamBIdErr, setTeamBIdErr] = useState("");
  const [umpire, setUmpire] = useState("");
  const [thirdUmpire, setThirdUmpire] = useState("");
  const [referee, setReferee] = useState("");
  useEffect(() => {
    if (props.series_id) {
      const data = {
        series_id: props.series_id,
      };
      getVenueByseries(data);
      getTeamByseries(data);
    }
  }, [props.series_id]);

  useEffect(() => {
    if (props.currentRecord) {
      let currentRecord = props.currentRecord;
      setVenueId(currentRecord?.venue_id);
      setMatchType(currentRecord?.match_type);
      setMatchStatus(currentRecord.match_status);
      setMatchDate(currentRecord.match_date);
      setMatch(currentRecord.matchs);
      setTeamAId(currentRecord.team_a_id);
      setTeamBId(currentRecord?.team_b_id);
      setMatchId(currentRecord?.match_id);
      setUmpire(currentRecord?.umpire);
      setThirdUmpire(currentRecord?.third_umpire);
      setReferee(currentRecord?.referee);
    }
  }, [props]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "venueId") {
      setVenueId(value);
      if (value === "") {
        setVenueIdErr("This field is required");
        return false;
      }
      setVenueIdErr("");
    }
    if (name === "matchType") {
      setMatchType(value);
      const err = InputValid(name, value);
      setMatchTypeErr(err);
    }
    if (name === "matchStatus") {
      setMatchStatus(value);
      const err = InputValid(name, value);
      setMatchStatusErr(err);
    }
    if (name === "match") {
      setMatch(value);
      const err = InputValid(name, value);
      setMatchErr(err);
    }
    if (name === "umpire") {
      setUmpire(value);
    }
    if (name === "referee") {
      setReferee(value);
    }
    if (name === "thirdUmpire") {
      setThirdUmpire(value);
    }
    if (name === "matchDate") {
      setMatchDate(value);
    }
    if (name === "teamAId") {
      setTeamAId(value);
      if (value === "") {
        setTeamAIdErr("This field is required");
        return false;
      }
      setTeamAIdErr("");
    }
    if (name === "teamBId") {
      setTeamBId(value);
      if (value === "") {
        setTeamBIdErr("This field is required");
        return false;
      }
      setTeamBIdErr("");
    }
  };

  const handleClose = () => {
    setVenueId("");
    setVenueIdErr("");
    setMatchType("");
    setMatchTypeErr("");
    setMatchStatus("");
    setMatchStatusErr("");
    setMatchDate(new Date());
    setMatch("");
    setMatchErr("");
    setTeamAId("");
    setTeamAIdErr("");
    setTeamBId("");
    setTeamBIdErr("");
    setReferee("");
    setUmpire("");
    setThirdUmpire("");
    props.setUpdateModal(false);
  };

  const updatedTeamBList = teamAId
    ? teamList?.filter((team) => {
        return team.team_id !== parseInt(teamAId);
      })
    : teamList;
  const today = new Date();
  const minDate = today.toISOString().slice(0, 16);

  const onsubmit = (e) => {
    e.preventDefault();
    const checkTeamA = InputValid("teamAId", teamAId);
    const checkTeamB = InputValid("teamBId", teamBId);
    const checkVenue = InputValid("venueId", venueId);
    const checkType = InputValid("matchType", matchType);
    const checkMatch = InputValid("match", match);
    const checkStatus = InputValid("matchStatus", matchStatus);
    if (checkTeamA) {
      setTeamAIdErr(checkTeamA);
      return false;
    }
    if (checkTeamB) {
      setTeamBIdErr(checkTeamB);
      return false;
    }
    if (checkVenue) {
      setVenueIdErr(checkVenue);
      return false;
    }
    if (checkType) {
      setMatchTypeErr(checkType);
      return false;
    }
    if (checkStatus) {
      setMatchStatusErr(checkStatus);
      return false;
    }
    if (checkMatch) {
      setMatchErr(checkMatch);
      return false;
    }

    const data = {
      series_id: props.series_id,
      venue_id: venueId,
      match_type: matchType,
      match_status: matchStatus,
      match_date: moment(matchDate).format("YYYY-MM-DDTHH:mm"),
      match: match,
      match_id: matchId,
      team_a_id: teamAId,
      team_b_id: teamBId,
      umpire:umpire,
      referee:referee,
      third_umpire:thirdUmpire
    };
    updateMatches(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        props.getMatch();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <div>
      <Modal show={props.updateModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update Match</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-md-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Team A</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="teamAId"
                    onChange={handleChange}
                    value={teamAId}
                  >
                    <option value="">Please select Team</option>
                    {teamList?.map((list) => {
                      return <option value={list.team_id}>{list.name}</option>;
                    })}
                  </Form.Select>
                  <span style={{ color: "red" }}>{teamAIdErr}</span>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Team B</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="teamBId"
                    onChange={handleChange}
                    value={teamBId}
                  >
                    <option value="">Please select Team</option>
                    {updatedTeamBList?.map((list) => {
                      return <option value={list.team_id}>{list.name}</option>;
                    })}
                  </Form.Select>
                  <span style={{ color: "red" }}>{teamBIdErr}</span>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Venue</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="venueId"
                    onChange={handleChange}
                    value={venueId}
                  >
                    <option value="">Please select Venue</option>
                    {venueList?.map((list) => {
                      return (
                        <option value={list.venue_id}>{list.venue_name}</option>
                      );
                    })}
                  </Form.Select>
                  <span style={{ color: "red" }}>{venueIdErr}</span>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Match Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="matchType"
                    onChange={handleChange}
                    value={matchType}
                  >
                    <option value="">Please select Type</option>
                    {matchTypeEnum?.map((list) => {
                      return <option value={list.value}>{list.key}</option>;
                    })}
                  </Form.Select>
                  <span style={{ color: "red" }}>{matchTypeErr}</span>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="matchStatus"
                    onChange={handleChange}
                    value={matchStatus}
                  >
                    <option value="">Please select Status</option>
                    {matchStatusEnum?.map((list) => {
                      return <option value={list.value}>{list.key}</option>;
                    })}
                  </Form.Select>
                  <span style={{ color: "red" }}>{matchStatusErr}</span>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label> Match </Form.Label>
                  <Form.Control
                    name="match"
                    onChange={handleChange}
                    type="text"
                    value={match}
                  ></Form.Control>
                  <span style={{ color: "red" }}>{matchErr}</span>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Match Date/Time</Form.Label>
                  <Form.Control
                    name="matchDate"
                    onChange={handleChange}
                    type="datetime-local"
                    value={matchDate}
                    min={minDate}
                  ></Form.Control>
                </Form.Group>
              </div>
              <div className="col-sm-6 col-md-4 col-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label> Umpire </Form.Label>
                  <Form.Control
                    name="umpire"
                    onChange={handleChange}
                    type="text"
                    value={umpire}
                  ></Form.Control>
                </Form.Group>
              </div>
              <div className="col-sm-6 col-md-4 col-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label> Third Umpire </Form.Label>
                  <Form.Control
                    name="thirdUmpire"
                    onChange={handleChange}
                    type="text"
                    value={thirdUmpire}
                  ></Form.Control>
                </Form.Group>
              </div>
              <div className="col-sm-6 col-md-4 col-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Referee </Form.Label>
                  <Form.Control
                    name="referee"
                    onChange={handleChange}
                    type="text"
                    value={referee}
                  ></Form.Control>
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdateMatchModal;
