export const matchStatusEnum = [
  {
    key: "Upcoming",
    value: "Upcoming",
  },
  {
    key: "Live",
    value: "Live",
  },
  {
    key: "Finished",
    value: "Finished",
  },
];

export const matchTypeEnum = [
  {
    key: "T20",
    value: "T20",
  },
  {
    key: "T10",
    value: "T10",
  },
  {
    key: "ODI",
    value: "ODI",
  },
  {
    key: "Test",
    value: "Test",
  },
];

export const PlayerMatchTypeEnum = [
  {
    key: "T20",
    value: "T20",
  },
  {
    key: "ODI",
    value: "ODI",
  },
  {
    key: "Test",
    value: "Test",
  },
  {
    key: "IPL",
    value: "IPL",
  },
];

export const PlayerRoleEnum = [
  {
    key: "Batting",
    value: "Batting",
  },
  {
    key: "Bowling",
    value: "Bowling",
  },
  {
    key: "All Rounder",
    value: "All Rounder",
  },
  {
    key: "WK-Batsman",
    value: "WK-Batsman",
  },
  {
    key: "Wicketkeeper",
    value: "Wicketkeeper",
  },
  {
    key: "Bowling Allrounder",
    value: "Bowling Allrounder",
  },
  {
    key: "Batting Allrounder",
    value: "Batting Allrounder",
  },
];

export const PlayerStyleBatingEnum = [
  {
    key: "Right Handed Bat",
    value: "Right Handed Bat",
  },
  {
    key: "Left Handed Bat",
    value: "Left Handed Bat",
  },
];
export const PlayerStyleBowlingEnum = [
  {
    key: "Right-arm fast",
    value: "Right-arm fast",
  },
  {
    key: "Right-arm offbreak",
    value: "Right-arm offbreak",
  },
  {
    key: "Right-arm fast-medium",
    value: "Right-arm fast-medium",
  },
  {
    key: "Right-arm medium",
    value: "Right-arm medium",
  },
  {
    key: "Right-arm offbreak",
    value: "Right-arm offbreak",
  },
  {
    key: "Right-arm legbreak",
    value: "Right-arm legbreak",
  },
  {
    key: "Left-arm fast",
    value: "Left-arm fast",
  },
  {
    key: "Left-arm medium",
    value: "Left-arm medium",
  },
  {
    key: "Left-arm chinaman",
    value: "Left-arm chinaman",
  },
  {
    key: "Left-arm fast-medium",
    value: "Left-arm fast-medium",
  },
  {
    key: "Left-arm orthodox",
    value: "Left-arm orthodox",
  },
  {
    key:"Slow Left-arm Orthodox",
    value:"Slow Left-arm Orthodox"
  },
  {
    key: "Left-arm legbreak",
    value: "Left-arm legbreak",
  },
  {
    key:"Left-arm wrist spin",
    value:"Left-arm wrist spin"
  }
];

export const SeriesTypeEnum = [
  {
    key: "League",
    value: "League",
  },
  {
    key: "International",
    value: "International",
  },
  {
    key: "Domestic",
    value: "Domestic",
  },
  {
    key: "Women",
    value: "Women",
  },
];

export const GenderEnum = [
  {
    key: "Men",
    value: "Men",
  },
  {
    key: "Women",
    value: "Women",
  },
];

export const StatusEnum = [
  {
    key: "Active",
    value: "Active",
  },
  {
    key: "Retired",
    value: "Retired",
  },
];

export const TypeArray = ["International", "Domestic", "League"];
export const FormatArray = ["T20", "T10", "ODI", "Test"];

export const TestInningEnum = [
  {
    key: "inning 1",
    value: "1",
  },
  {
    key: "inning 2",
    value: "2",
  },
  {
    key: "inning 3",
    value: "3",
  },
  {
    key: "inning 4",
    value: "4",
  },
];

export const InningEnum = [
  {
    key: "inning 1",
    value: "1",
  },
  {
    key: "inning 2",
    value: "2",
  },
];

export const FirstCircleEnum = [
  {
    key: "Ball",
    value: "Ball",
  },
  {
    key: "0",
    value: "0",
  },
  {
    key: "1",
    value: "1",
  },
  {
    key: "2",
    value: "2",
  },
  {
    key: "3",
    value: "3",
  },
  {
    key: "4",
    value: "4",
  },
  {
    key: "5",
    value: "5",
  },
  {
    key: "6",
    value: "6",
  },
  {
    key: "7",
    value: "7",
  },
  {
    key: "Over",
    value: "Over",
  },
  {
    key: "Catch Out",
    value: "Catch Out",
  },
  {
    key: "Third Umpire",
    value: "Third Umpire",
  },
  {
    key: "Wicket",
    value: "Wicket",
  },
  {
    key: "Drinks Break",
    value: "Drinks Break",
  },
  {
    key: "Inning Break",
    value: "Inning Break",
  },
  {
    key: "Player In",
    value: "Player In",
  },
];

export const ShortKeyEnum=[
  {
    key:"B",
    value:"Ball chalu Ball"
  },
  {
    key:"C",
    value:"Confirming"
  },
  {
    key:"DB",
    value:" Drinks Break"
  },
  {
    key:"IB",
    value:" Innings Break"
  },
  {
    key:"W",
    value:"Wicket"
  },
  {
    key:"WD",
    value:"Wide Ball"
  },
  {
    key:"NB",
    value:"No Ball"
  },
  {
    key:"WDW",
    value:"Wide + Wicket"
  },
  {
    key:"ROC",
    value:"Run out check"
  },
  {
    key:"BRC",
    value:"Boundary Check"
  },
  {
    key:"NBB",
    value:"No ball + By run"
  },
  {
    key:"NBB4",
    value:"No ball + by 4 run"
  },
  {
    key:"R",
    value:"Bowler stop"
  },
  {
    key:"CD",
    value:"Catch Drop"
  },
  {
    key:"CO",
    value:"Catch Out"
  },
  {
    key:"CAC",
    value:"Catch Check"
  },
  {
    key:"ST",
    value:"Stump Check"
  },
  {
    key:"O",
    value:"Over"
  },
  {
    key:"LB",
    value:"Leg By run"
  },
  {
    key:"BY",
    value:"By run"
  },
  {
    key:"D",
    value:"Drizzling"
  },
  {
    key:"CC",
    value:"Covers coming"
  },
  {
    key:"BTR",
    value:"Batting review"
  },
  {
    key:"BLR",
    value:"Bowling review"
  },
  {
    key:"U",
    value:"Third Umpire"
  },
  {
    key:"P",
    value:"Player Injured"
  },
  {
    key:"BI",
    value:"Batsman Injured"
  },
  {
    key:"BOI",
    value:"Bowler Injured"
  },
  {
    key:"E",
    value:"Players Entering"
  },
  {
    key:"F",
    value:"Fast Bowler"
  },
  {
    key:"S",
    value:"Spinner"
  },
  {
    key:"NBL",
    value:"No ball + Leg by run"
  },
  {
    key:"HTB",
    value:"Hattrick-Ball"
  },
  {
    key:"HB",
    value:"Hattrick ball notification generate"
  },
  {
    key:"MO",
    value:"Maiden Over"
  },
  {
    key:"WMO",
    value:"Wicket + Maiden Over"
  },
  {
    key:"0/1",
    value:"Overthrow run"
  },
  {
    key:"RO",
    value:"Run Out"
  },
]

export const WicketEnum=['Wicket','Wicket + Maiden Over','Wide + Wicket']
export const CRWEnum=['Catch Out','Run out check']
export const recentBall=['Wicket','Wide + Wicket','Catch Out','Wide Ball','Run out check','No ball + by 4 run','No Ball','No ball + Leg by run','No ball + By run']

export const commetaryWicketEnumm=[
  {
    key:"W",
    value:"Wicket"
  },
  {
    key:"RO",
    value:"Run Out"
  },
  {
    key:"CO",
    value:"Catch Out"
  },
  {
    key:"ROC",
    value:"Run out check"
  },
]

export const commetaryRunEnumm=[
  {
    key:"NBB4",
    value:"No ball + by 4 run",
    run:5
  },
  {
    key:"NB",
    value:"No Ball",
    run:1
  },
  {
    key:"WD",
    value:"Wide Ball",
    run:1
  },
  {
    key:"NBB",
    value:"No ball + By run",
    run:2
  }
]