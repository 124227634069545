import React, { useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetCountryQuery } from "../../../redux/countryApi";
import AddCountryModal from "../../partial/country/AddCountryModal";
import UpdateCountryModal from "../../partial/country/UpdateCountryModal";

function Country() {
  const { data: record } = useGetCountryQuery();
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "index",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "iso3",
      text: "Short Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              onClick={() => {
                editCountry(record);
              }}
              className="btn btn-primary"
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const editCountry = (record) => {
    setUpdateModal(true);
    setCurrentRecord(record);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="section-heading">
            <h2>
              <b>Country List</b>
            </h2>
            <div className="buttonDirection">
              <button
                className="btn btn-primary"
                onClick={() => setAddModal(true)}
              >
                Add Country
              </button>
            </div>
          </div>
          <div>
            <div className="product-list-outer all-matches">
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
      <AddCountryModal setAddModal={setAddModal} addModal={addModal} />
      <UpdateCountryModal
        setUpdateModal={setUpdateModal}
        updateModal={updateModal}
        currentRecord={currentRecord}
      />
    </div>
  );
}

export default Country;
