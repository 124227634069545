import { baseUrl } from "./BaseUrl";

export const loginApi = baseUrl + "/login";
export const changePasswordApi = baseUrl + "/change-password";

// ==================== Dashboard =============================
export const getDashboardApi = "/dashboard";

//===================== Country ========================
export const getCountryApi="/country-list";
export const addCountryapi="/insert-country"
export const updateCountryApi="/update-country"

//======================== Teams ==========================
export const getTeamApi = "/get-team";
export const insertTeamApi = "/insert-team";
export const getTeamSeriesIdApi="/get-team-by-seriesid"
export const updateTeamApi="/update-team"
export const getteamByIdApi="/get-team-by-id"

// ======================== Players ==========================
export const getPlayerApi = "/get-player";
export const insertPlayerApi = "/insert-player";
export const getPlayerTeamIdApi = "/get-player-by-teamid";
export const insertPlayerTeamApi = "/insert-team-player";
export const getPlayerByIdApi="/get-player-by-id"
export const updatePlayerApi="/update-player"
export const getMatchSquadApi="/match-squad"
export const getMatchSquadXIApi="/match-squad-xi"
export const addMatchSquadApi="/add-match-squad"
export const getMatchSquadIdApi="/match-squad-ids"
export const getMatchSquadXIIdApi="/match-squad-xi-ids"
export const setMatchSquadXIApi="/set-squad-xi"
export const setPlayerMatchRoleapi="/set-match-role"
export const getSeriesPlayerApi="/get-series-player"

//========================== Venue =========================
export const insertVenueApi = "/insert-venue";
export const getVenueApi = "/get-venue";
export const getVenueSeriesIdApi="/get-venue-by-seriesid"
export const getVenueByIdApi="/get-venue-id"
export const updateVenueApi="/update-venue"

// ======================== series ===========================
export const insertSeriesApi = "/insert-series";
export const getSeriesApi = "/get-series";
export const getSeriesByIdApi="/get-series-by-id"
export const updateSeriesApi="/update-series"
export const updateTredingSeriesApi="/update-treding-series"

//========================== Points =======================
export const insertPointApi = "/insert-point";
export const getPointSeriesIdApi = "/get-point-by-series-id";
export const updatePointApi = "/update-point";

// ======================== Match ====================
export const insertMatchApi = "/insert-match";
export const getMatchApi = "/get-match";
export const getUpcomingMatchApi = "/get-upcoming-match";
export const getLiveMatchApi = "/get-live-match";
export const getRecentMatchApi = "/get-recent-match";
export const getMatchSeriesIdApi="/get-match-seriesid"
export const updateMatchApi="/update-match"
export const getMatchByIdApi="/get-match-by-id"
export const updateResultApi="/update-result"
export const updateRateApi="/update-rate"
export const updateCommentApi="/update-comment"
export const updateYetToBatApi="/update-yetToBat"
export const updateMoveLiveApi="/update-move-live"
export const getSeriesMatchApi="/get-series-match"
export const addSessionApi="/add-session"

//============================= News =================
export const insertNewsApi="/insert-news"
export const getNewsApi="/get-news"
export const getNewsSeriesIdApi="/get-news-seriesid"
export const getNewsByIdApi="/get-news-by-id"
export const getNewsPlayerIdApi="/get-news-playerid"

//========================== Toss ================
export const updateTossApi="/update-toss"

//========================== Umpire ================
export const updateUmpireApi="/update-umpire"

//========================= Status ================
export const updateStatusApi="/update-status"

//==================== Score card =============
export const ScoreCardByInningApi="/score-card-by-inning"
export const scoreCardByMatchApi="/score-card-by-match"

//==================== Odd history =================
export const insertOddHistoryApi="/add-odd-history"

//==================== Umpire ==================
export const getUmpireApi="/get-umpire"
export const insertUmpireApi="/insert-umpire"
export const updateUmpireDetail="/update-umpire-detail"

//=================== Over run ====================
export const addOverRunApi="/insert-over-run"
export const getOverRunApi="/get-over-run"

//================ Commentary ==================
export const getCommentaryApi="/get-commentary" 
export const updatecommentaryApi="/update-commentary"