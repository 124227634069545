export const NewsValid = (name, value) => {
  let error = "";
  if (name === "title") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    if (value.match(/^\s/)) {
      error = `Please enter valid ${name} without first space`;
      return error;
    }
    return error;
  }
  if (name === "description") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    if (value.match(/^\s/)) {
      error = `Please enter valid ${name} without first space`;
      return error;
    }
    return error;
  }
  if (name === "pubDate") {
    if (value === "") {
      error = "This field is required";
      return error;
    }
    return error;
  }
};
