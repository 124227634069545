import React,{useState} from "react";
import Modal from "react-bootstrap/Modal";

function BowlingPlayerModal(props) {
   const [bowler, setbowler] = useState([])
  const handleClose=()=>{
    props.setAddModal(false)
  }
  const handlechecked=(name)=>{
    setbowler([...bowler,{player_name:name}])
  }
  const handlesubmit=(e)=>{
    e.preventDefault()
    props.setWicketBowler(bowler)
    setbowler([])
    props.setAddModal(false)
  }
  return (
    <div>
       <Modal show={props.addModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Bowling Player</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <form onSubmit={handlesubmit}>
              {props?.bowlingPlayer?.map((list) => {
                
                return (
                  <div className="mb-3 col-md-6" key={list.id}>
                    <input
                      type="checkbox"
                      onChange={() => {
                        handlechecked(list.player_name);
                      }}
                      className="me-3"
                    />
                    <img
                      src={list.image}
                      alt=""
                      className="img-size img-round"
                    />
                    {list.player_name}
                    <span className="ms-2">({list.player_role})</span>
                  </div>
                );
              })}
              <button type="submit">Submit</button>
              </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default BowlingPlayerModal
