import React, { useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetUmpireQuery } from "../../../redux/umpireApi";
import AddUmpireModal from "../../partial/umpire/AddUmpireModal";
import UpdateUmpireModal from "../../partial/umpire/UpdateUmpireModal";

function Umpire() {
  const { data } = useGetUmpireQuery();
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },

    {
      key: "dob",
      text: "DOB",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "country",
      text: "Country",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "total_matches",
      text: "Total Matches",
      className: "Name",
      align: "left",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              className="btn btn-primary"
              onClick={() => {
                editRecord(record);
              }}
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const editRecord = (record) => {
    setCurrentRecord(record);
    setUpdateModal(true);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Umpire List</b>
              </h2>
            </div>
            <div>
              <div className="product-list-outer all-matches">
                <div className="buttonDirection">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setAddModal(true);
                    }}
                  >
                    Add Umpire
                  </button>
                </div>
                <ReactDatatable
                  config={config}
                  records={data}
                  columns={columns}
                />
              </div>
              <AddUmpireModal addModal={addModal} setAddModal={setAddModal} />
              <UpdateUmpireModal
                updateModal={updateModal}
                setUpdateModal={setUpdateModal}
                currentRecord={currentRecord}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Umpire;
