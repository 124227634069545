import React, { useEffect, useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetMatchSeriesIdMutation } from "../../../redux/matchApi";
import { Link, useParams } from "react-router-dom";
import AddMatchModal from "../../partial/match/AddMatchModal";
import UpdateMatchModal from "../../partial/match/UpdateMatchModal";

function SeriesMatch() {
  const { series_id } = useParams();
  const [getMatchSeriesId, { data }] = useGetMatchSeriesIdMutation();
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  useEffect(() => {
    if (series_id) {
      getMatch();
    }
  }, [series_id]);

  const getMatch = () => {
    const data = {
      series_id,
    };
    getMatchSeriesId(data);
  };

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "series",
      text: "Series",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_type",
      text: "Match Type",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "team_a_short",
      text: "Team A",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "team_b_short",
      text: "Team B",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_date",
      text: "Match Date",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "match_status",
      text: "Match Status",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "add_squad",
      text: "Add Squad",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {record.match_status === "Upcoming" ? (
              <Link
                to={`/add-squad/${record.team_a_id}/${record.team_b_id}/${record.match_id}`}
                className="btn btn-primary"
              >
                Add
              </Link>
            ) : (
              "--"
            )}
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              onClick={() => {
                editFee(record);
              }}
              className="btn btn-primary"
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const editFee = (record) => {
    setUpdateModal(true);
    setCurrentRecord(record);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="section-heading">
            <h2>
              <b>Match List</b>
            </h2>
          </div>
          <div >
            <div className="product-list-outer all-matches">
              <div className="buttonDirection">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setAddModal(true);
                  }}
                >
                  Add Match
                </button>
              </div>
              <ReactDatatable
                config={config}
                records={data}
                columns={columns}
              />
            </div>
          </div>
          <AddMatchModal
            addModal={addModal}
            setAddModal={setAddModal}
            series_id={series_id}
            getMatch={getMatch}
          />
          <UpdateMatchModal
            updateModal={updateModal}
            setUpdateModal={setUpdateModal}
            currentRecord={currentRecord}
            series_id={series_id}
            getMatch={getMatch}
          />
        </div>
      </div>
    </div>
  );
}

export default SeriesMatch;
