import React, { useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { useGetTeamQuery } from "../../../redux/teamApi";
import { Link } from "react-router-dom";

function Team() {
  const { data } = useGetTeamQuery({});

  const columns = [
    {
      key: "categoriesId",
      text: "Sr.No.",
      className: "catergory",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "short_name",
      text: "Short Name",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "flag",
      text: "Flag",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <img src={record.flag} alt="team-flag" className="img-fluid img-size" />;
      },
    },
    {
      key: "t_win",
      text: "Tournament Win",
      className: "Name",
      align: "left",
      sortable: true,
    },
    {
      key: "player_list",
      text: "Player List",
      className: "Name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Link
            to={`/team-player/${record.team_id}/${record.name}`}
            className="btn btn-primary"
          >
            List
          </Link>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <Link to={`/update-team/${record.team_id}`}  className="btn btn-primary">
              <span className="mdi mdi-square-edit-outline"></span>
            </Link>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 20,
    length_menu: [20, 30, 50, 100],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Team List</b>
              </h2>
            </div>
            <div>
              <div className="product-list-outer all-matches">
                <div className="buttonDirection">
                  <Link to="/add-team" className="btn btn-primary">
                    Add Team
                  </Link>
                </div>
                <ReactDatatable
                  config={config}
                  records={data}
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
