import React, { useState, useEffect } from "react";
import { useGetVenueQuery } from "../../../redux/venueApi";
import { GenderEnum, matchTypeEnum, SeriesTypeEnum } from "../../constant/enum";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from "../../widgets/Header";
import Navbar from "../../widgets/Navbar";
import { toast } from "react-toastify";
import { ImageValid } from "../../validations/ImageValid";
import { InputValid } from "../../validations/InputValid";
import { useGetTeamQuery } from "../../../redux/teamApi";
import {
  useGetSeriesByIdMutation,
  useUpdateSeriesMutation,
} from "../../../redux/seriesApi";
import { useNavigate, useParams } from "react-router-dom";

function UpdateSeries() {
  const { series_id } = useParams();
  const navigation = useNavigate();
  const [getSeriesById, { data }] = useGetSeriesByIdMutation();
  const { data: teamList } = useGetTeamQuery({});
  const { data: venueList } = useGetVenueQuery({});
  const [updateSeries] = useUpdateSeriesMutation();
  const [series, setSeries] = useState("");
  const [seriesErr, setSeriesErr] = useState("");
  const [seriesType, setSeriesType] = useState("");
  const [seriesTypeErr, setSeriesTypeErr] = useState("");
  const [gender, setGender] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [shortName, setShortName] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [venueIds, setVenueIds] = useState([]);
  const [teamIds, setteamIds] = useState([]);
  const [venues, setVenues] = useState([]);
  const [teams, setTeams] = useState([]);
  const [formats, setFormats] = useState([]);
  const [formatsErr, setFormatsErr] = useState("");
  const [venueIdsErr, setVenueIdsErr] = useState("");
  const [teamIdsErr, setteamIdsErr] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (series_id) {
      getSeriesById({ series_id });
    }
  }, [series_id]);

  useEffect(() => {
    if (data) {
      setSeries(data?.series);
      setSeriesType(data?.series_type);
      setGender(data?.gender?data?.gender:"");
      setEndDate(data?.end_date);
      setStartDate(data?.start_date);
      setFormats(data?.format?JSON.parse(data?.format):[]);
      setShortName(data?.short_name ? data?.short_name : "");
      setVenueIds(JSON.parse(data?.venue_ids));
      setteamIds(JSON.parse(data?.team_ids));
      setImageUrl(data?.image);
      const venueNames = venueList?.filter((obj) => JSON.parse(data?.venue_ids).includes(obj.venue_id))
        .map((obj) => obj.venue_name);
      setVenues(venueNames);
      const teamNames = teamList?.filter((obj) => JSON.parse(data?.team_ids).includes(obj.team_id))
        .map((obj) => obj.short_name);
      setVenues(venueNames);
      setTeams(teamNames);
    }
  }, [data]);

  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "teamimage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "series") {
      setSeries(value);
      const err = InputValid(name, value);
      setSeriesErr(err);
    }
    if (name === "seriesType") {
      setSeriesType(value);
      const err = InputValid(name, value);
      setSeriesTypeErr(err);
    }
    if (name === "gender") {
      setGender(value);
      const err = InputValid(name, value);
      setGenderErr(err);
    }
    if (name === "formats") {
      setFormats([...formats, value]);
      setFormatsErr("");
    }
    if (name === "teams") {
      setteamIds([...teamIds, parseInt(value)]);
      let teamData = teamList?.filter((team) => {
        return team.team_id === parseInt(value);
      });
      setTeams([...teams, teamData[0].short_name]);
      setteamIdsErr("");
    }
    if (name === "venues") {
      setVenueIds([...venueIds, parseInt(value)]);
      let venueData = venueList?.filter((venue) => {
        return venue.venue_id === parseInt(value);
      });
      setVenues([...venues, venueData[0].venue_name]);
      setVenueIdsErr("");
    }
    if (name === "startDate") {
      setStartDate(new Date(value).toISOString().split("T")[0]);
      setEndDate(new Date(value).toISOString().split("T")[0]);
    }
    if (name === "endDate") {
      setEndDate(new Date(value).toISOString().split("T")[0]);
    }
  };

  const updatedTeamList =
    teamIds.length > 0
      ? teamList?.filter((team) => !teamIds.includes(team.team_id))
      : teamList;
  const removeTeam = (i) => {
    let newteamValues = [...teamIds];
    let newteamName = [...teams];
    newteamValues.splice(i, 1);
    newteamName.splice(i, 1);
    setteamIds(newteamValues);
    setTeams(newteamName);
  };

  const removeFormat = (i) => {
    let newformatValues = [...formats];
    newformatValues.splice(i, 1);
    setFormats(newformatValues);
  };
  const updatedVenueList =
    venueIds.length > 0
      ? venueList?.filter((venue) => !venueIds.includes(venue.venue_id))
      : venueList;
  const updatedFormatList =
    formats?.length > 0
      ? matchTypeEnum?.filter((format) => !formats.includes(format.key))
      : matchTypeEnum;
  const removeVenue = (i) => {
    let newVenueValues = [...venueIds];
    let newVenueName = [...venues];
    newVenueValues.splice(i, 1);
    newVenueName.splice(i, 1);
    setVenueIds(newVenueValues);
    setVenues(newVenueName);
  };

  const onsubmit = (e) => {
    e.preventDefault();
    const checkSeries = InputValid("series", series);
    const checkSeriesType = InputValid("seriesType", seriesType);
    const checkGender = InputValid("gender", gender);
    if (checkSeries) {
      setSeriesErr(checkSeries);
      return false;
    }
    if (checkSeriesType) {
      setSeriesTypeErr(checkSeriesType);
      return false;
    }
    if (formats.length == 0) {
      setFormatsErr("Please select the format");
      return false;
    }
    if (checkGender) {
      setGenderErr(checkGender);
      return false;
    }
    if (teamIds.length < 2) {
      setteamIdsErr("Please select at least two team");
      return false;
    }
    // if (venueIds.length < 1) {
    //   setVenueIdsErr("Please select at least one venue");
    //   return false;
    // }
    // if (image) {
    //   let checkImage = ImageValid("image", image);
    //   if (checkImage) {
    //     setImageErr(checkImage);
    //     return false;
    //   }
    // }
    const formdata = new FormData();
    formdata.append("series", series);
    formdata.append("short_name", shortName);
    formdata.append("series_type", seriesType);
    formdata.append("start_date", startDate);
    formdata.append("end_date", endDate);
    formdata.append("venue_ids", JSON.stringify(venueIds));
    formdata.append("team_ids", JSON.stringify(teamIds));
    formdata.append("gender", gender);
    formdata.append("format", JSON.stringify(formats));
    formdata.append("series_image", image);
    formdata.append("series_id", series_id);
    updateSeries(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        if(localStorage.getItem('path')){
          navigation(localStorage.getItem('path'));
          localStorage.removeItem('path')
        }
        else{
          navigation("/series");
        }
        
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Update Series</b>
              </h2>
            </div>
            <div className="card mt-3">
              <div className="product-list-outer card-body">
                <Form onSubmit={onsubmit}>
                  <div className="row">
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          {" "}
                          Series Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          name="series"
                          onChange={handleChange}
                          type="text"
                          value={series}
                        ></Form.Control>
                        <span style={{ color: "red" }}>{seriesErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Short Name</Form.Label>
                        <Form.Control
                          name="shortName"
                          onChange={handleChange}
                          type="text"
                          value={shortName}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Series Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="seriesType"
                          onChange={handleChange}
                          value={seriesType}
                        >
                          <option value="">Please select Type</option>
                          {SeriesTypeEnum?.map((list) => {
                            return (
                              <option value={list.value}>{list.key}</option>
                            );
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{seriesTypeErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Formats <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="formats"
                          onChange={handleChange}
                        >
                          <option value="">Please select Formats</option>
                          {updatedFormatList?.map((list) => {
                            return (
                              <option value={list.value} key={list.key}>
                                {list.key}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{formatsErr}</span>
                        <div className="mt-2">
                          {formats?.map((list, i) => {
                            return (
                              <span className="mul-team">
                                {list}{" "}
                                <span
                                  class="mdi mdi-close ms-2 mul-icon"
                                  onClick={() => {
                                    removeFormat(i);
                                  }}
                                ></span>
                              </span>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Gender <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="gender"
                          onChange={handleChange}
                          value={gender}
                        >
                          <option value="">Please select Gender</option>
                          {GenderEnum?.map((list) => {
                            return (
                              <option value={list.value}>{list.key}</option>
                            );
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{genderErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Start Date <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          name="startDate"
                          onChange={handleChange}
                          type="date"
                          value={startDate}
                          // min={new Date().toISOString().split("T")[0]}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          End Date <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          name="endDate"
                          onChange={handleChange}
                          type="date"
                          value={endDate}
                          // min={new Date(startDate).toISOString().split("T")[0]}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Teams <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="teams"
                          onChange={handleChange}
                        >
                          <option value="">Please select Team</option>
                          {updatedTeamList?.map((list) => {
                            return (
                              <option value={list.team_id} key={list.team_id}>
                                {list.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{teamIdsErr}</span>
                        <div className="mt-2">
                          {teams?.map((list, i) => {
                            return (
                              <span className="mul-team">
                                {list}{" "}
                                <span
                                  class="mdi mdi-close ms-2 mul-icon"
                                  onClick={() => {
                                    removeTeam(i);
                                  }}
                                ></span>
                              </span>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Venues
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="venues"
                          onChange={handleChange}
                        >
                          <option value="">Please select Venue</option>
                          {updatedVenueList?.map((list) => {
                            return (
                              <option value={list.venue_id} key={list.venue_id}>
                                {list.venue_name}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <span style={{ color: "red" }}>{venueIdsErr}</span>
                        <div className="mt-2">
                          {venues?.map((list, i) => {
                            return (
                              <span className="mul-team">
                                {list}{" "}
                                <span
                                  class="mdi mdi-close ms-2 mul-icon"
                                  onClick={() => {
                                    removeVenue(i);
                                  }}
                                ></span>
                              </span>
                            );
                          })}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Image
                        </Form.Label>
                        <img
                          style={{ width: "100px" }}
                          src={image}
                          className="img-fluid"
                          alt=""
                        />
                        <Form.Control
                          type="file"
                          name="teamimage"
                          onChange={handlechangeimage}
                        />
                        <span style={{ color: "red" }}>{imageErr}</span>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <img src={imageUrl} alt="series" className="img-fluid" />
                    </div>
                  </div>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateSeries;
